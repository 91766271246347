import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const GroupByStatusWrapper = styled.dl<{ $isFirstOfModel: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${({ $isFirstOfModel }) => ($isFirstOfModel ? '0' : '8px')};
  padding: 12px;
  border-radius: 12px;
  background-color: #fff;
`;

export const ListRow = styled.div`
  display: grid;
  grid-template-areas:
    'title primary-value'
    'title secondary-value';
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto auto;
  row-gap: 4px;
  column-gap: 24px;
`;

export const ListTitle = styled.dt<{ $isTurnedOff: boolean }>`
  grid-area: title;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: ${({ $isTurnedOff }) => ($isTurnedOff ? '#A1A1A1' : '#646464;')};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const PrimaryValue = styled.dd<{ $isTurnedOff: boolean }>`
  grid-area: primary-value;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ $isTurnedOff }) => ($isTurnedOff ? '#A1A1A1' : '#272727')};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const SecondaryValue = styled.dd<{ $isTurnedOff: boolean }>`
  grid-area: secondary-value;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: ${({ $isTurnedOff }) => ($isTurnedOff ? '#A1A1A1' : '#646464')};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const HashrateValue = styled(PrimaryValue)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const MinersValue = styled(SecondaryValue)`
  margin-left: 20px;
`;
