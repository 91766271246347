import {
  IEcommerce,
  IGtmEventsBuyMiner,
  IGtmEventsClick,
  IGtmEventsClickMapped,
  IGtmEventsPageView,
  IItems,
} from './types';

export const gtmEventsSender = (
  data: IGtmEventsClick | IGtmEventsPageView | IGtmEventsBuyMiner,
): void => {
  window.dataLayer.push(data);
};

export const gtmEventsPageViewSender = ({
  cityId = 0,
  cityName = '',
  userAuth,
  userId = 0,
  pageType = '',
}: IGtmEventsPageView): void => {
  gtmEventsSender({
    cityId,
    cityName,
    userAuth,
    userId,
    pageType,
  });
};

export const gtmEventsClickSender = ({
  event = 'gtm-event',
  gtmEventCategory = '',
  gtmEventAction = '',
  gtmEventLabel = '',
  gtmEventContent = '',
}: IGtmEventsClickMapped): void => {
  gtmEventsSender({
    event: event,
    gtmEventCategory: gtmEventCategory,
    gtmEventAction: gtmEventAction,
    gtmEventLabel: gtmEventLabel,
    gtmEventContent: gtmEventContent,
  });
};

export const gtmClickCart = (
  itemName: string,
  itemId: number,
  price: number,
  itemBrand: string,
  itemHashRate: number,
  itemElectricityFee: number,
): void => {
  gtmEventsSender({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: itemName,
          item_id: itemId,
          price: price,
          item_brand: itemBrand,
          item_hash_rate: itemHashRate,
          item_electricity_fee: itemElectricityFee,
          quantity: 1,
        },
      ],
    },
  });
};

export const gtmClickCartCheckout = (items: IItems[]): void => {
  gtmEventsSender({
    event: 'checkout',
    ecommerce: {
      items: items,
    },
  });
};

export const gtmClickPaymentSubmit = (ecommerce: IEcommerce): void => {
  gtmEventsSender({
    event: 'purchase',
    ecommerce: ecommerce,
  });
};

export const gtmClickDeposit = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'deposit account',
    gtmEventAction: 'click',
    gtmEventLabel: 'Deposit',
    gtmEventContent: 'Wallet',
  });
};

export const gtmClickWithdraw = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'withdraw account',
    gtmEventAction: 'click',
    gtmEventLabel: 'Withdraw',
    gtmEventContent: 'Wallet',
  });
};

export const gtmClickSendForm = (label: string): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'send',
    gtmEventLabel: 'success',
    gtmEventContent: label,
  });
};

export const gtmClickSendRequestResetPassword = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'click',
    gtmEventLabel: 'sendRequest',
    gtmEventContent: 'resetPassword',
  });
};

export const gtmClickEmailChange = (content: string): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'input',
    gtmEventLabel: 'e-mail',
    gtmEventContent: content,
  });
};

export const gtmClickPasswordChange = (content: string): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'input',
    gtmEventLabel: 'password',
    gtmEventContent: content,
  });
};

export const gtmClickFormError = (label: string, errorMessage: string): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'error',
    gtmEventLabel: label,
    gtmEventContent: errorMessage,
  });
};

export const gtmClickResetPasswordConfirm = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'click',
    gtmEventLabel: 'confirm',
    gtmEventContent: 'resetPassword',
  });
};

export const gtmClickSetNewPassword = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'click',
    gtmEventLabel: 'newPassword',
    gtmEventContent: 'resetPassword',
  });
};

export const gtmClickForgotPassword = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'forms',
    gtmEventAction: 'click',
    gtmEventLabel: 'forgotPassword',
    gtmEventContent: 'Log in',
  });
};

export const gtmClickDepositProcess = (label: string): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'deposit account',
    gtmEventAction: 'click',
    gtmEventLabel: label,
    gtmEventContent: 'Wallet',
  });
};

export const gtmClickReplenish = (value: string): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'deposit electricity',
    gtmEventAction: 'click',
    gtmEventLabel: value,
    gtmEventContent: 'deposit electricity payment',
  });
};

export const gtmClickReplenishPaid = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'ecom',
    gtmEventAction: 'click',
    gtmEventLabel: 'Replenish',
    gtmEventContent: 'invoice paid successfully',
  });
};

export const gtmClickCopyAffiliateLink = (): void => {
  gtmEventsClickSender({
    gtmEventCategory: 'affiliate',
    gtmEventAction: 'click',
    gtmEventLabel: 'Copy link',
    gtmEventContent: 'affiliate program',
  });
};
