import styled from 'styled-components';

import { Button } from 'components/ui/Button';

export const CashbackButton = styled(Button)`
  justify-self: start;
  padding: 2px 8px;
  border-radius: 8px;
  background-color: #f4f4f7;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;

  &:hover,
  &:focus {
    background-color: #e5e5e5;
  }
`;
