import { useRef } from 'react';

import { ICodeVerificationRefs } from 'components/ui/CodeVerificationForm/types/code-verification-refs.interface';

export const useCodeVerificationRefs = (): ICodeVerificationRefs => {
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);
  const input5Ref = useRef<HTMLInputElement>(null);
  const input6Ref = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const inputRefs = [input1Ref, input2Ref, input3Ref, input4Ref, input5Ref, input6Ref];

  return { inputRefs, submitButtonRef };
};
