import React from 'react';
import { ReactComponent as ClockDarkIcon } from 'assets/icons/button-icons/clock-dark.svg';
import { ReactComponent as PowerIcon } from 'assets/icons/button-icons/power.svg';
import { ReactComponent as XCircleIcon } from 'assets/icons/button-icons/x-circle.svg';

import { Button } from 'components/ui/Button';

import {
  DISABLE_UPTIME_LIMIT,
  ENABLE_UPTIME_LIMIT,
  ONCE_EVERY_24_HOURS_TEXT,
} from './constants/ui-text.const';

interface LimitedUptimeButtonProps {
  uptime: boolean;
  cooldown: boolean;
  uptimeStatus: string;
  isInModal?: boolean;
  onEnable: () => void;
  onDisable: () => void;
}

export const LimitedUptimeSetButton: React.FC<LimitedUptimeButtonProps> = ({
  cooldown,
  uptime,
  uptimeStatus,
  isInModal,
  onEnable,
  onDisable,
}) => {
  const isPending = uptimeStatus === 'pending';

  if (cooldown) {
    return (
      <Button
        style={isInModal ? { fontSize: '10px' } : undefined}
        variant={'primary-gray'}
        text={ONCE_EVERY_24_HOURS_TEXT}
        icon={<ClockDarkIcon />}
        svgProps={{
          svgFill: 'transparent',
        }}
        disabled
      />
    );
  }

  return (
    <Button
      variant={'primary'}
      text={uptime ? DISABLE_UPTIME_LIMIT : ENABLE_UPTIME_LIMIT}
      icon={uptime ? <XCircleIcon /> : <PowerIcon />}
      onClick={uptime ? onDisable : onEnable}
      disabled={isPending}
      svgProps={{
        svgFill: 'transparent',
      }}
    />
  );
};
