import {
  ECategory,
  ITransaction,
} from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';

/**
 * Returns transaction history items
 * @param historyItems - An array of history items
 * @param startElement - Starting element for slicing
 * @param endElement - ending element for slicing
 * @returns array of currentHistoryItems and array of slicedItems
 */
export const getSlicedItemsWithoutIncorrectCategories = (
  historyItems: ITransaction[],
  startElement: number,
  endElement: number,
): { currentHistoryItems: ITransaction[]; slicedItems: ITransaction[] } => {
  const currentHistoryItems = historyItems.filter(
    (item) => item.category === ECategory[item.category],
  );
  const slicedItems = currentHistoryItems.slice(startElement, endElement);
  return { currentHistoryItems, slicedItems };
};
