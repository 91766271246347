import { createAsyncThunk } from '@reduxjs/toolkit';
import { IVerificationDataMapped } from 'store/api/cuverse-api/verification/types/types';
import { requestVerification } from 'store/api/cuverse-api/verification/verification.service';

import { mapVerificationData } from 'utils/mapper/mapper';

export const fetchVerificationThunkAction = createAsyncThunk(
  'verification/fetch',
  async (): Promise<IVerificationDataMapped> => {
    return await requestVerification().then((res) => mapVerificationData(res?.data));
  },
);
