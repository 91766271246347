import { ValidationErrorMessage } from 'utils/validators/constants/validation-error.message';

import { TValidatorResponse } from './types/validator-response.types';

type TInputType = 'email' | 'password';

export const isNotTooLong =
  (maxLength: number, inputType?: TInputType) =>
  (value: string): TValidatorResponse => {
    if (value?.length > maxLength) {
      return inputType === 'email'
        ? ValidationErrorMessage.EmailIsTooLong(maxLength)
        : ValidationErrorMessage.PasswordIsTooLong(maxLength);
    }
    return undefined;
  };
