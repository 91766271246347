export const ValidationErrorMessage = {
  AmountExceeded: () => 'Amount exceeds available balance',
  ValueShouldBeGreaterThan: (minValue: number): string =>
    `Value should be greater than ${minValue}`,
  ValueShouldBeGreaterOrEqual: (minValue: number): string =>
    `Value should be greater or equal to ${minValue}`,
  ValueShouldBeLessOrEqual: (maxValue: number): string =>
    `Value should be less or equal to ${maxValue}`,
  FieldRequired: (): string => 'This field is required',
  CheckboxRequired: (): string => 'This checkbox is required',
  IncorrectEmail: (): string => 'Incorrect email',
  IncorrectBtcAddress: (): string => 'Incorrect BTC wallet address',
  PasswordIsTooShort: (minLength: number): string =>
    `Minimum password length is ${minLength} symbols`,
  PasswordIsTooLong: (maxLength: number): string =>
    `Maximum password length is ${maxLength} symbols`,
  EmailIsTooLong: (maxLength: number): string => `Maximum email length is ${maxLength} symbols`,
  ShouldHaveExactLength: (length: number): string => `The code should have ${length} characters`,
  PasswordsDoesNotMatch: () => 'Passwords does not match',
  SlippageIsNotInRange: (minValue: number, maxValue: number): string =>
    `Slippage should be between ${minValue}% and ${maxValue}%`,
  DeadlineIsNotInRange: (minValue: number, maxValue: number): string =>
    `Deadline should be between ${minValue} and ${maxValue} minutes`,
} as const;
