import { MutableRefObject } from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackAuthErrorMessage } from 'constants/notification-messages/fallback-auth-error-message';
import {
  handle2faError,
  handleSocial2faError,
  requestConfirm2FA,
  requestDisable2FA,
  requestEmailResend,
  requestEmailVerify,
  requestEnable2FA,
  requestLogin,
  requestLogout,
  requestOtpVerification,
  requestPasswordChangeCode,
  requestPasswordReset,
  requestRegistration,
  requestSocialLogin,
  requestTokenProlongation,
} from 'store/api/cuverse-api/auth/auth.service';
import { IConfirm2FARequestBody } from 'store/api/cuverse-api/auth/types/confirm-2fa-request-body.interface';
import { IDisable2FARequestBody } from 'store/api/cuverse-api/auth/types/disable-2fa-request-body.interface';
import { IEmailVerifyRequestBody } from 'store/api/cuverse-api/auth/types/email-verify-request-body.interface';
import { ILoginError } from 'store/api/cuverse-api/auth/types/login-error.interface';
import { ILoginRequestBody } from 'store/api/cuverse-api/auth/types/login-request-body.interface';
import { IPasswordChangeCodeError } from 'store/api/cuverse-api/auth/types/password-change-code-error.interface';
import { IPasswordResetError } from 'store/api/cuverse-api/auth/types/password-reset-error.interface';
import { IPasswordResetRequestBody } from 'store/api/cuverse-api/auth/types/password-reset-request-body.interface';
import { IRegisterError } from 'store/api/cuverse-api/auth/types/register-error.interface';
import { IRegistrationRequestBody } from 'store/api/cuverse-api/auth/types/registration-request-body.interface';
import { IResendEmailRequestBody } from 'store/api/cuverse-api/auth/types/resend-email-request-body.interface';
import { TSocialLoginProvider } from 'store/api/cuverse-api/auth/types/social-login-provider.type';
import { ISocialLoginRequestBody } from 'store/api/cuverse-api/auth/types/social-login-request-body.interface';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { TRootState } from 'store/store';
import { TUserAuthStatus } from 'types/user/user-auth-status.type';

import { gtmClickFormError } from 'utils/gtmSender/gtmSender';
import { notifyError, notifySuccess, notifyWarning } from 'utils/notify/notify.utils';
import { deleteAuthStatus } from 'utils/storage/auth-status/delete-auth-status';
import { getAuthStatusFromLocalStorage } from 'utils/storage/auth-status/get-auth-status';
import { setAuthStatusToLocalStorage } from 'utils/storage/auth-status/set-auth-status';
import { deleteAuthToken } from 'utils/storage/auth-token/delete-auth-token';
import { setAuthToken } from 'utils/storage/auth-token/set-auth-token';
import { deleteEmail } from 'utils/storage/email/delete-email.util';
import { deleteOtpToken } from 'utils/storage/otp/delete-otp-token.util';
import { setOtpToken } from 'utils/storage/otp/set-otp-token.util';

import { REQUIRED_2FA_ERROR_CODE } from './constants/required-2fa-error-code';
import { setUserAuthStatusAction } from './auth.reducer';

export const requestLoginThunkAction = createAsyncThunk(
  'auth/requestLogin',
  async (
    {
      body,
      errorHandlerRef,
      gtmClickSendForm,
    }: {
      body: ILoginRequestBody;
      errorHandlerRef: MutableRefObject<AsyncGenerator<
        string | undefined,
        void,
        string
      > | null> | null;
      gtmClickSendForm?: (label: string) => void;
    },
    { dispatch },
  ): Promise<void> => {
    await requestLogin(body)
      .then(({ token, is_approved }) => {
        setAuthToken(token);
        dispatch(
          setUserAuthStatusAction(
            is_approved ? 'authorized-verified' : 'email-verification-pending',
          ),
        );
        if (gtmClickSendForm) {
          gtmClickSendForm('Log in');
        }
      })
      .catch(async (error: AxiosError<ILoginError>) => {
        if (error.response?.status === REQUIRED_2FA_ERROR_CODE) {
          dispatch(setUserAuthStatusAction('authentication-required'));
          if (errorHandlerRef) {
            errorHandlerRef.current = handle2faError({
              body,
              onSuccessCb: (newToken: string) => {
                setAuthToken(newToken);
                dispatch(setUserAuthStatusAction('authorized-verified'));
              },
              onErrorCb: () => {
                if (error.response?.data.message) {
                  notifyError(error.response.data.message);
                }
                dispatch(setUserAuthStatusAction('unauthorized'));
              },
            });
            await errorHandlerRef.current?.next();
          }
        } else {
          dispatch(setUserAuthStatusAction('unauthorized'));
          if (error.response?.data.message) {
            notifyError(error.response.data.message);
            throw error;
          }
          if (error.response?.data.errors?.email) {
            notifyError(error.response.data.errors.email[0]);
            gtmClickFormError('e-mail', error.response.data.errors.email[0]);
            throw error;
          }
          if (error.response?.data.errors?.password) {
            notifyError(error.response.data.errors.password[0]);
            gtmClickFormError('password', error.response.data.errors.password[0]);
            throw error;
          }
          notifyError(FallbackAuthErrorMessage.SomethingWentWrong);
          throw error;
        }
      });
  },
);

export const requestSocialLoginThunkAction = createAsyncThunk(
  'auth/requestSocialLogin',
  async (
    {
      body,
      socialProvider,
      errorHandlerRef,
    }: {
      body: ISocialLoginRequestBody;
      socialProvider: TSocialLoginProvider;
      errorHandlerRef: MutableRefObject<AsyncGenerator<
        string | undefined,
        void,
        string
      > | null> | null;
    },
    { dispatch },
  ): Promise<void> => {
    await requestSocialLogin(body, socialProvider)
      .then(({ token, is_approved }) => {
        setAuthToken(token);
        dispatch(
          setUserAuthStatusAction(
            is_approved ? 'authorized-verified' : 'email-verification-pending',
          ),
        );
      })
      .catch(async (error: AxiosError<ILoginError>) => {
        if (error.response?.status === REQUIRED_2FA_ERROR_CODE) {
          dispatch(setUserAuthStatusAction('authentication-required'));
          if (errorHandlerRef) {
            errorHandlerRef.current = handleSocial2faError({
              body,
              socialProvider,
              onSuccessCb: (newToken: string) => {
                setAuthToken(newToken);
                dispatch(setUserAuthStatusAction('authorized-verified'));
              },
              onErrorCb: () => {
                if (error.response?.data.message) {
                  notifyError(error.response.data.message);
                }
                dispatch(setUserAuthStatusAction('unauthorized'));
              },
            });
            await errorHandlerRef.current?.next();
          }
        } else {
          dispatch(setUserAuthStatusAction('unauthorized'));
          if (error.response?.data.message) {
            notifyError(error.response.data.message);
            throw error;
          }
          notifyError(FallbackAuthErrorMessage.SomethingWentWrong);
          throw error;
        }
      });
  },
);

export const requestTokenProlongationThunkAction = createAsyncThunk(
  'auth/token-prolongation',
  async (): Promise<boolean> =>
    await requestTokenProlongation()
      .then(({ is_approved }) => is_approved)
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.SessionExpired);
        throw error;
      }),
);

export const requestLogoutThunkAction = createAsyncThunk(
  'auth/logout',
  async (
    callback: { onSuccessCb: () => void } | undefined,
    { dispatch, getState },
  ): Promise<void> => {
    const state = getState() as TRootState;
    setAuthStatusToLocalStorage(state.authReducer.userAuthStatus);
    dispatch(setUserAuthStatusAction('anonymous'));

    await requestLogout()
      .then(() => {
        deleteAuthToken();
        deleteAuthStatus();
        callback?.onSuccessCb();
      })
      .catch((error) => {
        const authStatusBeforeLogoutRequest = getAuthStatusFromLocalStorage() as TUserAuthStatus;
        dispatch(setUserAuthStatusAction(authStatusBeforeLogoutRequest));
        notifyError(FallbackAuthErrorMessage.SomethingWentWrong);
        throw error;
      });
  },
);

export const requestRegisterThunkAction = createAsyncThunk(
  'auth/register',
  async (body: IRegistrationRequestBody, { dispatch }): Promise<void> =>
    await requestRegistration(body)
      .then(() => {
        void dispatch(requestLoginThunkAction({ body, errorHandlerRef: null }));
      })
      .catch((error: AxiosError<IRegisterError>) => {
        const errors = error.response?.data.errors;
        if (errors?.email?.length) {
          notifyError(errors.email[0]);
        } else {
          notifyError(FallbackAuthErrorMessage.FailedToRegister);
        }
        throw error;
      }),
);

export const requestEmailVerifyThunkAction = createAsyncThunk(
  'auth/email-verify',
  async (body: IEmailVerifyRequestBody, { dispatch }): Promise<void> =>
    await requestEmailVerify(body)
      .then(() => {
        dispatch(setUserAuthStatusAction('registration-success'));
        body.gtmClickSendForm('Registration');
      })
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.FailedToVerifyEmail);
        throw error;
      }),
);

export const requestEmailResendThunkAction = createAsyncThunk(
  'auth/email-resend',
  async (body: IResendEmailRequestBody): Promise<string> =>
    await requestEmailResend(body)
      .then(({ message }) => message)
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.FailedToResendEmail);
        throw error;
      }),
);

export const requestPasswordChangeCodeThunkAction = createAsyncThunk(
  'auth/password-change-code',
  async ({ email, onSuccessCb }: { email: string; onSuccessCb: () => void }): Promise<void> => {
    return await requestPasswordChangeCode(email)
      .then(() => onSuccessCb())
      .catch((error: AxiosError<IPasswordChangeCodeError>) => {
        if (error.response?.data.message?.original) {
          notifyError(error.response.data.message.original);
          throw error;
        }
        if (error.response?.data.message) {
          notifyError(JSON.stringify(error.response.data.message));
          throw error;
        } else {
          notifyError(FallbackAuthErrorMessage.IncorrectEmail);
          throw error;
        }
      });
  },
);

export const requestOtpVerificationThunkAction = createAsyncThunk(
  'auth/otp-verify',
  async ({ code, onSuccessCb }: { code: string; onSuccessCb: () => void }): Promise<void> => {
    return requestOtpVerification({ verification_code: code })
      .then(({ token }) => {
        setOtpToken(token);
        onSuccessCb();
      })
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.IncorrectOtp);
        throw error;
      });
  },
);

export const requestPasswordResetThunkAction = createAsyncThunk(
  'auth/password-reset',
  async ({
    body,
    onSuccessCb,
  }: {
    body: IPasswordResetRequestBody;
    onSuccessCb: () => void;
  }): Promise<void> => {
    return requestPasswordReset(body)
      .then(() => {
        deleteOtpToken();
        deleteEmail();
        onSuccessCb();
      })
      .catch((error: AxiosError<IPasswordResetError>) => {
        if (error.response?.data.message) {
          notifyError(error.response.data.message);
          throw error;
        }
        notifyError(FallbackAuthErrorMessage.SomethingWentWrong);
        throw error;
      });
  },
);

export const requestEnable2FAThunkAction = createAsyncThunk(
  'auth/enable-2fa',
  async (): Promise<string> =>
    await requestEnable2FA()
      .then(({ data }) => data.auth_qr_code)
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.CannotLoad2FACode);
        throw error;
      }),
);

export const requestConfirm2FAThunkAction = createAsyncThunk(
  'auth/confirm-2fa',
  async (body: IConfirm2FARequestBody, { dispatch }): Promise<void> =>
    await requestConfirm2FA(body)
      .then(() => {
        void dispatch(requestProfileDataThunkAction());
      })
      .then(() => {
        dispatch(closePopupAction());
        notifySuccess('Google Authenticator added');
      })
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.SomethingWentWrong);
        throw error;
      }),
);

export const requestDisable2FAThunkAction = createAsyncThunk(
  'auth/disable-2fa',
  async (body: IDisable2FARequestBody, { dispatch }): Promise<void> =>
    await requestDisable2FA(body)
      .then(() => {
        void dispatch(requestProfileDataThunkAction());
      })
      .then(() => {
        notifyWarning('Google Authenticator is disabled');
      })
      .catch((error) => {
        notifyError(FallbackAuthErrorMessage.SomethingWentWrong);
        throw error;
      }),
);
