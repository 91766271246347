import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';
import { IMinerDataResponse } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { IPurchaseCartResponse } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import { PurchaseRoute } from './purchase.route';

export const requestActiveMiners = async (): Promise<IMinerDataResponse> =>
  cuverseApi.get<IMinerDataResponse>(PurchaseRoute.Miners()).then((res) => res?.data);

export const requestCartList = async (): Promise<IPurchaseCartResponse> =>
  cuverseApi.get<IPurchaseCartResponse>(PurchaseRoute.Cart()).then((res) => res?.data);

export const createCartList = async (id: number): Promise<IPurchaseCartResponse> =>
  cuverseApi
    .post<IPurchaseCartResponse>(PurchaseRoute.Cart(), { product_id: id })
    .then((res) => res?.data);

export const updateCartList = async (
  cartNumber: number,
  productId: number,
): Promise<IPurchaseCartResponse> =>
  cuverseApi
    .patch<IPurchaseCartResponse>(PurchaseRoute.UpdateCart(cartNumber), { product_id: productId })
    .then((res) => res?.data);

export const deleteCartListItem = async (
  cartNumber: number,
  productId: number,
): Promise<IPurchaseCartResponse> =>
  cuverseApi
    .delete<IPurchaseCartResponse>(PurchaseRoute.DeleteCartItem(cartNumber, productId))
    .then((res) => res?.data);
