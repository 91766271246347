import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';
import { StorageKey } from 'types/storage/storage-key.enum';

import { AppRoute } from 'utils/route/app-route';

interface IRedirectToOuterRouteProps {
  children: React.ReactNode;
}

const nonPersistingAuthorizedRoutes = [
  AppRoute.Login(),
  AppRoute.Registration(),
  AppRoute.PasswordReset(),
  AppRoute.NotFound(),
];

const nonPersistingUnauthorizedRoutes = [
  AppRoute.AffiliateProgram(),
  AppRoute.Assets(),
  AppRoute.Profile(),
  AppRoute.ProfileTab('personal'),
  AppRoute.ProfileTab('wallet'),
  AppRoute.ProfileTab('security'),
  AppRoute.Disable2FA(),
  AppRoute.Purchase(),
  AppRoute.TransactionsHistory(),
  AppRoute.NotFound(),
];

const RedirectToOuterRoute: React.FC<IRedirectToOuterRouteProps> = ({ children }) => {
  const userAuthStatus = useSelector(selectUserAuthStatus);
  const outerRoute = localStorage.getItem(StorageKey.OuterRoute);
  const outerRouteWithoutSlash = outerRoute?.replace(/\/$/, '');

  const nonPersistingRoutes =
    userAuthStatus === 'authorized-verified'
      ? nonPersistingAuthorizedRoutes
      : nonPersistingUnauthorizedRoutes;

  const appRoutes = Object.values(AppRoute)
    .map((routeFn) => {
      if (routeFn === AppRoute.ProfileTab) {
        return [routeFn('personal'), routeFn('wallet'), routeFn('security')];
      }
      return routeFn('personal');
    })
    .flat();

  if (outerRouteWithoutSlash && !appRoutes.includes(outerRouteWithoutSlash)) {
    return <Navigate to={AppRoute.NotFound()} replace />;
  }

  if (
    outerRouteWithoutSlash &&
    appRoutes.includes(outerRouteWithoutSlash) &&
    !nonPersistingRoutes.includes(outerRouteWithoutSlash)
  ) {
    return <Navigate to={outerRouteWithoutSlash} replace />;
  }

  return <>{children}</>;
};

export default RedirectToOuterRoute;
