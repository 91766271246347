import React, { forwardRef, useLayoutEffect, useState } from 'react';
import { ReactComponent as EyeClosed } from 'assets/icons/button-icons/eye-closed.svg';
import { ReactComponent as EyeOpened } from 'assets/icons/button-icons/eye-opened.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/input-icons/circle-check.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/input-icons/warning-triangle.svg';

import { IInputProps } from './Input.types';

import * as S from './Input.styled';

export const Input = forwardRef<HTMLInputElement | null, IInputProps>(
  (
    {
      hasErrors = false,
      isDisabled = false,
      isReadOnly = false,
      type = 'text',
      rightIcon = null,
      rightText = '',
      className,
      cryptoCurrency = null,
      hasValidationIcons = false,
      ...props
    },
    ref,
  ) => {
    const [inputType, setInputType] = useState(type);
    const [isValidated, setIsValidated] = useState(false);

    const hasPasswordType = type === 'password';
    const hasRightIcon = type === 'text' && !!rightIcon;
    const hasRightText = type === 'text' && !!rightText;
    const hasCurrencyBadge = type === 'text' && !!cryptoCurrency;
    const areValidationIconsVisible =
      type === 'text' && !hasRightIcon && !hasRightText && hasValidationIcons && isValidated;
    const hasValidationErrorIcon = areValidationIconsVisible && hasErrors;
    const hasValidationSuccessIcon = areValidationIconsVisible && !hasErrors;

    const togglePasswordVisibility = (): void =>
      setInputType((current) => (current === 'password' ? 'text' : 'password'));

    useLayoutEffect(() => {
      if (!isValidated && hasValidationIcons && hasErrors) {
        setIsValidated(true);
      }
    }, [isValidated, hasValidationIcons, hasErrors]);

    return (
      <S.InputWrapper $isDisabled={isDisabled} className={className}>
        <S.Input
          {...props}
          ref={ref}
          disabled={isDisabled}
          readOnly={isReadOnly}
          type={inputType}
          $hasCurrencyBadge={hasCurrencyBadge}
          $hasRightIcon={hasPasswordType || hasRightIcon || areValidationIconsVisible}
          $hasRightText={hasRightText}
          $hasErrors={hasErrors}
        />
        {hasPasswordType && (
          <S.EyeButton
            type="button"
            variant="borderless"
            icon={inputType === 'password' ? <EyeClosed /> : <EyeOpened />}
            onClick={togglePasswordVisibility}
            disabled={isDisabled}
          />
        )}
        {hasCurrencyBadge && <S.InputCurrencyBadge currency={cryptoCurrency} />}
        {hasRightIcon && rightIcon}
        {hasRightText && <S.SecondaryText>{rightText}</S.SecondaryText>}
        {hasValidationErrorIcon && <ErrorIcon />}
        {hasValidationSuccessIcon && <SuccessIcon />}
      </S.InputWrapper>
    );
  },
);
