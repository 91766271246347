import { EVerificationStatusCode } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * full verified status
 * @param verificationStatusCode status of verification
 * @returns boolean value
 */
export const getIsFullVerificationStatus = (
  verificationStatusCode: EVerificationStatusCode,
): boolean => {
  return [EVerificationStatusCode.KYC_STATUS_VERIFIED].includes(verificationStatusCode);
};
