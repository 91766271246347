import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackAlphaPoPaymentErrorMessage } from 'constants/notification-messages/fallback-alpha-po-payment-error-message';
import { FallbackPaymentMessages } from 'constants/notification-messages/fallback-payment-messages';
import {
  requestAlphaPoPayment,
  requestCardPayment,
  requestPayment,
} from 'store/api/cuverse-api/payment/payment.service';
import {
  ICardPaymentMessageMapped,
  IPaymentResponseData,
  TAlphaPoMessage,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { fetchInvoicePdfLinksAction } from 'store/Invoice-pdf-links-reducer/Invoice-pdf-links.thunk-action';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';

import { mapCardPayment } from 'utils/mapper/mapper';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

interface PaymentParams {
  id: number;
  paymentMethod: 'btc-balance' | 'alpha-po' | 'mercuryo';
}

export const fetchPaymentThunkAction = createAsyncThunk(
  'payment/fetch',
  async (
    { id, paymentMethod }: PaymentParams,
    { dispatch },
  ): Promise<IPaymentResponseData | TAlphaPoMessage | ICardPaymentMessageMapped> => {
    let response;
    switch (paymentMethod) {
      case 'btc-balance': {
        response = await requestPayment({
          purchase_id: id,
          payment_method: paymentMethod,
          operation_type: 'pool_miner',
          language: 'en',
        });
        dispatch(setPurchaseStepAction('paid-success'));
        notifySuccess(FallbackPaymentMessages.SuccessfullyPayment);
        const orders = response.message.data?.map((order) => order.order_id);
        orders?.forEach((orderId) => dispatch(fetchInvoicePdfLinksAction(orderId)));
        return response.message;
      }
      case 'alpha-po': {
        response = await requestAlphaPoPayment({
          purchase_id: id,
          payment_method: paymentMethod,
          operation_type: 'pool_miner',
          language: 'en',
        });
        if (!response?.message) {
          notifyError(FallbackAlphaPoPaymentErrorMessage.FailedToGetPaymentLink);
          throw new Error();
        }
        return response.message;
      }
      case 'mercuryo': {
        response = await requestCardPayment({
          purchase_id: id,
          payment_method: paymentMethod,
          operation_type: 'pool_miner',
          language: 'en',
        });
        if (!response?.message) {
          notifyError(FallbackAlphaPoPaymentErrorMessage.FailedToGetPaymentLink);
          throw new Error();
        }
        return mapCardPayment(response.message);
      }
      default:
        throw new Error('Invalid payment method');
    }
  },
);
