import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const FormInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SuggestedAmountsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SuggestedAmountTitle = styled(SText)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const SuggestedAmountsList = styled.ul`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const SuggestedAmount = styled.li`
  &:first-child {

    & button {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }

  &:last-child {

    & button {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
`;

export const AmountButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 0;
  background-color: #f4f4f7;
  color: #272727;

  &:hover,
  &:focus {
    background-color: #eaeaea;
  }

  &:active,
  &:disabled {
    background-color: #a1a1a1;
    color: #fff;
  }
`;

export const InputLabel = styled.label<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 80px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }}
`;

export const LabelText = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const AmountInput = styled(Input)`
  & input {
    padding-left: 24px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      height: 42px;
      padding: 11px 15px 11px 24px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &::before {
    content: '$';
    position: absolute;
    top: 50%;
    left: 12px;
    font-family: ${FontFamily.Montserrat};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #a1a1a1;
    transform: translateY(-50%);
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const DepositButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
  border-radius: 24px;
`;
