import fromExponential from 'from-exponential';

//cuts digits after dot with specified number of fraction digits
export const cutFractionDigits = (value: string | number, fractionDigits: number): string => {
  let resultValue = String(fromExponential(value));

  if (resultValue.includes('.')) {
    const [beforeDot, afterDot] = resultValue.split('.');
    resultValue =
      afterDot.length > 0 ? `${beforeDot}.${afterDot.slice(0, fractionDigits)}` : beforeDot;
  }

  return resultValue;
};
