import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ElectricityWithCashback } from 'pages/PagePurchase/components/ui/ElectricityWithCashback';
import { ElectricityWithNoCashback } from 'pages/PagePurchase/components/ui/ElectricityWithNoCashback';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';
import { openElectricityCashbackTermsPopupAction } from 'store/modals-reducer/modals.reducer';
import { selectCartFetchStatus } from 'store/purchase-reducer/purchase.selectors';
import {
  createCartThunkAction,
  deleteCartItemThunkAction,
  updateCartThunkAction,
} from 'store/purchase-reducer/purchase.thunk-actions';
import { useAppDispatch } from 'store/store';

import { CartManageButton } from 'components/ui/CartManageButton';
import { CurrencyBadge } from 'components/ui/CurrencyBadge';
import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { gtmClickCart } from 'utils/gtmSender/gtmSender';

import {
  CURRENCY_TITLE,
  ELECTRICITY_TITLE,
  HASH_RATE_TITLE,
  INCOME_TITLE,
} from './constants/miner-card.consts';

import * as S from './MinerCard.styled';

interface MinerCardProps {
  minerData: IMinerDataMapped;
  btcToUsdRate: number;
  isCartExist: boolean;
  cartId: number;
  cartList: IPurchaseCartItemMapped[];
}

export const MinerCard: React.FC<MinerCardProps> = ({
  minerData,
  btcToUsdRate,
  isCartExist,
  cartId,
  cartList,
}) => {
  const dispatch = useAppDispatch();
  const {
    dailyElectricityFee,
    isSoldOut,
    picture,
    model,
    hashrate,
    unitSymbol,
    estDailyOutput,
    price,
    id,
    cashback,
  } = minerData;
  //TODO delete constant when server create cashback feature and return correct cashback value
  const CASHBACK_ELECTRICITY = 0.2;

  const cartFetchStatus = useSelector(selectCartFetchStatus);

  const cartListItem = cartList?.find((el) => el.productId === id);
  const initialIsSoldOut = cartListItem?.maxQuantity === 0 || isSoldOut;
  const [isActualSoldOut, setIsActualSoldOut] = useState(initialIsSoldOut);
  const [availableMinersNumber, setAvailableMinersNumber] = useState(0);

  const isCartPending = cartFetchStatus === 'pending';
  //TODO replace with hasCashback = cashbackFromServer > 0 comparison when server return correct cashback value
  const hasCashback = true;

  const handleIncrement = () => {
    if (!isCartPending) {
      gtmClickCart(model, id, price, model.split(' ')[0], hashrate, dailyElectricityFee);
      isCartExist
        ? void dispatch(updateCartThunkAction({ cartId, id }))
        : void dispatch(createCartThunkAction(id));
    }
  };

  const handleDecrement = () => {
    !isCartPending && void dispatch(deleteCartItemThunkAction({ cartId, id }));
  };

  const handleCashbackButtonClick = () => dispatch(openElectricityCashbackTermsPopupAction());

  useLayoutEffect(() => {
    if (cartListItem) {
      const minersInStock = cartListItem.maxQuantity;
      const minersInCart = cartListItem.quantity;
      const isActualSoldOut = cartListItem.maxQuantity === 0;

      setAvailableMinersNumber(minersInStock - minersInCart);
      setIsActualSoldOut(isActualSoldOut);
    }
  }, [cartListItem, cartListItem?.maxQuantity]);

  return (
    <S.MinerItem as="li">
      <S.MinerDataWrapper>
        <S.MinerPicture>
          <source srcSet={`${picture}, ${picture} 2x, ${picture} 3x`} type="image/webp" />
          <source srcSet={`${picture}, ${picture} 2x, ${picture} 3x`} type="image/png" />
          <img width={120} height={120} src={picture} alt="Miner" />
        </S.MinerPicture>
        <S.MinerName>{model}</S.MinerName>
        <S.PerformanceData as="dl">
          <S.CurrencyTitle>{CURRENCY_TITLE}</S.CurrencyTitle>
          <S.CurrencyValue>
            <CurrencyBadge currency="btc" />
          </S.CurrencyValue>
          <S.HashTitle>{HASH_RATE_TITLE}</S.HashTitle>
          <S.HashValue>
            {hashrate} {unitSymbol}
          </S.HashValue>
        </S.PerformanceData>
        <S.FinanceData as="dl">
          <S.IncomeTitle>{INCOME_TITLE}</S.IncomeTitle>
          <S.IncomeValueWrapper>
            <S.IncomeBtcValue>{`${formatBtcOutput(estDailyOutput)} BTC`}</S.IncomeBtcValue>
            <S.IncomeUsdValue>{`≈ $${formatUsdOutput(btcToUsdRate * estDailyOutput)}`}</S.IncomeUsdValue>
          </S.IncomeValueWrapper>

          <S.ElectricityTitle>{ELECTRICITY_TITLE}</S.ElectricityTitle>
          {hasCashback ? (
            <ElectricityWithCashback
              dailyElectricityFee={dailyElectricityFee}
              electricityCashback={CASHBACK_ELECTRICITY}
              handleCashbackButtonClick={handleCashbackButtonClick}
            />
          ) : (
            <ElectricityWithNoCashback
              dailyElectricityFee={dailyElectricityFee}
            />
          )}
        </S.FinanceData>
      </S.MinerDataWrapper>
      <S.MinerFooterWrapper>
        <S.MinerPrice>{`$${formatUsdOutput(price)}`}</S.MinerPrice>
        <CartManageButton
          quantity={cartListItem?.quantity || 0}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          availableMinersNumber={availableMinersNumber}
          isSoldOut={isActualSoldOut}
        />
      </S.MinerFooterWrapper>
    </S.MinerItem>
  );
};
