import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IHistoryByDates } from 'pages/PageTransactionsHistory/types/history-by-dates.interface';
import { ITransaction } from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';

dayjs.extend(utc);

// a function, which takes an array of IHistoryItems items
// and groups it by dates, specified in date field
// returns an array typed as IHistoryByDates
export const groupHistoryItemsByDates = (historyItems: ITransaction[]): IHistoryByDates[] => {
  const groupedHistoryItems: IHistoryByDates[] = [];

  historyItems?.forEach((item) => {
    const date = dayjs.utc(item.datetime).format('MMMM DD, YYYY');
    const existingGroup = groupedHistoryItems.find((group) => group.date === date);

    if (existingGroup) {
      existingGroup.historyItems.push(item);
    } else {
      groupedHistoryItems.push({
        date,
        historyItems: [item],
      });
    }
  });

  return groupedHistoryItems;
};
