import styled, { css } from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

export const Button = styled.button<{ $isCurrent: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${({ $isCurrent }) => ($isCurrent ? '#0e46c0' : '#272727')};
  transition: all ${TransitionTime.Hover};
  cursor: pointer;
  user-select: none;

  &:hover,
  &:focus,
  &:active {
    color: #0e46c0;
  }
`;
