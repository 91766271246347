import React from 'react';
import { ReactComponent as SelectMinerIcon } from 'assets/icons/purchase-page-icons/select-miner.svg';
import { MinerCard } from 'pages/PagePurchase/components/logic/MinerCard';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import { SELECT_MINER_TITLE } from './constants/miners-buying-section.consts';

import * as S from './MinersBuyingSection.styled';

interface MinersBuyingSectionProps {
  minersData: IMinerDataMapped[];
  btcToUsdRate: number;
  isCartExist: boolean;
  cartId: number;
  cartList: IPurchaseCartItemMapped[];
}

export const MinersBuyingSection: React.FC<MinersBuyingSectionProps> = ({
  minersData,
  btcToUsdRate,
  isCartExist,
  cartId,
  cartList,
}) => {
  return (
    <S.MinersBlock as="section">
      <S.TitleWrapper>
        <SelectMinerIcon />
        <S.MinersBlockTitle>{SELECT_MINER_TITLE}</S.MinersBlockTitle>
      </S.TitleWrapper>
      <S.MinerList as="ul">
        {minersData.map((miner) => (
          <MinerCard
            key={miner.id}
            cartList={cartList}
            cartId={cartId}
            minerData={miner}
            btcToUsdRate={btcToUsdRate}
            isCartExist={isCartExist}
          />
        ))}
      </S.MinerList>
    </S.MinersBlock>
  );
};
