import { createSlice } from '@reduxjs/toolkit';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { IPurchaseCartDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';
import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
  createCartThunkAction,
  deleteCartItemThunkAction,
  fetchActiveMinersThunkAction,
  fetchCartThunkAction,
  updateCartThunkAction,
} from './purchase.thunk-actions';

export interface IPurchaseState {
  purchaseStep: TPurchaseStep;
  activeMiners: IMinerDataMapped[];
  minersFetchStatus: TFetchStatus;
  minersError: string | null;
  cart: IPurchaseCartDataMapped;
  cartListFetchStatus: TFetchStatus;
  cartError: string | null;
}

const initialState: IPurchaseState = {
  purchaseStep: 'buying',
  activeMiners: [],
  minersFetchStatus: 'initial',
  minersError: null,
  cart: { cartId: 0, totalCartSum: 0, cartItems: [] },
  cartListFetchStatus: 'initial',
  cartError: null,
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setPurchaseStepAction: (state, { payload }: { payload: TPurchaseStep }) => {
      state.purchaseStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveMinersThunkAction.pending, (state) => {
        state.minersFetchStatus = 'pending';
      })
      .addCase(fetchActiveMinersThunkAction.fulfilled, (state, action) => {
        state.minersFetchStatus = 'fulfilled';
        state.activeMiners = action.payload;
      })
      .addCase(fetchActiveMinersThunkAction.rejected, (state, error) => {
        state.minersFetchStatus = 'rejected';
        state.minersError = JSON.stringify(error);
      });
    builder
      .addCase(fetchCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(fetchCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(fetchCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cart = { cartId: 0, totalCartSum: 0, cartItems: [] };
      })
      .addCase(createCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(createCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(createCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })
      .addCase(updateCartThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(updateCartThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(updateCartThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      })
      .addCase(deleteCartItemThunkAction.pending, (state) => {
        state.cartListFetchStatus = 'pending';
      })
      .addCase(deleteCartItemThunkAction.fulfilled, (state, action) => {
        state.cartListFetchStatus = 'fulfilled';
        state.cart = action.payload;
      })
      .addCase(deleteCartItemThunkAction.rejected, (state, error) => {
        state.cartListFetchStatus = 'rejected';
        state.cartError = JSON.stringify(error);
      });
  },
});

export const { setPurchaseStepAction } = purchaseSlice.actions;
export const purchaseReducer = purchaseSlice.reducer;
