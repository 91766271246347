import { DecimalPlaces } from 'constants/decimals/decimal-places';

import { deleteDecimalTailZeros } from './delete-decimal-tail-zeros.util';
import { formatToNumberWithSpaces } from './format-to-number-with-spaces.util';

export const formatUsdOutput = (value: number | string): string => {
  if (isNaN(Number(value))) {
    return '0';
  }

  return deleteDecimalTailZeros(formatToNumberWithSpaces(value, DecimalPlaces.Usd));
};
