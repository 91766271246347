import { ReactElement } from 'react';

import { SuccessDescription } from 'components/ui/AuthSuccess/components/ui/SuccessDescription';
import { TAuthSuccessVariant } from 'components/ui/AuthSuccess/types/auth-success-variant.type';
import { AppRoute } from 'utils/route/app-route';

export const useAuthSuccessAttributes = (
  variant: TAuthSuccessVariant,
): {
  title: string;
  descriptionContent: ReactElement | null;
  linkText: string;
  linkUrl: string;
} => {
  let title = '';
  let descriptionContent = null;
  let linkText = '';
  let linkUrl = '';

  switch (variant) {
    case 'password-reset':
      title = 'New password is set';
      descriptionContent = <SuccessDescription variant={'password-reset'} />;
      linkText = 'Log in';
      linkUrl = AppRoute.Login();
      break;
    case 'registration':
      title = 'Successful registration';
      descriptionContent = <SuccessDescription variant={'registration'} />;
      linkText = 'Go to Dashboard';
      linkUrl = AppRoute.Home();
      break;
  }

  return { title, descriptionContent, linkText, linkUrl };
};
