import { ISelectItem } from 'types/ui/Select/select-item.interface';

export const getSelectItemsFromYear = (year: number | string): ISelectItem[] => {
  const currentYear = new Date().getFullYear();
  const initialYearNumber = Number(year);

  if (isNaN(initialYearNumber) || initialYearNumber > currentYear) {
    return [];
  }

  const yearSelectItems: ISelectItem[] = [];
  for (let i = currentYear; i >= initialYearNumber; i--) {
    yearSelectItems.push({ text: String(i), value: String(i) });
  }

  return yearSelectItems;
};
