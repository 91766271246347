import { SInfoArticle, SInfoBadge } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const WalletArticle = styled(SInfoArticle)`
  flex: 1 1 422px;
  gap: 16px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-basis: 342px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex: 0 0 auto;
    justify-content: flex-start;
    padding: 12px;
    border-radius: 16px;
    background-color: #fff;
  }
`;

export const BalanceList = styled(SInfoBadge)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const BalanceTitle = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;
`;

export const BalanceDescription = styled.dd`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const BtcValue = styled(SText)`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  color: #000;
`;

export const UsdValue = styled(SText)`
  font-size: 18px;
  line-height: 24px;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const WalletButtons = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const WalletButton = styled(Button)`
  flex-grow: 1;
  min-height: 42px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    min-height: 32px;
  }
`;
