import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 32px;
  }
`;

export const SettingsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SectionHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: stretch;
    gap: 8px;
  }
`;

export const SectionTitle = styled.h2`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: left;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const WarningBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  border-radius: 48px;
  background-color: #fff6d9;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #272727;

  & svg {
    flex-shrink: 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }
`;

export const SettingsItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-items: stretch;
    gap: 12px;
  }
`;

export const SettingsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SettingsItemTitle = styled.h3`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const SettingsItemParagraph = styled.p`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SettingsItemText = styled(SText)`
  font-size: 14px;
  line-height: 18px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const AuthButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
`;

export const GoogleAuthAddedBadge = styled(SText)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 42px;
  padding: 12px 16px;
  border-radius: 24px;
  background-color: #f4f4f7;
  font-size: 14px;
  line-height: 18px;

  & svg {
    flex-shrink: 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const SettingsButton = styled(Button)`
  padding: 11px 15px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 6px 8px;
  }
`;

export const DisableGoogleAuthButton = styled(SettingsButton)`
  border-color: #ec494f;
  color: #ec494f;

  & svg path {
    fill: #ec494f;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    background-color: #ec494f;
    color: #fff;

    & svg path {
      fill: #fff;
    }
  }
`;
