import React from 'react';
import { ReactComponent as GoogleIcon } from 'assets/icons/button-icons/google-alt.svg';

import * as S from './GoogleLoginButton.styled';

interface IGoogleLoginButtonProps {
  handleGoogleLoginClick: () => void;
  isDisabled?: boolean;
}

export const GoogleLoginButton: React.FC<IGoogleLoginButtonProps> = ({
  handleGoogleLoginClick,
  isDisabled = false,
}) => {
  return (
    <S.GoogleLoginButton
      text="Sign in with Google"
      onClick={handleGoogleLoginClick}
      icon={<GoogleIcon />}
      disabled={isDisabled}
    />
  );
};
