import { isValidEmail } from 'utils/validators/is-valid-email';

export const hideEmailAddress = (email: string): string => {
  if (isValidEmail(email)) {
    return '';
  }
  const emailPartsSeparatedByDot = email.split('.');
  const firstLetter = emailPartsSeparatedByDot[0].charAt(0);
  const lastLetter = emailPartsSeparatedByDot[emailPartsSeparatedByDot.length - 2].slice(-1);
  const topLevelDomain = emailPartsSeparatedByDot[emailPartsSeparatedByDot.length - 1];
  return `${firstLetter}...@...${lastLetter}.${topLevelDomain}`;
};
