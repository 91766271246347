import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 48px 32px 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 0 64px;
  }
`;
