import React from 'react';
import { SContainer, SInvoiceWrapper, SPaymentWrapper } from 'pages/PagePurchase/components/styled';
import { InvoiceDescription } from 'pages/PagePurchase/components/ui/InvoiceDescription';
import { PaymentAgreementForm } from 'pages/PagePurchase/components/ui/PaymentAgreementForm';
import { PaymentDetails } from 'pages/PagePurchase/components/ui/PaymentDetails';
import { PromoCodeArea } from 'pages/PagePurchase/components/ui/PromoCodeArea';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { IPaymentAgreementFormValue } from 'pages/PagePurchase/types/payment-agreement-form.interface';
import { IPromoCodeAreaValue } from 'pages/PagePurchase/types/promo-code-area-value.interface';
import { TDiscountType } from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';
import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';

interface IInvoiceScreenProps {
  purchaseStep: TPurchaseStep;
  cartList: IPurchaseCartItemMapped[];
  sumUsd: number;
  finalSumBtc: number;
  discount: number;
  discountType: TDiscountType;
  btcEarned: number;
  promoStatus: EPromoCodeStatus;
  handlePromoCodeButtonClick: () => void;
  handlePromoCodeSubmit: (value: IPromoCodeAreaValue) => void;
  handleCloseButtonClick: () => void;
  handleAdditionalTermsClick: () => void;
  handlePaymentSubmit: (value: IPaymentAgreementFormValue) => void;
  isPaymentSubmitting?: boolean;
}

export const InvoiceScreen: React.FC<IInvoiceScreenProps> = ({
  purchaseStep,
  cartList,
  sumUsd,
  finalSumBtc,
  discount,
  discountType,
  btcEarned,
  promoStatus,
  handlePromoCodeButtonClick,
  handlePromoCodeSubmit,
  handleCloseButtonClick,
  handleAdditionalTermsClick,
  handlePaymentSubmit,
  isPaymentSubmitting,
}) => {
  return (
    <SContainer>
      <SInvoiceWrapper $purchaseStep={purchaseStep}>
        <SVisuallyHidden as="h2">Invoice data</SVisuallyHidden>

        <InvoiceDescription cartItems={cartList} />

        <SPaymentWrapper>
          <PromoCodeArea
            promoStatus={promoStatus}
            handlePromoCodeButtonClick={handlePromoCodeButtonClick}
            handlePromoCodeSubmit={handlePromoCodeSubmit}
            handleCloseButtonClick={handleCloseButtonClick}
          />
          <PaymentDetails
            sumUsd={sumUsd}
            finalSumBtc={finalSumBtc}
            discount={discount}
            discountType={discountType}
            btcEarned={btcEarned}
          />
          <PaymentAgreementForm
            handleAdditionalTermsClick={handleAdditionalTermsClick}
            handlePaymentSubmit={handlePaymentSubmit}
            isSubmitting={isPaymentSubmitting}
          />
        </SPaymentWrapper>
      </SInvoiceWrapper>
    </SContainer>
  );
};
