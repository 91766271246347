import React from 'react';

import { BUY_MINER_TITLE } from './constants/miners-header.consts';

import * as S from './MinersHeader.styled';

export const MinersHeader: React.FC = () => {
  return (
    <S.Container>
      <S.Title>{BUY_MINER_TITLE}</S.Title>
    </S.Container>
  );
};
