import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TPaymentStatus } from 'store/api/cuverse-api/deposit/types/deposit-payment-status-response.interface';
import { IDepositResponseData } from 'store/api/cuverse-api/deposit/types/deposit-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TDepositStep } from 'types/deposit/deposit-step.type';

import {
  requestDepositThunkAction,
  requestPaymentStatusThunkAction,
} from './deposit.thunk-actions';

export interface IDepositState {
  depositStep: TDepositStep;
  depositData: IDepositResponseData;
  depositDataFetchStatus: TFetchStatus;
  paymentStatus: TPaymentStatus;
  paymentFetchStatus: TFetchStatus;
  error: string | null;
}

export const initialState: IDepositState = {
  depositStep: 'initial',
  depositData: {
    amount: '',
    address: '',
    code: '',
  },
  depositDataFetchStatus: 'initial',
  paymentStatus: 'NEW',
  paymentFetchStatus: 'initial',
  error: null,
};

const depositSlice = createSlice({
  name: 'deposit',
  initialState,
  reducers: {
    setDepositStepAction: (state, { payload }: { payload: TDepositStep }) => {
      state.depositStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestDepositThunkAction.pending, (state) => {
        state.depositDataFetchStatus = 'pending';
        state.paymentStatus = 'NEW';
        state.depositData = { amount: '', address: '', code: '' };
      })
      .addCase(
        requestDepositThunkAction.fulfilled,
        (state, action: PayloadAction<IDepositResponseData>) => {
          state.depositDataFetchStatus = 'fulfilled';
          state.depositData = action.payload;
        },
      )
      .addCase(requestDepositThunkAction.rejected, (state, error) => {
        state.depositDataFetchStatus = 'rejected';
        state.error = JSON.stringify(error);
      })
      .addCase(requestPaymentStatusThunkAction.pending, (state) => {
        state.paymentFetchStatus = 'pending';
      })
      .addCase(
        requestPaymentStatusThunkAction.fulfilled,
        (state, action: PayloadAction<TPaymentStatus>) => {
          state.paymentFetchStatus = 'fulfilled';
          state.paymentStatus = action.payload;
        },
      )
      .addCase(requestPaymentStatusThunkAction.rejected, (state) => {
        state.paymentFetchStatus = 'rejected';
        state.paymentStatus = 'ERROR';
      });
  },
});

export const { setDepositStepAction } = depositSlice.actions;
export const depositReducer = depositSlice.reducer;
