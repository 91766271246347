import React from 'react';
import { ReactComponent as CartIcon } from 'assets/icons/purchase-page-icons/shopping-basket.svg';

import { ADD_MINERS_TEXT, CART_EMPTY_TITLE } from './constants/cart-empty.consts';

import * as S from './CartEmpty.styled';

export const CartEmpty: React.FC = () => {
  return (
    <>
      <S.CartEmptyTitle>{CART_EMPTY_TITLE}</S.CartEmptyTitle>
      <CartIcon />
      <S.Text>{ADD_MINERS_TEXT}</S.Text>
    </>
  );
};
