import React from 'react';
import { ReactComponent as RefreshIcon } from 'assets/icons/button-icons/refresh.svg';
import { IPaymentStatusInfoProps } from 'pages/PageDashboard/components/ui/PaymentStatusInfo/types/payment-status-info-props.interface';
import { EPaymentStatus } from 'types/ui/PaymentStatus/payment-status.enum';

import { PaymentStatus } from 'components/ui/PaymentStatus';

import { STATUS_TITLE } from './constants/ui-text';
import { useCheckButtonAttributes } from './hooks/useCheckButtonAttributes';

import * as S from './PaymentStatusInfo.styled';

export const PaymentStatusInfo: React.FC<IPaymentStatusInfoProps> = ({
  paymentStatus,
  handleStatusCheck,
  handleRefreshAddress,
  isDataPending,
}) => {
  const { buttonText, buttonVariant } = useCheckButtonAttributes(paymentStatus);

  const isPaid = paymentStatus === EPaymentStatus.Paid;
  const isExpired = paymentStatus === EPaymentStatus.Expired;

  return (
    <S.Container>
      <S.StatusWrapper>
        <S.StatusTitle>{STATUS_TITLE}</S.StatusTitle>
        {isDataPending ? <S.StatusSpinner /> : <PaymentStatus paymentStatus={paymentStatus} />}
      </S.StatusWrapper>
      {!isPaid && (
        <S.CheckButton
          variant={buttonVariant}
          text={buttonText}
          icon={<RefreshIcon />}
          isReversed={true}
          disabled={isDataPending}
          onClick={isExpired ? handleRefreshAddress : handleStatusCheck}
        />
      )}
    </S.Container>
  );
};
