import React from 'react';
import { ReactComponent as CuverseLogo } from 'assets/icons/logo-icons/logo-picture.svg';

import { useAuthSuccessAttributes } from './hooks/useAuthSuccessAttributes';
import { IAuthSuccessProps } from './types/auth-success-props.interface';

import * as S from './AuthSuccess.styled';

export const AuthSuccess: React.FC<IAuthSuccessProps> = ({ variant, handleContinue }) => {
  const { title, descriptionContent, linkText, linkUrl } = useAuthSuccessAttributes(variant);

  return (
    <S.SuccessSection>
      <CuverseLogo />
      <S.Title>{title}</S.Title>
      <S.DescriptionParagraph>{descriptionContent}</S.DescriptionParagraph>
      <S.ContinueLink to={linkUrl} onClick={handleContinue}>
        {linkText}
      </S.ContinueLink>
    </S.SuccessSection>
  );
};
