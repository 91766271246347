import { useEffect, useState } from 'react';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { BORDERS_HEIGHT_COMPENSATION_PIXELS } from 'components/ui/TextArea/constants/borders-height-compensation-pixels';
import { MAX_DESKTOP_TEXTAREA_HEIGHT_PIXELS } from 'components/ui/TextArea/constants/max-desktop-textarea-height-pixels';

export const useTextAreaHeightCalculation = (
  textAreaElement: HTMLTextAreaElement | null,
  textValue: string,
): number => {
  const [height, setHeight] = useState(0);

  const { innerWidth } = useInnerWidth();

  useEffect(() => {
    if (textAreaElement && innerWidth >= parseInt(BreakPoint.TabletLow)) {
      textAreaElement.style.height = '0px';
      const calculatedHeight = textAreaElement.scrollHeight + BORDERS_HEIGHT_COMPENSATION_PIXELS;
      textAreaElement.style.removeProperty('height');

      if (calculatedHeight !== height) {
        const newHeight =
          calculatedHeight <= MAX_DESKTOP_TEXTAREA_HEIGHT_PIXELS
            ? calculatedHeight
            : MAX_DESKTOP_TEXTAREA_HEIGHT_PIXELS;
        setHeight(newHeight);
      }
    }
  }, [innerWidth, textAreaElement, textValue, height]);

  return height;
};
