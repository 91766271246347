import styled from 'styled-components';

export const SVisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0 none;
  overflow: hidden;
  clip-path: inset(100%);
`;
