import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';

export const ElectricityValueWrapper = styled.dd`
  display: flex;
  align-items: center;
  justify-content: start;
  grid-area: electricityValue;
`;

export const ActualElectricityPrice = styled(SText)`
  grid-area: actualElectricityPrice;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-right: 4px;
  }
`;
