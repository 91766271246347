import { forwardRef } from 'react';

import { LinkItem } from 'components/ui/LinkItem';

import { ICheckboxProps } from './Checkbox.types';

import * as S from './Checkbox.styled';

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      labelText,
      hasErrors = false,
      linkText,
      linkTo,
      isOuterLink = false,
      handleLinkClick,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <S.Label $hasErrors={hasErrors} className={className}>
        <S.Checkbox type="checkbox" {...props} ref={ref} $hasError={hasErrors} />
        <S.LabelText $hasErrors={hasErrors}>
          {labelText}
          {linkText && (
            <>
              {' '}
              <LinkItem
                linkTo={linkTo ? linkTo : ''}
                text={linkText}
                isOuterLink={isOuterLink}
                handleClick={handleLinkClick ? handleLinkClick : undefined}
              />
            </>
          )}
        </S.LabelText>
      </S.Label>
    );
  },
);
