import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const VerificationPageHeader = styled.header`
  display: flex;
  align-items: center;
  min-height: 160px;
  padding: 32px 24px;
  background-color: #0e46c0;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 24px 16px;
  }
`;

export const VerificationPageTitle = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: #fff;
`;

export const SumsubWrapper = styled.section`
  width: 96%;
  min-height: 75vh;
  margin: -24px auto 0;
  padding: 24px 0;
  border-radius: 16px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    min-height: 78vh;
    margin: 0;
    border-radius: 0;
  }
`;
