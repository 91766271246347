import React from 'react';

import { MAIN_SITE_LINK } from './constants/main-site-link';

import * as S from './NavigationFooter.styled';

interface INavigationFooterProps {
  handleLogout: () => void;
  isLoggingOut: boolean;
}

export const NavigationFooter: React.FC<INavigationFooterProps> = ({
  handleLogout,
  isLoggingOut,
}) => {
  return (
    <S.FooterLinksWrapper>
      <S.LogoutButton onClick={handleLogout} disabled={isLoggingOut}>
        Log out
      </S.LogoutButton>
      <S.MainSiteLink href={MAIN_SITE_LINK}>Go to the main site →</S.MainSiteLink>
    </S.FooterLinksWrapper>
  );
};
