import { AxiosResponse } from 'axios';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';
import { IDepositPaymentStatusRequestParams } from 'store/api/cuverse-api/deposit/types/deposit-payment-status-request-params.interface';
import { IDepositPaymentStatusResponse } from 'store/api/cuverse-api/deposit/types/deposit-payment-status-response.interface';
import { IDepositRequestBody } from 'store/api/cuverse-api/deposit/types/deposit-request-body.interface';
import { IDepositResponse } from 'store/api/cuverse-api/deposit/types/deposit-response.interface';

import { DepositRoute } from './deposit.route';

export const requestDeposit = async (body: IDepositRequestBody): Promise<IDepositResponse> =>
  cuverseApi
    .post<
      IDepositResponse,
      AxiosResponse<IDepositResponse>,
      IDepositRequestBody
    >(DepositRoute.DashboardDeposit(), body)
    .then((res) => {
      return res?.data;
    });

export const requestDepositPaymentStatus = async ({
  address,
  coin,
}: IDepositPaymentStatusRequestParams): Promise<IDepositPaymentStatusResponse> =>
  cuverseApi
    .get<
      IDepositPaymentStatusResponse,
      AxiosResponse<IDepositPaymentStatusResponse>
    >(DepositRoute.DashboardPaymentStatus(address, coin))
    .then((res) => {
      return res?.data;
    });
