import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ReactComponent as RetryIcon } from 'assets/icons/button-icons/arrow-clockwise.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/button-icons/copy.svg';
import { ReactComponent as AppStoreLogo } from 'assets/icons/link-icons/app-store-logo.svg';
import { ReactComponent as AppStoreTitle } from 'assets/icons/link-icons/app-store-title.svg';
import { ReactComponent as GooglePlayLogo } from 'assets/icons/link-icons/google-play-logo.svg';
import { ReactComponent as GooglePlayTitle } from 'assets/icons/link-icons/google-play-title.svg';
import { FetchStatus } from 'constants/fetch-status/fetch-status.const';
import { FormApi } from 'final-form';
import {
  selectConfirm2FAFetchStatus,
  selectEnable2FAFetchStatus,
  selectQrCode2FAUrl,
} from 'store/auth-reducer/auth.selectors';
import {
  requestConfirm2FAThunkAction,
  requestEnable2FAThunkAction,
} from 'store/auth-reducer/auth.thunk-actions';
import { useAppDispatch } from 'store/store';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { copyText } from 'utils/common/copy-text';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isRequired } from 'utils/validators/is-required';

import { GoogleAuthDownloadLink } from './constants/google-auth-download-link.const';
import {
  QR_CODE_DESCRIPTION_TEXT,
  SETUP_FIRST_STEP_TITLE,
  SETUP_SECOND_STEP_SUBTITLE,
  SETUP_SECOND_STEP_TITLE,
  SETUP_THIRD_STEP_TITLE,
} from './constants/ui-text.const';
import { IEnable2FAFormValues } from './types/enable-2fa-form-values.interface';
import { getSecretFromUrl } from './utils/get-secret-from-url.util';

import * as S from './Enable2FAContent.styled';

export const Enable2FAContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const enable2FAFetchStatus = useSelector(selectEnable2FAFetchStatus);
  const isQrDataLoading = enable2FAFetchStatus === FetchStatus.Pending;
  const isQrDataFailed = enable2FAFetchStatus === FetchStatus.Rejected;
  const confirm2FAFetchStatus = useSelector(selectConfirm2FAFetchStatus);
  const is2FAConfirmPending = confirm2FAFetchStatus === FetchStatus.Pending;
  const qrUrl = useSelector(selectQrCode2FAUrl);
  const secretCode = qrUrl ? getSecretFromUrl(qrUrl) : '';

  const handleCopyButtonClick = () => copyText(secretCode);

  const handleRetryClick = () => void dispatch(requestEnable2FAThunkAction());

  const handleCodeChange = (
    value: string,
    form: FormApi<IEnable2FAFormValues, Partial<IEnable2FAFormValues>>,
  ) => form.change('code', removeNonNumericSymbols(value));

  const handleFormSubmit = ({ code }: IEnable2FAFormValues) =>
    void dispatch(requestConfirm2FAThunkAction({ auth_confirm_code: code }));

  return (
    <S.GoogleAuthSetupSteps>
      <S.SetupStep>
        <S.SetupStepTitle>{SETUP_FIRST_STEP_TITLE}</S.SetupStepTitle>
        <S.StoreLinksWrapper>
          <S.StoreLink
            href={GoogleAuthDownloadLink.AppStore}
            title="Download on the App Store"
            target="_blank"
            rel="noreferrer"
          >
            <AppStoreLogo />
            <AppStoreTitle />
          </S.StoreLink>
          <S.StoreLink
            href={GoogleAuthDownloadLink.GooglePlay}
            title="Get it on Google Play"
            target="_blank"
            rel="noreferrer"
          >
            <GooglePlayLogo />
            <GooglePlayTitle />
          </S.StoreLink>
        </S.StoreLinksWrapper>
      </S.SetupStep>

      <S.SetupStep>
        <S.SetupStepTitle>{SETUP_SECOND_STEP_TITLE}</S.SetupStepTitle>
        <S.ScanQrStepWrapper>
          <S.QrCodeWrapper>
            <S.QrCode>
              {isQrDataLoading && <SSpinnerBlue />}
              {!isQrDataLoading && isQrDataFailed && (
                <S.RetryButton
                  variant="borderless"
                  text="Try again"
                  icon={<RetryIcon />}
                  onClick={handleRetryClick}
                />
              )}
              {qrUrl && <S.QrCodeImage width="100" height="100" src={qrUrl} alt="QR-code" />}
            </S.QrCode>
            <S.QrCodeDescription>{QR_CODE_DESCRIPTION_TEXT}</S.QrCodeDescription>
          </S.QrCodeWrapper>

          <S.SecretCodeWrapper>
            <S.SecretCodeTitle>{SETUP_SECOND_STEP_SUBTITLE}</S.SecretCodeTitle>
            <S.SecretCode $isSecretCodeLoaded={!!secretCode}>
              {isQrDataLoading && <S.SecretCodeLoader />}
              {!isQrDataLoading && isQrDataFailed && (
                <S.RetryButton
                  variant="borderless"
                  text="Try again"
                  icon={<RetryIcon />}
                  onClick={handleRetryClick}
                />
              )}
              {secretCode && (
                <>
                  <S.SecretCodeText>{secretCode}</S.SecretCodeText>
                  <S.CopyButton
                    variant="borderless"
                    icon={<CopyIcon />}
                    onClick={handleCopyButtonClick}
                  />
                </>
              )}
            </S.SecretCode>
          </S.SecretCodeWrapper>
        </S.ScanQrStepWrapper>
      </S.SetupStep>

      <S.SetupStep>
        <S.SetupStepTitle>{SETUP_THIRD_STEP_TITLE}</S.SetupStepTitle>
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <S.Form onSubmit={handleSubmit} $isFailed={submitFailed}>
              <Field name="code" validate={composeValidators([isRequired])}>
                {({ input, meta }) => (
                  <S.InputLabel>
                    <S.LabelText>Code</S.LabelText>
                    <S.CodeInput
                      {...input}
                      type="text"
                      hasErrors={meta.error && submitFailed}
                      onChange={(evt) => handleCodeChange(evt.currentTarget.value, form)}
                      isDisabled={isQrDataLoading || is2FAConfirmPending}
                      autoComplete="off"
                    />
                  </S.InputLabel>
                )}
              </Field>

              <S.SubmitButton
                type="submit"
                text="Submit"
                disabled={
                  (submitFailed && hasValidationErrors) || isQrDataLoading || is2FAConfirmPending
                }
              />
            </S.Form>
          )}
        </Form>
      </S.SetupStep>
    </S.GoogleAuthSetupSteps>
  );
};
