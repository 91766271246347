import React from 'react';
import { ReactComponent as BchIcon } from 'assets/icons/currency-icons/bch.svg';
import { ReactComponent as BnbIcon } from 'assets/icons/currency-icons/bnb.svg';
import { ReactComponent as BtcIcon } from 'assets/icons/currency-icons/btc.svg';
import { ReactComponent as BusdIcon } from 'assets/icons/currency-icons/busd.svg';
import { ReactComponent as EthIcon } from 'assets/icons/currency-icons/eth.svg';
import { ReactComponent as LtcIcon } from 'assets/icons/currency-icons/ltc.svg';
import { ReactComponent as UsdcIcon } from 'assets/icons/currency-icons/usdc.svg';
import { ReactComponent as UsdtIcon } from 'assets/icons/currency-icons/usdt.svg';
import { TCurrency } from 'types/ui/CurrencyBadge/currency.type';

import * as S from './CurrencyBadge.styled';

interface ICurrencyBadgeProps {
  currency: TCurrency;
  className?: string;
}

export const CurrencyBadge: React.FC<ICurrencyBadgeProps> = ({ currency, className }) => {
  switch (currency) {
    case 'btc':
      return (
        <S.BtcBadge className={className}>
          <BtcIcon />
          <S.CurrencyName>btc</S.CurrencyName>
        </S.BtcBadge>
      );
    case 'ltc':
      return (
        <S.LtcBadge className={className}>
          <LtcIcon />
          <S.CurrencyName>ltc</S.CurrencyName>
        </S.LtcBadge>
      );
    case 'eth':
      return (
        <S.EthBadge className={className}>
          <EthIcon />
          <S.CurrencyName>eth</S.CurrencyName>
        </S.EthBadge>
      );
    case 'usdt':
      return (
        <S.UsdtBadge className={className}>
          <UsdtIcon />
          <S.CurrencyName>usdt</S.CurrencyName>
        </S.UsdtBadge>
      );
    case 'bnb':
      return (
        <S.BnbBadge className={className}>
          <BnbIcon />
          <S.CurrencyName>bnb</S.CurrencyName>
        </S.BnbBadge>
      );
    case 'busd':
      return (
        <S.BusdBadge className={className}>
          <BusdIcon />
          <S.CurrencyName>busd</S.CurrencyName>
        </S.BusdBadge>
      );
    case 'usdc':
      return (
        <S.UsdcBadge className={className}>
          <UsdcIcon />
          <S.CurrencyName>usdc</S.CurrencyName>
        </S.UsdcBadge>
      );
    case 'bch':
      return (
        <S.BchBadge className={className}>
          <BchIcon />
          <S.CurrencyName>bch</S.CurrencyName>
        </S.BchBadge>
      );
  }
};
