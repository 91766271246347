import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IDashboardElectricityResponse } from './types/dashboard-electricity-response';
import { IDashboardHashRateResponse } from './types/dashboard-hashrate-response';
import { IDashboardMiningTotalResponse } from './types/dashboard-mining-response';
import { IDashboardWalletResponse } from './types/dashboard-wallet-response';
import { DashboardRoute } from './dashboard.route';


export const requestDashboard = async (): Promise<IDashboardWalletResponse> =>
  cuverseApi.get(DashboardRoute.DashboardWallet()).then((res) => {
    return res?.data;
  });

export const requestElectricity = async (): Promise<IDashboardElectricityResponse> =>
  cuverseApi.get(DashboardRoute.DashboardElectricity()).then((res) => {
    return res?.data;
  });

export const requestHashRate = async (): Promise<IDashboardHashRateResponse> =>
  cuverseApi.get(DashboardRoute.DashboardMiningHashRate()).then((res) => {
    return res?.data;
  });

export const requestMinigTotal = async (): Promise<IDashboardMiningTotalResponse> =>
  cuverseApi.get(DashboardRoute.DashboardMiningTotal()).then((res) => {
    return res?.data;
  });
