import React from 'react';
import { ISelectItem } from 'types/ui/Select/select-item.interface';

import * as S from './CustomDatePickerHeader.styled';

interface ICustomDatePickerHeaderProps {
  years: ISelectItem[];
  months: ISelectItem[];
  currentYear: string;
  currentMonth: string;
  handleYearChange: (newValue: string) => void;
  handleMonthChange: (newValue: string) => void;
}

export const CustomDatePickerHeader: React.FC<ICustomDatePickerHeaderProps> = ({
  years,
  months,
  currentYear,
  currentMonth,
  handleYearChange,
  handleMonthChange,
}) => {
  return (
    <S.HeaderContainer>
      <S.MonthSelect items={months} currentValue={currentMonth} onChange={handleMonthChange} />
      <S.YearSelect items={years} currentValue={currentYear} onChange={handleYearChange} />
    </S.HeaderContainer>
  );
};
