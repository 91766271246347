import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';

export const ElectricityValueWrapper = styled.dd`
  display: grid;
  align-items: center;
  grid-template-areas:
    'oldElectricityPrice actualElectricityPrice'
    'cashback cashback';
  grid-template-columns: min-content 1fr;
  grid-area: electricityValue;
  column-gap: 8px;
  row-gap: 2px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    column-gap: 4px;
    row-gap: 4px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    column-gap: 4px;
    row-gap: 4px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-areas: 'oldElectricityPrice actualElectricityPrice cashback';
    grid-template-columns: min-content min-content min-content;
    column-gap: 0;
    row-gap: 0;
  }
`;

export const InitialElectricityPrice = styled(SText)`
  grid-area: oldElectricityPrice;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  text-decoration-line: line-through;
  color: #a1a1a1;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 8px;
  }
`;

export const ElectricityPriceWithCashback = styled(SText)`
  grid-area: actualElectricityPrice;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-right: 4px;
  }
`;

export const CashbackButtonWrapper = styled.div`
  grid-area: cashback;
`
