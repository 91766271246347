export enum EVerificationStatus {
  Unverified = 'Unverified',
  FinalVerified = 'Final verified',
  Verified = 'Verified',
  NeedsVerification = 'Needs verification',
  NeedsFinalVerification = 'Needs final verification',
  FinalVerificationInit = 'Final verification init',
  VerificationInit = 'Verification init',
  FinalVerificationChecking = 'Final verification checking',
  Checking = 'Checking',
  TryAgain = 'Try again',
  AccountIsBlocked = 'Account is blocked',
  LimitWithout2StepVerificationExceeded = 'Limit without 2-step verification exceeded',
  LimitWithoutVerificationExceeded = 'Limit without verification exceeded',
  FinalVerificationInProgress = 'Final verification in progress',
  VerificationInProgress = 'Verification in progress',
  TryVerificationAgain = 'Try verification again',
  EmailVerified = 'Email verified',
  YouAreVerified = 'You are verified!',
  CodeNotFoundOrHasExpired = 'Code not found or has expired!',
  YourEmailAddressIsNotVerified = 'Your email address is not verified.',
  EmailVerificationReturnedError = 'Email verification returned error!',
  TheNotificationWasSentAgain = 'The notification was sent again!',
}

export enum EVerificationStatusCode {
  KYC_STATUS_UNVERIFIED = 0,
  KYC_STATUS_VERIFIED = 1,
  KYC_STATUS_NEEDS_VERIFICATION = 2,
  KYC_STATUS_INIT = 3,
  KYC_STATUS_PROCESSING = 4,
  KYC_STATUS_RETRY = 5,
  KYC_STATUS_REJECT = 6,
  KYC_STATUS_PRIMARY_NEEDS_VERIFICATION = 7,
  KYC_STATUS_PRIMARY_INIT = 8,
  KYC_STATUS_PRIMARY_PROCESSING = 9,
  KYC_STATUS_PRIMARY_VERIFIED = 11,
  KYC_STATUS_PRIMARY_RETRY = 12,
  KYC_STATUS_PRIMARY_REJECT = 13,
}

export interface IProfileData {
  firstname: string;
  lastname: string;
  id: number;
  avatar: string;
  level: number;
  birthdate: string;
  email: string;
  country: string;
  timezone: string;
  state: string;
  city: string;
  zip: string;
  address: string;
  verificationStatusCode: EVerificationStatusCode;
  verificationStatus: EVerificationStatus;
  walletLockedUntil: number;
  is2FAEnabled: boolean;
  isApproved: boolean;
  isLocked: boolean;
  isManager: boolean;
}

type TNumericFlag = 0 | 1;

interface IData {
  firstname: string | null;
  lastname: string | null;
  id: number;
  avatar: string | null;
  level: number;
  dob: string | null;
  email: string | null;
  country: string | null;
  timezone: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
  address1: string | null;
  verification_status_code: EVerificationStatusCode;
  verification_status: EVerificationStatus;
  wallet_locked_until: number;
  is_2fa: TNumericFlag;
  is_approved: boolean;
  is_locked: TNumericFlag;
  is_manager: TNumericFlag;
}

export interface IProfileResponse {
  data: IData;
}
