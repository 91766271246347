import React from 'react';

import { Profile } from 'components/ui/Profile';
import { AppRoute } from 'utils/route/app-route';

import * as S from './MenuProfileLink.styled';

export const MenuProfileLink: React.FC = () => {
  return (
    <S.ProfileLinkWrapper to={AppRoute.Profile()}>
      <Profile variant="menu" />
    </S.ProfileLinkWrapper>
  );
};
