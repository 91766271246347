import { AxiosResponse } from 'axios';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { ICartCouponRequestBody } from './types/cart-coupon-request-body.interface';
import { ICartCouponResponse } from './types/cart-coupon-response.interface';
import { IInvoiceRequestBody } from './types/invoice-request-body.interface';
import { IInvoiceResponse } from './types/invoice-response.interface';
import { InvoiceRoute } from './invoice.route';

export const requestInvoice = async (body: IInvoiceRequestBody): Promise<IInvoiceResponse> =>
  cuverseApi.post(InvoiceRoute.Invoice(), body).then((res) => {
    return res?.data;
  });

export const applyCartCoupon = async (body: ICartCouponRequestBody): Promise<ICartCouponResponse> =>
  cuverseApi
    .patch<
      ICartCouponResponse,
      AxiosResponse<ICartCouponResponse>,
      ICartCouponRequestBody
    >(InvoiceRoute.CartCoupon(), body)
    .then((res) => res?.data);
