import { PAGE_NUMBER_OFFSET } from 'components/ui/Pagination/constants/page-number-offset';

export const getPageNumbersArray = (numberOfPages: number, currentPageNumber: number): number[] => {
  if (numberOfPages <= 0) {
    return [];
  }

  const pageNumbersArray = Array.from({ length: numberOfPages }, (_, i) => i + 1);

  if (numberOfPages <= PAGE_NUMBER_OFFSET * 2 + 1) {
    return pageNumbersArray;
  }

  let startPosition = currentPageNumber - PAGE_NUMBER_OFFSET;
  let endPosition = currentPageNumber + PAGE_NUMBER_OFFSET;

  if (startPosition < 1) {
    startPosition = 1;
  }

  if (endPosition > numberOfPages) {
    endPosition = numberOfPages;
  }

  if (startPosition === 1) {
    endPosition = PAGE_NUMBER_OFFSET * 2 + 1;
  }

  if (endPosition === numberOfPages) {
    startPosition = numberOfPages - PAGE_NUMBER_OFFSET * 2;
  }

  return pageNumbersArray.slice(startPosition - 1, endPosition);
};
