import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IAffiliateState } from './affiliate.reducer';

const getAffiliateState = (state: TRootState): IAffiliateState => state.affiliateReducer;

export const selectReferralStats = createSelector(
  [getAffiliateState],
  (state) => state.referralStats,
);

export const selectReferralStatsStatus = createSelector(
  [getAffiliateState],
  (state) => state.referralStatsStatus,
);

export const selectReferralLink = createSelector(
  [getAffiliateState],
  (state) => state.referralLink.link,
);

export const selectReferralLinkFetchStatus = createSelector(
  [getAffiliateState],
  (state) => state.referralLinkFetchStatus,
);

export const selectReferralHistory = createSelector(
  [getAffiliateState],
  (state) => state.referralHistory,
);

export const selectReferralHistoryStatus = createSelector(
  [getAffiliateState],
  (state) => state.referralHistoryFetchStatus,
);
