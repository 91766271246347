import React from 'react';
import { DesktopRowsByModel } from 'pages/PageAssets/components/ui/DesktopRowsByModel';
import {
  DAILY_INCOME,
  HASH_RATE_AND_QUANTITY,
  POWER_CONSUMPTION,
  STATUS,
  TYPE_AND_NAME,
} from 'pages/PageAssets/constants/constants';
import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';

import * as S from './AssetsTableDesktop.styled';

interface IAssetsTableDesktopProps {
  minersByModel: TMinersGroupByModel[];
  btcToUsdRate: number;
}

export const AssetsTableDesktop: React.FC<IAssetsTableDesktopProps> = ({
  minersByModel,
  btcToUsdRate,
}) => {
  return (
    <S.TableWrapper>
      <S.Table as="table">
        <thead>
          <tr>
            <th>
              <S.HeadCell>{TYPE_AND_NAME}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{HASH_RATE_AND_QUANTITY}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{POWER_CONSUMPTION}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{DAILY_INCOME}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{STATUS}</S.HeadCell>
            </th>
          </tr>
        </thead>
        <tbody>
          {minersByModel.map((minersGroup: TMinersGroupByModel) => {
            const minerModel = Object.keys(minersGroup)[0];
            return (
              <DesktopRowsByModel
                key={minerModel}
                minersGroupByModel={minersGroup}
                btcToUsdRate={btcToUsdRate}
              />
            );
          })}
        </tbody>
      </S.Table>
    </S.TableWrapper>
  );
};
