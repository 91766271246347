import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const GoogleAuthSetupSteps = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;
  counter-reset: num;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 24px;
    padding: 0 16px 24px;
  }
`;

export const SetupStep = styled.li`
  display: flex;
  flex-direction: column;
  gap: 10px;
  counter-increment: num;

  &:nth-child(3) {
    gap: 12px;
  }
`;

export const SetupStepTitle = styled(SText)`
  display: flex;
  align-items: baseline;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;

  &::before {
    content: counter(num);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    padding: 4px;
    border-radius: 64px;
    background-color: #e5eaf1;
    font-family: ${FontFamily.Montserrat};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #646464;
  }
`;

export const StoreLinksWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding-left: 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-left: 0;
  }
`;

export const StoreLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 32px;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #000;
  text-decoration: none;
  transition: background-color ${TransitionTime.Hover};
  outline: none;

  & svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #03349e;
  }
`;

export const ScanQrStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-left: 0;
  }
`;

export const QrCodeWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const QrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 116px;
  height: 116px;
  border: 1px solid #a1a1a1;
  border-radius: 8px;
`;

export const QrCodeImage = styled.img`
  display: block;
  width: 100px;
  height: 100px;
`;

export const QrCodeDescription = styled(SText)`
  padding: 8px 0;
  opacity: 0.8;
  font-size: 12px;
  line-height: 16px;
  color: #646464;
`;

export const RetryButton = styled(Button)`
  margin: 0 auto;
`;

export const SecretCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SecretCodeTitle = styled(SetupStepTitle)`
  &::before {
    content: none;
  }
`;

export const SecretCode = styled.div<{ $isSecretCodeLoaded: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  min-height: 36px;
  padding: ${({ $isSecretCodeLoaded }) => ($isSecretCodeLoaded ? '8px 40px 8px 12px' : '8px 12px')};
  border-radius: 12px;
  background-color: #f4f4f7;
`;

export const SecretCodeLoader = styled(SSpinnerBlue)`
  width: 20px;
  height: 20px;
  margin: 0 auto;
`;

export const SecretCodeText = styled(SText)`
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
`;

export const CopyButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 10px 12px;
`;

export const Form = styled.form<{ $isFailed: boolean }>`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  padding-left: 32px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-left: 0;
  }
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const LabelText = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;
`;

export const CodeInput = styled(Input)`
  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      height: 42px;
      padding: 11px 15px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;
`;
