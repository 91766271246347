import React from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as WarningIcon } from 'assets/icons/profile-page-icons/warning-triangle.svg';
import { FormApi } from 'final-form';
import { setChangeWalletAddressStepAction, setNewWalletAddressAction } from 'store/profile-reducer/profile.reducer';
import { requestWalletApproveOtpThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';

import { removeSpacesAndEmojis } from 'utils/formatters/remove-spaces-and-emojis.util';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isRequired } from 'utils/validators/is-required';
import { isValidBtcAddress } from 'utils/validators/is-valid-btc-address';

import { DESCRIPTION_TEXT, WALLET_LABEL_TEXT, WARNING_TEXT } from './constants/ui-text.const';
import { IWalletAddressAddingFormValues } from './types/withdraw-wallet-address-adding-form-values.interface';

import * as S from './WalletAddressAdding.styled';

export const WalletAddressAdding: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleWalletAddressChange = (
    value: string,
    form: FormApi<IWalletAddressAddingFormValues, Partial<IWalletAddressAddingFormValues>>,
  ) => {
    const formattedValue = removeSpacesAndEmojis(value);
    form.change('walletAddress', formattedValue);
  };

  const handleConfirmSubmit = (values: IWalletAddressAddingFormValues) => {
    setEmailSendTime();
    void dispatch(requestWalletApproveOtpThunkAction({ type: 'btc_wallet' }));
    dispatch(setChangeWalletAddressStepAction('wallet-address-confirm'));
    dispatch(setNewWalletAddressAction(values.walletAddress));
  };
  return (
    <S.Wrapper>
      <S.Description>{DESCRIPTION_TEXT}</S.Description>

      <Form onSubmit={handleConfirmSubmit}>
        {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
          <S.Form onSubmit={handleSubmit}>
            <S.FieldsWrapper $isFailed={submitFailed}>
              <Field
                name="walletAddress"
                validate={composeValidators([isRequired, isValidBtcAddress])}
              >
                {({ input, meta }) => (
                  <S.WalletAddressLabel>
                    <S.LabelText>{WALLET_LABEL_TEXT}</S.LabelText>
                    <S.WalletAddressInput
                      {...input}
                      type="text"
                      hasErrors={meta.error && submitFailed}
                      onChange={(evt) => handleWalletAddressChange(evt.currentTarget.value, form)}
                    />

                    {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                  </S.WalletAddressLabel>
                )}
              </Field>
            </S.FieldsWrapper>

            <S.WithdrawButton
              type="submit"
              text="Confirm"
              disabled={submitFailed && hasValidationErrors}
            />

            <S.WarningWrapper>
              <WarningIcon />
              <S.WarningText>{WARNING_TEXT}</S.WarningText>
            </S.WarningWrapper>
          </S.Form>
        )}
      </Form>
    </S.Wrapper>
  );
};
