import { MINUTE_IN_SECONDS } from 'constants/time/minute-in-seconds.const';

export const getFormattedTimeDelay = (timeDelay: number | string): string => {
  const timeDelayNumber = Number(timeDelay);
  if (isNaN(timeDelayNumber) || timeDelayNumber < 0) {
    return '00:00';
  }
  const formattedMinutes = String(Math.floor(timeDelayNumber / MINUTE_IN_SECONDS)).padStart(2, '0');
  const formattedSeconds = (timeDelayNumber % MINUTE_IN_SECONDS).toFixed(0).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
};
