import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TWithdrawalStep } from 'pages/PageDashboard/components/ui/WithdrawForm/types/withdrawal-step.type';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { confirmWithdrawThunkAction, requestWithdrawThunkAction } from './withdraw.thunk-actions';

export interface IWithdrawState {
  debugError: string | null;
  withdrawStep: TWithdrawalStep;
  withdrawRequestStatus: TFetchStatus;
  withdrawConfirmationStatus: TFetchStatus;
}

export const initialState: IWithdrawState = {
  debugError: null,
  withdrawStep: 'initial',
  withdrawRequestStatus: 'initial',
  withdrawConfirmationStatus: 'initial',
};

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    setWithdrawStepAction: (state, action: PayloadAction<TWithdrawalStep>) => {
      state.withdrawStep = action.payload;
    },
    resetWithdrawConfirmationStatus: (state) => {
      state.withdrawConfirmationStatus = 'initial';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestWithdrawThunkAction.pending, (state) => {
        state.withdrawRequestStatus = 'pending';
        state.debugError = null;
      })
      .addCase(requestWithdrawThunkAction.fulfilled, (state) => {
        state.withdrawRequestStatus = 'fulfilled';
      })
      .addCase(requestWithdrawThunkAction.rejected, (state, error) => {
        state.withdrawRequestStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });

    builder
      .addCase(confirmWithdrawThunkAction.pending, (state) => {
        state.withdrawConfirmationStatus = 'pending';
        state.debugError = null;
      })
      .addCase(confirmWithdrawThunkAction.fulfilled, (state) => {
        state.withdrawConfirmationStatus = 'fulfilled';
      })
      .addCase(confirmWithdrawThunkAction.rejected, (state, error) => {
        state.withdrawConfirmationStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });
  },
});

export const {
  setWithdrawStepAction, 
  resetWithdrawConfirmationStatus,
} = withdrawSlice.actions;
export const withdrawReducer = withdrawSlice.reducer; 
