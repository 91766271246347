import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { VerificationPanel } from 'pages/PageVerification/components/ui/VerificationPanel';
import {
  LABEL_FOR_BASIC_VERIFIED,
  LABEL_FOR_NOT_VERIFIED,
} from 'pages/PageVerification/constants/constants';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { requestProfileDataThunkAction } from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { TProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

import { Tabs } from 'components/ui/Tabs';
import { getIsBasicVerificationStatus } from 'utils/common/get-is-basic-verification-status.util';
import { getIsFullVerificationStatus } from 'utils/common/get-is-full-verification-status.util';
import { getVerificationPanelText } from 'utils/common/get-verification-panel-text.util';
import { getVerificationStatusTag } from 'utils/common/get-verification-status-tag.util';
import { AppRoute } from 'utils/route/app-route';

import { UserNameCard } from './components/ui/UserNameCard';
import { profileTabItems } from './constants/profile-tab-items.const';
import { useActiveTabValue } from './hooks/useActiveTabValue';

import * as S from './PageProfile.styled';

export const PageProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { firstname, lastname, verificationStatusCode } = useSelector(selectProfile);
  const activeTabValue = useActiveTabValue();
  const status = getVerificationStatusTag(verificationStatusCode);

  const isBasicVerification = getIsBasicVerificationStatus(verificationStatusCode);
  const isFullVerification = getIsFullVerificationStatus(verificationStatusCode);
  const verificationPanelLabel = isBasicVerification
    ? LABEL_FOR_BASIC_VERIFIED
    : LABEL_FOR_NOT_VERIFIED;
  const verificationInfo = getVerificationPanelText(isBasicVerification);
  const verificationPanelData = isBasicVerification ? [verificationInfo[1]] : verificationInfo;
  const fullName = `${firstname} ${lastname}`;

  const handleTabClick = (newTabValue: string) =>
    navigate(AppRoute.ProfileTab(newTabValue as TProfileTabName));

  useEffect(() => {
    void dispatch(requestProfileDataThunkAction());
  }, [dispatch]);

  return (
    <S.Container>
      <S.PageTitle>My profile</S.PageTitle>
      <UserNameCard verificationStatus={status} fullName={fullName} />
      <S.ProfileTabsWrapper>
        {!isFullVerification && (
          <VerificationPanel
            label={verificationPanelLabel}
            hasLimitTag={isBasicVerification}
            data={verificationPanelData}
          />
        )}
        <Tabs
          tabItems={profileTabItems}
          activeTabValue={activeTabValue}
          handleTabClick={handleTabClick}
        />
        <S.TabsContentWrapper>
          <S.TabsContent>
            <Outlet />
          </S.TabsContent>
        </S.TabsContentWrapper>
      </S.ProfileTabsWrapper>
    </S.Container>
  );
};
