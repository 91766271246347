import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Button } from 'components/ui/Button';

export const GoogleLoginButton = styled(Button)`
  gap: 8px;
  padding: 12px 16px;
  border-radius: 24px;
  background-color: #ec494f;

  &:hover,
  &:focus {
    background-color: #d41e3a;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 7px 12px;
  }
`;
