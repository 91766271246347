/**
 * Formats the given value as a string with specified fraction digits for correct transaction.
 *
 * @param {string | number} value - The value to be formatted.
 * @param {number} fractionDigits - The maximum number of digits allowed after the decimal point.
 * @returns {string} - The formatted value as a string.
 */
export const formatInputValue = (value: string | number, fractionDigits: number): string => {
  if (value === '') {
    return '';
  }

  const formattedValue = String(value).replace(',', '.').replace(/^\./, '0.');

  if (formattedValue.includes('.')) {
    let [beforeDot, afterDot] = formattedValue.split('.');

    beforeDot = beforeDot
      .replace(/\D/g, '')
      .replace(/^0{2,}/, '0')
      .replace(/^(0[1-9])/, (match) => match.replace('0', ''));
    afterDot = afterDot ? afterDot.replace(/\D/g, '') : '';

    return afterDot ? `${beforeDot}.${afterDot?.slice(0, fractionDigits)}` : `${beforeDot}.`;
  }

  return formattedValue
    .replace(/\D/g, '')
    .replace(/^0{2,}/, '0')
    .replace(/^(0[1-9])/, (match) => match.replace('0', ''));
};
