export enum ECategory {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
  mining = 'mining',
  invoice = 'invoice',
  electricity_fee = 'electricity_fee',
}

export interface ITransaction {
  time: string;
  datetime: string;
  id: number;
  title: string;
  category: ECategory;
  status: number;
  amount: number;
  balance: number;
  coin: string;
}

export type TTransactionHistoryData = Record<string, ITransaction[]>;

export interface ITransactionHistoryDataMapped {
  count: number;
  initialDate: string;
  transactionHistoryData: ITransaction[];
}

export interface ITransactionHistoryRequestParams {
  count: number;
  dateStart: string;
}

export interface ITransactionHistoryResponse {
  count: number;
  initial_date: string;
  data: TTransactionHistoryData;
}
