import styled  from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const MinerItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 24px;
  background: #fff;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    padding: 16px;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 16px;
    border-radius: 16px;
  }
`;

export const MinerDataWrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-areas:
    'image title title'
    'image performance finance';
  grid-template-columns: min-content auto 1fr;
  grid-template-rows: min-content auto;
  margin-bottom: 24px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    margin-bottom: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-template-areas:
      'image title'
      'image performance'
      'image finance';
    grid-template-columns: min-content auto;
    margin-bottom: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-areas:
      'image title'
      'image performance'
      'finance finance';
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr auto;
    margin-bottom: 16px;
  }
`;

export const MinerPicture = styled.picture`
  grid-area: image;
  width: 120px;
  height: 120px;
  margin-right: 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 80px;
    height: 80px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  & img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const MinerName = styled(SText)`
  grid-area: title;
  margin-bottom: 16px;
  justify-self: start;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const PerformanceData = styled.dl`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-areas:
    'currency hash'
    'btc index';
  grid-template-columns: auto auto;
  grid-area: performance;
  margin-right: 25px;
  row-gap: 10px;
  column-gap: 25px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    row-gap: 4px;
    column-gap: 24px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    row-gap: 4px;
    column-gap: 24px;
    margin-right: 0;
    margin-bottom: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    row-gap: 4px;
    column-gap: 24px;
    margin-right: 0;
  }
`;

const MinerDescriptionTitle = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #a1a1a1;
`;

const minerDescriptionValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const CurrencyTitle = styled(MinerDescriptionTitle)`
  grid-area: currency;
`;

export const CurrencyValue = styled(minerDescriptionValue)`
  grid-area: btc;
  justify-self: start;

  & > div {
    display: flex;

    @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
      gap: 4px;
      min-width: 50px;
      height: 16px;

      & svg {
        width: 16px;
        height: 16px;
      }

      & span {
        font-size: 10px;
      }
    }

    @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
      gap: 4px;
      min-width: 50px;
      height: 16px;

      & svg {
        width: 16px;
        height: 16px;
      }

      & span {
        font-size: 10px;
      }
    }

    @media (max-width: ${BreakPoint.MobileTop}) {
      gap: 4px;
      min-width: 50px;
      height: 16px;

      & svg {
        width: 16px;
        height: 16px;
      }

      & span {
        font-size: 10px;
      }
    }
  }
`;

export const HashTitle = styled(MinerDescriptionTitle)`
  grid-area: hash;
`;

export const HashValue = styled(minerDescriptionValue)`
  grid-area: index;
`;

export const FinanceData = styled.dl`
  display: grid;
  align-items: start;
  grid-template-areas:
    'incomeTitle electricityTitle'
    'incomeValue electricityValue';
  grid-template-columns: auto 1fr;
  grid-area: finance;
  row-gap: 10px;
  column-gap: 25px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    row-gap: 4px;
    column-gap: 24px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    row-gap: 4px;
    column-gap: 24px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-areas:
      'incomeTitle incomeTitle'
      'incomeValue incomeValue'
      'electricityTitle electricityTitle'
      'electricityValue electricityValue';
    grid-template-columns: 1fr auto;
    row-gap: 4px;
  }
`;

export const IncomeTitle = styled(MinerDescriptionTitle)`
  grid-area: incomeTitle;
`;

export const IncomeValueWrapper = styled(minerDescriptionValue)`
  display: flex;
  flex-direction: column;
  gap: 2px;
  grid-area: incomeValue;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    gap: 4px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    gap: 4px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
  }
`;

export const IncomeBtcValue = styled(SText)`
  font-size: 18px;
  line-height: 24px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const IncomeUsdValue = styled(SText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #a1a1a1;
`;

//Electricity Data
export const ElectricityTitle = styled(MinerDescriptionTitle)`
  grid-area: electricityTitle;
`;

/*MinersFooter*/
export const MinerFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    justify-content: space-between;
    gap: 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-content: space-between;
    gap: 0;
  }
`;

export const MinerPrice = styled(SText)`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
`;
