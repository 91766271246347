import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

export const SHistoryLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: auto;
  height: auto;
  padding: 6px 12px;
  border: 1px solid #0e46c0;
  border-radius: 16px;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  color: #0e46c0;
  transition: all ${TransitionTime.Hover};

  & svg path {
    transition: all ${TransitionTime.Hover};
    fill: #0e46c0;
  }

  &:hover,
  &:focus {
    background-color: #0e46c0;
    color: #fff;

    & svg path {
      fill: #fff;
    }
  }

  &:active {
    border-color: #03349e;
    background-color: #03349e;

    & svg path {
      fill: #fff;
    }
  }

  &:disabled {
    border-color: #a1a1a1;
    background-color: transparent;
    color: #a1a1a1;

    & svg path {
      fill: #a1a1a1;
    }
  }
`;
