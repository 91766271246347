import { ReactComponent as BasicVerifiedIcon } from 'assets/icons/verify-icons/basic-verification-icon.svg';
import { ReactComponent as FullVerifiedIcon } from 'assets/icons/verify-icons/full-verification-icon.svg';
import { ReactComponent as NotVerifiedIcon } from 'assets/icons/verify-icons/profile-status-icon.svg';

export const STATUS_LABEL = 'PROFILE STATUS';
export const LABEL_FOR_NOT_VERIFIED = 'Verify your profile to withdraw rewards';
export const LABEL_FOR_BASIC_VERIFIED = 'Get full verification to remove withdrawals limit';
export const CURRENT_WITHDRAWALS_LIMIT = 'current withdrawals limit';
export const WITHDRAWALS_VALUE = '$400 000';
export const VERIFICATION_LABEL_FOR_WITHDRAWAL =
  'To withdraw more than $400 000, you need full profile verification';

export const verificationStatuses = {
  notVerified: {
    statusText: 'Not verified',
    icon: NotVerifiedIcon,
  },
  basicVerification: {
    statusText: 'Basic verification',
    icon: BasicVerifiedIcon,
  },
  fullVerification: {
    statusText: 'Full verification',
    icon: FullVerifiedIcon,
  },
};
