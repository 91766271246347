import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 728px;
  padding: 32px;
  border-radius: 24px;
  background-color: #fff;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    width: 644px;
    border-radius: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
    padding: 32px 30px 32px 32px;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
  }
`;

export const AddressBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    margin-bottom: 29px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin-bottom: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

export const From = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 332px;
  padding-bottom: 20px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    width: 290px;
    padding-bottom: 0;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    width: 335px;
    padding-bottom: 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    padding-bottom: 12px;
  }
`;

export const Name = styled(SText)`
  margin-bottom: 23px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const MailingSenderBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 76px;
  margin-bottom: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    gap: 8px;
    min-height: 68px;
    margin-bottom: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 62px;
    margin-bottom: 16px;
  }
`;

export const MailingSenderAddress = styled.address`
  width: 302px;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #272727;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    line-height: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Email = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    line-height: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    line-height: 16px;
  }
`;

export const To = styled(From)`
  flex-grow: 1;
  padding-left: 32px;
  border-left: 1px solid #eaeaea;
  word-wrap: break-word;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 16px 0 0;
    border-left: none;
    border-top: 1px solid #eaeaea;
  }
`;

export const MailingRecipientAddress = styled(MailingSenderAddress)`
  width: 100%;
`;

export const MailingRecipientBlock = styled(MailingSenderBlock)`
  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: auto;
    margin-bottom: 0;
  }
`;
