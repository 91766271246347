import React, { useRef } from 'react';

import { useTextAreaHeightCalculation } from './hooks/useTextAreaHeightCalculation';
import { ITextAreaProps } from './types/text-area-props.interface';

import * as S from './TextArea.styled';

export const TextArea: React.FC<ITextAreaProps> = ({
  textValue,
  handleTextAreaChange,
  hasErrors = false,
  isVerifyPassed,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const height = useTextAreaHeightCalculation(textAreaRef.current, textValue);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) =>
    handleTextAreaChange(evt.target.value);

  return (
    <S.TextArea
      ref={textAreaRef}
      onChange={handleChange}
      rows={1}
      value={textValue}
      $heightInPixels={height}
      $hasErrors={hasErrors}
      disabled={isVerifyPassed}
    />
  );
};
