import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const PaymentDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentData = styled.dl`
  display: grid;
  align-items: center;
  gap: 12px;
  grid-template-columns: 1fr auto;
  margin-bottom: 16px;
  padding-bottom: 23px;
  border-bottom: 1px solid #eaeaea;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    padding-bottom: 15px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-template-areas:
      'sum discount'
      'sumValue discountValue';
    grid-template-columns: auto 1fr;
    padding-bottom: 16px;
    column-gap: 64px;
    row-gap: 4px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-bottom: 16px;
  }
`;

export const Title = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;
`;

export const SumTitle = styled(Title)`
  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-area: sum;
  }
`;

export const DiscountTitle = styled(Title)`
  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-area: discount;
  }
`;

const DescriptionValue = styled.dd`
  justify-self: end;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const UsdValue = styled(DescriptionValue)`
  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    justify-self: start;
    grid-area: sumValue;
  }
`;

export const DiscountValue = styled(DescriptionValue)`
  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    justify-self: start;
    grid-area: discountValue;
  }
`;

export const Option = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const PaymentTitleWrapper = styled.div`
  margin-bottom: 10px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: start;
    width: 100%;
  }
`;

export const BtcTransferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 16px;
  padding: 12px 12px 12px 16px;
  border-radius: 24px;
  background: #e5eaf1;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: start;
    width: 262px;
    margin-bottom: 0;
  }
`;

export const BtcTitle = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;

export const BtcValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #272727;
`;

export const TotalBtcWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin-right: auto;
  }
`;

export const TotalValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  white-space: nowrap;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
    line-height: 24px;
  }
`;
