import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IDashboardState } from './dashboard.reducer';

export const getDashBoardState = (state: TRootState): IDashboardState => state.dashboardReducer;

export const selectDashboardWallet = createSelector(
  [getDashBoardState],
  (dashboardState) => dashboardState.dashboardWallet,
);

export const selectDashboardWalletStatus = createSelector(
  [getDashBoardState],
  (dashboardState) => dashboardState.status,
);

export const selectDashboardWalletError = createSelector(
  [getDashBoardState],
  (dashboardState) => dashboardState.error,
);

export const selectDashboardElectricity = createSelector(
  [getDashBoardState],
  (dashboardElectricityState) => dashboardElectricityState.dashboardElectricity,
);

export const selectDashboardElectricityStatus = createSelector(
  [getDashBoardState],
  (dashboardElectricityState) => dashboardElectricityState.status,
);

export const selectDashboardElectricityError = createSelector(
  [getDashBoardState],
  (dashboardElectricityState) => dashboardElectricityState.error,
);

export const selectDashboardHashRate = createSelector(
  [getDashBoardState],
  (dashboardHashRateState) => dashboardHashRateState.dashboardHashRate,
);

export const selectDashboardHashRateStatus = createSelector(
  [getDashBoardState],
  (dashboardHashRateState) => dashboardHashRateState.status,
);

export const selectDashboardHashRateError = createSelector(
  [getDashBoardState],
  (dashboardHashRateState) => dashboardHashRateState.error,
);

export const selectDashboardTotalMining = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.dashboardMining,
);

export const selectDashboardTotalMiningStatus = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.status,
);

export const selectDashboardTotalMiningError = createSelector(
  [getDashBoardState],
  (dashboardTotalMiningState) => dashboardTotalMiningState.error,
);
