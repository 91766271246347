import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const SectionWrapper = styled.section`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 32px 24px;
  background-color: #f4f4f7;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    padding: 32px 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 24px 16px;
  }
`;
