import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as ArrowRight } from 'assets/icons/button-icons/caret-right.svg';
import { DecimalPlaces } from 'constants/decimals/decimal-places';
import { FormApi } from 'final-form';
import { DepositBtcWarning } from 'pages/PageDashboard/components/ui/DepositBtcWarning';
import { IDepositBtcFormValues } from 'types/ui/DepositBtcForm/deposit-btc-form-values.interface';

import { Input } from 'components/ui/Input';
import { formatInputValue } from 'utils/formatters/format-input-value.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isGreaterOrEqual } from 'utils/validators/is-greater-or-equal';
import { isLessOrEqual } from 'utils/validators/is-less-or-equal';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import { MAXIMUM_BTC_DEPOSIT_AMOUNT } from './constants/maximum-deposit-amount.const';
import { MINIMUM_BTC_DEPOSIT_AMOUNT } from './constants/minimum-deposit-amount.const';
import { INPUT_LABEL_TEXT, MINIMUM_DEPOSIT_AMOUNT_TEXT } from './constants/ui-text.const';
import { IDepositBtcFormProps } from './types/deposit-btc-form-props.interface';

import * as S from './DepositBtcForm.styled';

export const DepositBtcForm: React.FC<IDepositBtcFormProps> = ({
  btcUsdRate,
  handleFormSubmit,
  isDepositDataPending,
}) => {
  const [usdAmount, setUsdAmount] = useState('≈ $0');

  const handleBtcAmountChange = (
    value: string,
    form: FormApi<IDepositBtcFormValues, Partial<IDepositBtcFormValues>>,
  ) => {
    const formattedAmount = formatInputValue(value, DecimalPlaces.Btc);
    setUsdAmount(`≈ $${formatUsdOutput(Number(formattedAmount) * btcUsdRate)}`);
    form.change('amount', formattedAmount);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
        <S.Form onSubmit={handleSubmit}>
          <S.FormInputWrapper>
            <Field
              name="amount"
              validate={composeValidators([
                isRequired,
                isNumber,
                isGreaterOrEqual(MINIMUM_BTC_DEPOSIT_AMOUNT),
                isLessOrEqual(MAXIMUM_BTC_DEPOSIT_AMOUNT),
              ])}
            >
              {({ input, meta }) => (
                <S.InputLabel $isFailed={submitFailed}>
                  <S.LabelText>{INPUT_LABEL_TEXT}</S.LabelText>
                  <S.DepositFormInput
                    {...input}
                    type="text"
                    cryptoCurrency="btc"
                    rightText={usdAmount}
                    hasErrors={meta.error && submitFailed}
                    onChange={(evt) => handleBtcAmountChange(evt.currentTarget.value, form)}
                    autoComplete="off"
                    autoFocus={true}
                  />
                  <S.InputDescriptionText>{MINIMUM_DEPOSIT_AMOUNT_TEXT}</S.InputDescriptionText>
                  {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                </S.InputLabel>
              )}
            </Field>
            <DepositBtcWarning />
          </S.FormInputWrapper>

          <S.ConfirmButton
            type="submit"
            text="Continue"
            isReversed={true}
            icon={<ArrowRight />}
            disabled={(submitFailed && hasValidationErrors) || isDepositDataPending}
          />
        </S.Form>
      )}
    </Form>
  );
};
