import dayjs from 'dayjs';

// a function, which takes a filter and date format params
// returns startDate and endDate
export const getDatesRange = (
  tabValue: string,
  dateFormat: string,
): { newStartDate: string; newEndDate: string } => {
  let newStartDate: string;
  let newEndDate: string;

  const today = dayjs();

  switch (tabValue) {
    case 'today':
      newStartDate = today.format(dateFormat);
      newEndDate = today.format(dateFormat);
      break;
    case 'week':
      newStartDate = today.subtract(1, 'week').format(dateFormat);
      newEndDate = today.format(dateFormat);
      break;
    case 'month':
      newStartDate = today.subtract(1, 'month').format(dateFormat);
      newEndDate = today.format(dateFormat);
      break;
    case 'year':
      newStartDate = today.subtract(1, 'year').format(dateFormat);
      newEndDate = today.format(dateFormat);
      break;
    default:
      return { newStartDate: '', newEndDate: '' };
  }

  return { newStartDate, newEndDate };
};
