import CheckIcon from 'assets/icons/checkbox-icon/check.svg';
import styled from 'styled-components';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { SText } from 'components/styled/SText';

export const Label = styled.label<{ $hasErrors: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  padding: 0 4px 0 32px;
  border-radius: 6px;
  transition: all ${TransitionTime.Hover};
  outline: 1px solid transparent;
  outline-offset: 2px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    transition: all ${TransitionTime.Hover};
    box-sizing: inherit;
  }

  &::before {
    border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#0e46c0')};
    border-radius: 8px;
    background-color: #fff;
  }

  &:has(input:focus) {
    outline-color: #c9c9c9;
  }

  &:has(input:disabled) span {
    color: #a1a1a1;
  }

  &:has(input:disabled)::before {
    border-color: #c9c9c9;
  }

  &:has(input:focus)::before,
  &:has(input:not(:disabled)):hover::before {
    background-color: #e5eaf1;
    box-shadow: 0 0 8px 0 rgb(106 150 246 / 50%);
  }

  &:has(input:checked)::after {
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: center;
  }

  &:has(input:disabled:checked)::before {
    background-color: #c9c9c9;
  }

  &:has(input:checked:not(:disabled))::before,
  &:has(input:checked:not(:disabled)):hover::before,
  &:has(input:checked:not(:disabled)):focus::before {
    background-color: ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#0e46c0')};
  }

  &:has(input:not(:disabled)):has(a:not(:active)):active {

    &::before {
      background-color: ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#0e46c0')};
      box-shadow: 0 0 8px 0 rgb(106 150 246 / 50%);
    }

    &::after {
      background-image: none;
    }
  }
`;

export const Checkbox = styled.input<{ $hasError: boolean }>`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
`;

export const LabelText = styled(SText)<{ $hasErrors: boolean }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#272727')};
  transition: all ${TransitionTime.Hover};
  outline: 1px solid transparent;
  user-select: none;
`;
