import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';

export const Badge = styled.div<{ $isEnabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: ${({ $isEnabled }) => ($isEnabled ? '#e0fbed' : '#f4f4f7')};

  svg {
    flex-shrink: 0;
  }
`;
export const BadgeText = styled(SText)<{ $isEnabled: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${({ $isEnabled }) => ($isEnabled ? '#00bc81' : '#646464')};

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 12px;
    line-height: 16px;
  }
`;
