import React from 'react';
import { TDiscountType } from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';

import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './PaymentDetails.styled';

interface IPaymentDetailsProps {
  sumUsd: number;
  finalSumBtc: number;
  discount: number;
  discountType: TDiscountType;
  btcEarned: number;
}

export const PaymentDetails: React.FC<IPaymentDetailsProps> = ({
  sumUsd,
  finalSumBtc,
  discount,
  discountType,
  btcEarned,
}) => {
  const isDiscountInPercents = discountType === 'percent';
  const totalDiscount = isDiscountInPercents ? (sumUsd * discount) / 100 : discount;

  return (
    <S.PaymentDetailsWrapper>
      <S.PaymentData as="dl">
        <S.SumTitle as="dt">sum</S.SumTitle>
        <S.UsdValue>{`$${formatUsdOutput(sumUsd)}`}</S.UsdValue>
        <S.DiscountTitle as="dt">discount</S.DiscountTitle>
        <S.DiscountValue>{`$${formatUsdOutput(totalDiscount)}${isDiscountInPercents ? ` (${discount}%)` : ''}`}</S.DiscountValue>
      </S.PaymentData>
      <S.Option as="dl">
        <S.TotalBtcWrapper>
          <S.Title>total</S.Title>
          <S.TotalValue>{`${formatBtcOutput(finalSumBtc)} BTC`}</S.TotalValue>
        </S.TotalBtcWrapper>
      </S.Option>
    </S.PaymentDetailsWrapper>
  );
};
