import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const SListTitle = styled(SText)`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #646464;
  font-variant-numeric: lining-nums tabular-nums;
`;
