import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchReplenishThunkAction } from './replenish.thunk-actions';

export interface IReplenishState {
  replenishStatus: string | null;
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IReplenishState = {
  replenishStatus: null,
  status: 'initial',
  error: null,
};

const replenishSlice = createSlice({
  name: 'replenish',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReplenishThunkAction.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchReplenishThunkAction.fulfilled,
      (state, action: PayloadAction<string | null>) => {
        state.status = 'fulfilled';
        state.replenishStatus = action.payload;
      },
    );
    builder.addCase(fetchReplenishThunkAction.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const replenishReducer = replenishSlice.reducer;
