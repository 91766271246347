import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

export const SuccessSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  gap: 12px;
  width: 100%;
  max-width: 338px;
  padding: 24px;
  border-radius: 32px;
  background-color: #fff;

  & svg {
    flex-shrink: 0;
    width: 64px;
    height: 80px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    padding: 24px 16px 16px;
    border-radius: 0 0 24px 24px;
  }
`;

export const Title = styled.h1`
  padding-bottom: 12px;
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
`;

export const DescriptionParagraph = styled.p`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 4px;
`;

export const ContinueLink = styled(Link)`
  width: 100%;
  padding: 12px 16px;
  border-radius: 24px;
  background-color: #0e46c0;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  transition: all ${TransitionTime.Hover};
  outline: none;

  &:hover,
  &:focus,
  &:active {
    background-color: #03349e;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 7px 12px;
  }
`;
