import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { SText } from 'components/styled/SText';

export const UserInfoWrapper = styled.div`
  padding: 0 24px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 0 16px;
  }
`;

export const UserInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.TabletTop}) {
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & svg {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & svg {
      width: 48px;
      height: 48px;
    }
  }
`;

export const UserName = styled(SText)`
  width: min-content;
  overflow: auto;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  word-wrap: break-word;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
  }
`;
