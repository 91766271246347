import React from 'react';

import {
  CASHBACK_DESCRIPTION,
  CASHBACK_TERM,
  COMPANY_DESCRIPTION,
  COMPANY_TERM,
  CONDITIONS_1_TERM,
  CONDITIONS_2_TERM,
  CONDITIONS_3_TERM,
  CONDITIONS_TITLE,
  CONFIDENTIALITY_TERM,
  CONFIDENTIALITY_TITLE,
  CREDITING_1_TERM,
  CREDITING_2_TERM,
  CREDITING_3_TERM,
  CREDITING_4_TERM,
  CREDITING_TITLE,
  GENERAL_1_TERM,
  GENERAL_2_TERM,
  GENERAL_TITLE,
  OTHER_1_TERM,
  OTHER_2_TERM,
  OTHER_3_TERM,
  OTHER_4_TERM,
  OTHER_TITLE,
  PARTICIPANT_DESCRIPTION,
  PARTICIPANT_TERM,
  PARTICIPATION_1_TERM,
  PARTICIPATION_2_TERM,
  PARTICIPATION_3_TERM,
  PARTICIPATION_TITLE,
  PROMOTION_DESCRIPTION,
  PROMOTION_NAME_TERM,
  PROMOTION_NAME_TITLE,
  PROMOTION_PERIOD_DESCRIPTION,
  PROMOTION_PERIOD_TERM,
  PROMOTION_TERM,
  PROMOTION_TERRITORY_TERM,
  PROMOTION_TERRITORY_TITLE,
  RESPONSIBILITY_1_TERM,
  RESPONSIBILITY_2_TERM,
  RESPONSIBILITY_TITLE,
  TERMS_DEFINITIONS_TITLE,
  TERMS_DESCRIPTION,
  TERMS_TITLE,
} from './constants/ui-text.const';

import * as S from './ElectricityCashbackTerms.styled';

export const ElectricityCashbackTerms: React.FC = () => {
  return (
    <S.CashbackTermsArticle>
      <S.CashbackTermsTitle>{TERMS_TITLE}</S.CashbackTermsTitle>
      <S.DescriptionParagraph>{TERMS_DESCRIPTION}</S.DescriptionParagraph>

      <S.TermsCategoriesList>
        <S.TermsCategory>
          <S.CategoryTitle>{PROMOTION_NAME_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{PROMOTION_NAME_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{PROMOTION_TERRITORY_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{PROMOTION_TERRITORY_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{TERMS_DEFINITIONS_TITLE}</S.CategoryTitle>
          <S.DefinitionsList>
            <div>
              <S.DefinitionTerm>{PROMOTION_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${PROMOTION_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{CASHBACK_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${CASHBACK_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{COMPANY_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${COMPANY_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{PARTICIPANT_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${PARTICIPANT_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{PROMOTION_PERIOD_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${PROMOTION_PERIOD_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
          </S.DefinitionsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{GENERAL_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{GENERAL_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{GENERAL_2_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{CONDITIONS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{CONDITIONS_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{CONDITIONS_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{CONDITIONS_3_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{PARTICIPATION_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{PARTICIPATION_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PARTICIPATION_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PARTICIPATION_3_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{CREDITING_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{CREDITING_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{CREDITING_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{CREDITING_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{CREDITING_4_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{RESPONSIBILITY_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{RESPONSIBILITY_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{RESPONSIBILITY_2_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{CONFIDENTIALITY_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{CONFIDENTIALITY_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{OTHER_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{OTHER_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OTHER_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OTHER_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OTHER_4_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>
      </S.TermsCategoriesList>
    </S.CashbackTermsArticle>
  );
};
