import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IWithdrawState } from './withdraw.reducer';

const getWithdrawState = (state: TRootState): IWithdrawState => state.withdrawReducer;

export const selectRequestWithdrawStatus = createSelector(
  [getWithdrawState],
  (state) => state.withdrawRequestStatus,
);

export const selectWithdrawStep = createSelector(
  [getWithdrawState], 
  (state) => state.withdrawStep,
);

export const selectWithdrawConfirmationStatus = createSelector(
  [getWithdrawState],
  (state) => state.withdrawConfirmationStatus,
);
