import React from 'react';
import { ReactComponent as EnabledIcon } from 'assets/icons/assets-page-icons/check-circle.svg';
import { ReactComponent as DisabledIcon } from 'assets/icons/assets-page-icons/x-circle.svg';

import { DISABLED, ENABLED } from './constants/ui-text.const';

import * as S from './UptimeStatusBadge.styled';

interface IUptimeStatusBadgeProps {
  isEnabled: boolean;
}
export const UptimeStatusBadge: React.FC<IUptimeStatusBadgeProps> = ({ isEnabled }) => {
  switch (isEnabled) {
    case true:
      return (
        <S.Badge $isEnabled={isEnabled}>
          <EnabledIcon />
          <S.BadgeText $isEnabled={isEnabled}>{ENABLED}</S.BadgeText>
        </S.Badge>
      );
    case false:
      return (
        <S.Badge $isEnabled={isEnabled}>
          <DisabledIcon />
          <S.BadgeText $isEnabled={isEnabled}>{DISABLED}</S.BadgeText>
        </S.Badge>
      );
  }
};
