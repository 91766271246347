import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IVerificationState } from './verification.reducer';

const getVerificationState = (state: TRootState): IVerificationState => state.verificationReducer;

export const selectVerificationFetchStatus = createSelector(
  [getVerificationState],
  (state) => state.verificationFetchStatus,
);

export const selectVerificationData = createSelector(
  [getVerificationState],
  ({ verificationData }) => verificationData,
);
