import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Container = styled.div<{ step: TPurchaseStep }>`
  display: grid;
  align-items: start;
  grid-template-columns: ${({ step }) => (step === 'paid-success' ? '1fr auto' : '1fr')};
  grid-template-rows: min-content min-content;
  padding: 32px 32px 0 24px;
  background-color: #0e46c0;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    padding: 32px 24px 0;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    padding: 32px 16px 0;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
    padding: 24px 16px 0;
  }
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px;
  color: #fff;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const PdfButton = styled(Button)`
  align-self: center;

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-row: 3;
  }
`;

export const InvoiceDate = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 24px;
    font-family: ${FontFamily.Montserrat};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fff;
    font-variant-numeric: lining-nums tabular-nums;
  }
`;
