import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IProfileState } from './profile.reducer';

export const getProfileState = (state: TRootState): IProfileState => state.profileReducer;

export const selectProfile = createSelector(
  [getProfileState],
  (profileState) => profileState.profile,
);

export const selectProfileStatus = createSelector(
  [getProfileState],
  (profileState) => profileState.profileFetchStatus,
);

export const selectWallets = createSelector(
  [getProfileState],
  (profileState) => profileState.wallets,
);

export const selectWalletsStatus = createSelector(
  [getProfileState],
  (profileState) => profileState.walletsFetchStatus,
);

export const selectWalletChangeStatus = createSelector(
  [getProfileState],
  (profileState) => profileState.walletChangeStatus,
);

export const selectNewWalletAddress = createSelector(
  [getProfileState],
  (profileState) => profileState.newWalletAddress,
);

export const selectWalletApproveStatus = createSelector(
  [getProfileState],
  (profileState) => profileState.walletApproveFetchStatus,
);

export const selectChangeWalletStep = createSelector(
  [getProfileState],
  (profileState) => profileState.changeWalletAddressStep,
);
