import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const CheckboxAgreementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 67px;
  margin-top: 16px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    min-height: 80px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    min-height: 50px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 80px;
  }
`;

export const PayButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: flex-start;
    padding: 12px 32px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 12px 32px;
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;
