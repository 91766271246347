import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IInvoicePdfLinksState } from './Invoice-pdf-links.reducer';

export const getInvoicePdfLinksState = (state: TRootState): IInvoicePdfLinksState =>
  state.invoicePdfLinksReducer;

export const selectInvoicePdfLinks = createSelector(
  [getInvoicePdfLinksState],
  (invoicePdfLinksState: IInvoicePdfLinksState) => invoicePdfLinksState.invoicePdfLinksData,
);

export const selectInvoicePdfLinkStatus = createSelector(
  [getInvoicePdfLinksState],
  (invoicePdfLinksState: IInvoicePdfLinksState) => invoicePdfLinksState.status,
);

export const selectInvoicePdfLinkError = createSelector(
  [getInvoicePdfLinksState],
  (invoicePdfLinksState: IInvoicePdfLinksState) => invoicePdfLinksState.error,
);
