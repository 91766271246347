import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const SInfoArticle = styled.article`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    gap: 20px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    gap: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 12px;
  }
`;
