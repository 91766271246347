import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const copyText = (value: string): void => {
  navigator?.clipboard
    ?.writeText(value)
    .then(() => {
      notifySuccess('Copied to clipboard');
    })
    .catch(() => {
      notifyError('This operation is not supported in your browser');
    });
};
