import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const MinersBlock = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-grow: 0;
    gap: 16px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;

  & svg {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
  }
`;

export const MinersBlockTitle = styled.h2`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  color: #272727;
`;

export const MinerList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    gap: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
  }
`;


