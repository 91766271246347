import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavigationItem } from 'components/logic/InnerLayout/components/ui/NavigationItem';
import { AppRoute } from 'utils/route/app-route';

import { navItems } from './constants/nav-items';

import * as S from './Navigation.styled';

interface INavigationProps {
  handlePurchaseLinkClick: () => void;
}

export const Navigation: React.FC<INavigationProps> = ({ handlePurchaseLinkClick }) => {
  const { pathname } = useLocation();

  return (
    <S.NavWrapper as="nav">
      <S.NavList>
        {navItems.map(({ linkTo, text }) => (
          <NavigationItem
            key={linkTo}
            linkTo={linkTo}
            text={text}
            isCurrent={pathname === linkTo}
            onClick={linkTo === AppRoute.Purchase() ? handlePurchaseLinkClick : undefined}
          />
        ))}
      </S.NavList>
    </S.NavWrapper>
  );
};
