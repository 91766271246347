import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { setCartPromoStatusAction } from 'store/invoice-reducer/invoice.reducer';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';
import { useAppDispatch } from 'store/store';

import { AppRoute } from 'utils/route/app-route';

export const usePurchaseBrowserBackClick = (purchaseStep: TPurchaseStep): void => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      dispatch(setPurchaseStepAction('buying'));
      dispatch(setCartPromoStatusAction(EPromoCodeStatus.Button));
    };
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [dispatch]);

  useEffect(() => {
    if (purchaseStep === 'checkout') {
      navigate(AppRoute.Purchase(), { replace: false });
    }
  }, [navigate, purchaseStep]);
};
