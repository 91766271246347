import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const SArticleTitle = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;

    & svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }
  }
`;
