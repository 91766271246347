import React from 'react';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './MobileTable.styled';

interface IDesktopTableProps {
  cartItems: IPurchaseCartItemMapped[];
}

export const MobileTable: React.FC<IDesktopTableProps> = ({ cartItems }) => {
  return (
    <S.CartList>
      {cartItems.map((item) => (
        <S.Card key={item.productId}>
          <S.MainerItem as="dl">
            <S.Title as="dt">{item.name}</S.Title>
            <S.Total as="dd">{`$${formatUsdOutput(item.quantity * item.price)}`}</S.Total>
            <S.PurchaseType as="dd">Buy a miner</S.PurchaseType>
            <S.MultiplyItem as="dd">{`$${formatUsdOutput(item.price)} × ${item.quantity}`}</S.MultiplyItem>
          </S.MainerItem>
        </S.Card>
      ))}
    </S.CartList>
  );
};
