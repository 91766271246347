import React, { useEffect } from 'react';
import { useUptime } from 'hooks/useUptime';
import { LimitedUptimeSetButton } from 'pages/PageAssets/components/ui/LimitedUptimeSetButton';
import { useAppDispatch } from 'store/store';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';

import {
  LIMITED_UPTIME_EXPLAINER_PARAGRAPH1,
  LIMITED_UPTIME_EXPLAINER_PARAGRAPH2,
  LIMITED_UPTIME_EXPLAINER_PARAGRAPH3,
} from './constants/ui-text.const';

import * as S from './LimitedUptimeExplainer.styled';

export const LimitedUptimeExplainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    cooldown,
    uptime,
    uptimeStatus,
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,
  } = useUptime();
  useEffect(() => {
    void dispatch(requestUptimeThunkAction());
  }, [dispatch]);
  return (
    <S.ExplainerArticle>
      <S.ExplainerParagraphsWrapper>
        <S.ExplainerParagraph>{LIMITED_UPTIME_EXPLAINER_PARAGRAPH1}</S.ExplainerParagraph>
        <S.ExplainerParagraph>{LIMITED_UPTIME_EXPLAINER_PARAGRAPH2}</S.ExplainerParagraph>
        <S.ExplainerParagraph>{LIMITED_UPTIME_EXPLAINER_PARAGRAPH3}</S.ExplainerParagraph>
      </S.ExplainerParagraphsWrapper>
      <LimitedUptimeSetButton
        onDisable={handleLimitedUptimeDisabled}
        onEnable={handleLimitedUptimeEnabled}
        uptime={uptime}
        cooldown={cooldown}
        uptimeStatus={uptimeStatus}
        isInModal
      />
    </S.ExplainerArticle>
  );
};
