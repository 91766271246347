import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestPairRates } from 'store/api/cuverse-api/rates/rates.service';
import { TPairRatesData } from 'store/api/cuverse-api/rates/types/rates-response.interface';

export const fetchPairRatesThunkAction = createAsyncThunk(
  'rates/fetch',
  async (): Promise<TPairRatesData> => {
    return await requestPairRates().then((res) => res?.data);
  },
);
