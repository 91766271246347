import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { TransitionTime } from 'styles/style-variables/transition-time';

export const ProfileLinkWrapper = styled(Link)`
  padding: 24px 16px;
  text-decoration: none;

  & > div > div {
    transition: all ${TransitionTime.Hover};

    & > svg path {
      transition: all ${TransitionTime.Hover};
    }
  }

  &:focus-visible {
    outline: none;
  }
          
  &:hover > div > div,
  &:focus > div > div {
    background-color: #d8eeff;

    & > svg path {
      fill: #3069e5;
    }
  }

  @media (min-width: ${BreakPoint.TabletLow}) {
    display: none;
  }
`;
