import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 24px;
  background-color: #0e46c0;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 24px 16px;
  }
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px;
  color: #fff;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 32px;
    line-height: 40px;
  }
`;
