import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const SetNewPasswordSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 432px;
  padding: 24px;
  border-radius: 32px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    padding: 24px 16px 16px;
    border-radius: 0 0 24px 24px;
  }
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  color: #000;
`;

export const Form = styled.form<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 90px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: 76px;
  }
`;

export const LabelText = styled(SText)`
  display: inline-flex;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  &::after {
    content: '*';
    font-weight: 900;
    color: #ec494f;
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const SubmitButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 7px 12px;
  }
`;

export const RequiredFieldsNote = styled(SText)`
  display: inline-flex;
  gap: 4px;
  margin-left: auto;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: right;
  text-transform: uppercase;
  color: #646464;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-right: auto;
    margin-bottom: 32px;
  }

  &::before {
    content: '*';
    color: #ec494f;
  }
`;
