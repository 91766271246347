import React from 'react';
import { CartEmpty } from 'pages/PagePurchase/components/ui/CartEmpty';
import { CartListSection } from 'pages/PagePurchase/components/ui/CartListSection';
import { MinersBuyingSection } from 'pages/PagePurchase/components/ui/MinersBuyingSection';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import * as S from './MinersScreen.styled';

interface IMinersScreenProps {
  minersData: IMinerDataMapped[];
  btcToUsdRate: number;
  handleCheckoutClick: () => void;
  cartList: IPurchaseCartItemMapped[];
  isCartExist: boolean;
  totalCartSum: number;
  cartId: number;
}

export const MinersScreen: React.FC<IMinersScreenProps> = ({
  minersData,
  btcToUsdRate,
  handleCheckoutClick,
  cartList,
  isCartExist,
  totalCartSum,
  cartId,
}) => {
  const isEmptyCart = cartList.length === 0;

  return (
    <S.ScreenWrapper as="div">
      <MinersBuyingSection
        minersData={minersData}
        btcToUsdRate={btcToUsdRate}
        isCartExist={isCartExist}
        cartId={cartId}
        cartList={cartList}
      />
      <S.CartBlock as="section" $isEmptyCart={isEmptyCart}>
        {isEmptyCart ? (
          <CartEmpty />
        ) : (
          <CartListSection
            handleCheckoutClick={handleCheckoutClick}
            cartList={cartList}
            totalCartSum={totalCartSum}
          />
        )}
      </S.CartBlock>
    </S.ScreenWrapper>
  );
};
