import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import {
  ICartCouponResponseDataMapped,
  TDiscountType,
} from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';
import { IInvoiceResponseDataMapped } from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { applyCartPromoThunkAction, fetchInvoiceThunkAction } from './invoice.thunk-actions';

export interface IInvoiceState {
  invoiceData: IInvoiceResponseDataMapped | null;
  invoiceFetchStatus: TFetchStatus;
  invoiceError: string | null;
  cartPromoDiscountType: TDiscountType;
  cartPromoStatus: EPromoCodeStatus;
  promoError: string | null;
}

export const initialState: IInvoiceState = {
  invoiceData: null,
  invoiceFetchStatus: 'initial',
  invoiceError: null,
  cartPromoDiscountType: 'fixed',
  cartPromoStatus: EPromoCodeStatus.Button,
  promoError: null,
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setCartPromoStatusAction: (state, { payload }: PayloadAction<EPromoCodeStatus>) => {
      state.cartPromoStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvoiceThunkAction.pending, (state) => {
      state.cartPromoDiscountType = 'fixed';
      state.invoiceFetchStatus = 'pending';
      state.invoiceError = null;
    });
    builder.addCase(
      fetchInvoiceThunkAction.fulfilled,
      (state, { payload }: PayloadAction<IInvoiceResponseDataMapped>) => {
        state.invoiceFetchStatus = 'fulfilled';
        state.invoiceData = payload;
      },
    );
    builder.addCase(fetchInvoiceThunkAction.rejected, (state, error) => {
      state.invoiceFetchStatus = 'rejected';
      state.invoiceError = JSON.stringify(error);
    });
    builder
      .addCase(applyCartPromoThunkAction.pending, (state) => {
        state.cartPromoStatus = EPromoCodeStatus.Checking;
      })
      .addCase(
        applyCartPromoThunkAction.fulfilled,
        (state, { payload }: PayloadAction<ICartCouponResponseDataMapped>) => {
          state.cartPromoStatus = EPromoCodeStatus.Accepted;
          if (state.invoiceData) {
            state.invoiceData.priceUsd = payload.priceUsd;
            state.invoiceData.priceBtc = payload.priceBtc;
            state.invoiceData.priceLtc = payload.priceLtc;
            state.invoiceData.priceUst = payload.priceUst;
            state.invoiceData.priceEth = payload.priceEth;
            state.invoiceData.discount = payload.discount;
            state.cartPromoDiscountType = payload.type;
          }
        },
      )
      .addCase(applyCartPromoThunkAction.rejected, (state, error) => {
        state.cartPromoStatus = EPromoCodeStatus.Rejected;
        state.promoError = JSON.stringify(error);
      });
  },
});

export const { setCartPromoStatusAction } = invoiceSlice.actions;
export const invoiceReducer = invoiceSlice.reducer;
