import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICardPaymentMessageMapped,
  IOrdersIds,
  IPaymentResponseData,
  TAlphaPoMessage,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchPaymentThunkAction } from './payment.thunk-actions';

export interface IPaymentState {
  paymentStatusResult: string | null;
  ordersIds: IOrdersIds[] | null;
  cardPaymentLink: string | null;
  alphaPoPaymentLink: string | null;
  fetchStatus: TFetchStatus;
  error: string | null;
}

const initialState: IPaymentState = {
  paymentStatusResult: null,
  ordersIds: null,
  cardPaymentLink: null,
  alphaPoPaymentLink: null,
  fetchStatus: 'initial',
  error: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentThunkAction.pending, (state) => {
      state.fetchStatus = 'pending';
      state.error = null;
    });
    builder.addCase(
      fetchPaymentThunkAction.fulfilled,
      (
        state,
        action: PayloadAction<IPaymentResponseData | TAlphaPoMessage | ICardPaymentMessageMapped>,
      ) => {
        state.fetchStatus = 'fulfilled';
        if (action.payload) {
          if (typeof action.payload === 'object' && 'status' in action.payload) {
            const { status, data } = action.payload;
            state.paymentStatusResult = status;
            state.ordersIds = data || null;
          } else if (typeof action.payload === 'object' && 'paymentLink' in action.payload) {
            state.cardPaymentLink = action.payload.paymentLink;
          } else if (typeof action.payload === 'string') {
            state.alphaPoPaymentLink = state.alphaPoPaymentLink ?? action.payload;
          }
        }
      },
    );
    builder.addCase(fetchPaymentThunkAction.rejected, (state, action) => {
      state.fetchStatus = 'rejected';
      state.error = JSON.stringify(action.error);
    });
  },
});

export const paymentReducer = paymentSlice.reducer;
