import { useSelector } from 'react-redux';
import { selectWithdrawStep } from 'store/withdraw-reducer/withdraw.selectors';

interface IWithdrawPopupATitle {
  withdrawPopupTitle: string;
}

export const useWithdrawPopupTitle = (): IWithdrawPopupATitle => {
  const withdrawStep = useSelector(selectWithdrawStep);

  let withdrawPopupTitle = '';

  switch (withdrawStep) {
    case 'initial':
      withdrawPopupTitle = 'Withdrawal';
      break;
    case 'withdrawal-confirm':
      withdrawPopupTitle = 'Confirm withdrawal';
  }

  return { withdrawPopupTitle };
};
