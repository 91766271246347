import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const DescriptionContainer = styled.div<{ isWithdrawalView?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 12px;
  background-color: #f4f4f7;
  font-weight: 500;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    background-color: ${({ isWithdrawalView }) => (isWithdrawalView ? '#f4f4f7' : '#fff')};
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #272727;
`;

export const Info = styled.div<{ isWithdrawalView?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #646464;
`;

export const VerificationInfoWrapper = styled.section<{ isWithdrawalView?: boolean }>`
  display: flex;
  flex-direction: ${({ isWithdrawalView }) => (isWithdrawalView ? 'column' : 'row')};
  gap: 12px;
  font-family: ${FontFamily.Montserrat};

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
  }
`;

export const VerificationButton = styled(Button)<{
  isFullWidth?: boolean;
  isLimitWithdrawalPanel?: boolean;
}>`
  justify-content: ${({ isLimitWithdrawalPanel }) =>
    isLimitWithdrawalPanel ? 'flex-start' : 'center'};
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '160px')};
  margin-top: auto;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
  }
`;

export const Descirption = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
`;

export const DescriptionText = styled(SText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;
