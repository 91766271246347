import styled from 'styled-components';

export const Wrapper = styled.div`
  & h1 {
    display: none;
  }

  & section > div {
    padding-top: 0;
  }

  & section > span {
    display: none;
  }

  & section span::after {
    content: '';
  }
`;
