import React from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';

interface ISumsubProps {
  applicantToken: string;
  accessTokenExpirationHandler: () => void;
}

export const SumsubWidget: React.FC<ISumsubProps> = ({
  applicantToken,
  accessTokenExpirationHandler,
}) => {
  return (
    <SumsubWebSdk
      accessToken={applicantToken}
      expirationHandler={accessTokenExpirationHandler}
      config={{
        lang: 'en',
      }}
    />
  );
};
