import { createSelector } from '@reduxjs/toolkit';
import { TPasswordResetStep } from 'pages/PagePasswordReset/types/password-reset-step.type';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';
import { TUserAuthStatus } from 'types/user/user-auth-status.type';

import { IUserState } from './auth.reducer';

export const getUserState = (state: TRootState): IUserState => state.authReducer;

export const selectUserAuthStatus = createSelector(
  [getUserState],
  (state): TUserAuthStatus => state.userAuthStatus,
);

export const selectLoginFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.loginFetchStatus,
);

export const selectLogoutFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.logoutFetchStatus,
);

export const selectRegistrationFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.registrationFetchStatus,
);

export const selectEmailVerifyFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.emailVerifyFetchStatus,
);

export const selectChangePasswordStep = createSelector(
  [getUserState],
  (state): TPasswordResetStep => state.passwordChangeStep,
);

export const selectPasswordChangeCodeFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.passwordChangeCodeFetchStatus,
);

export const selectOtpVerificationStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.otpVerificationFetchStatus,
);

export const selectPasswordResetStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.passwordResetStatus,
);

export const selectEnable2FAFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.enable2FAFetchStatus,
);

export const selectQrCode2FAUrl = createSelector(
  [getUserState],
  (state): string | null => state.qrCode2FAUrl,
);

export const selectConfirm2FAFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.confirm2FAFetchStatus,
);

export const selectDisable2FAFetchStatus = createSelector(
  [getUserState],
  (state): TFetchStatus => state.disable2FAFetchStatus,
);
