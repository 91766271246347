import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PaymentDescription = styled(SText)`
  font-size: 14px;
  line-height: 18px;
`;

export const PaymentAmount = styled(SText)`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

export const WalletAddressWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 42px;
  padding: 12px 40px 12px 12px;
  border-radius: 24px;
  background-color: #e5eaf1;
`;

export const WalletAddressSpinner = styled(SSpinnerBlue)`
  width: 16px;
  height: 16px;
`;

export const WalletAddress = styled(SText)`
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
`;

export const CopyButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 13px 12px;
`;

export const QrCodeWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const QrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 144px;
  height: 144px;
  padding: 10px;
  border: 1px solid #0e46c0;
  border-radius: 16px;
`;

export const QrCodeImage = styled.img`
  flex-shrink: 0;
  width: 120px;
  height: 120px;
`;

export const AddressExpirationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
`;

export const AddressExpirationWarning = styled(SText)`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const AddressExpirationTimer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimerTitle = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

export const ExpirationTime = styled.time`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

export const FinishButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
  border-radius: 24px;
`;
