import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestIpData } from 'store/api/ip-api/ip.service';
import { IIpResponseDataMapped } from 'store/api/ip-api/types/ip-response-data.interface';

import { mapIpData } from 'utils/mapper/mapper';

export const fetchIpData = createAsyncThunk(
  'ip/fetch',
  async (): Promise<IIpResponseDataMapped> =>
    await requestIpData()
      .then((data) => mapIpData(data))
      .catch((error) => {
        throw error;
      }),
);
