import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  requestDeposit,
  requestDepositPaymentStatus,
} from 'store/api/cuverse-api/deposit/deposit.service';
import { IDepositError } from 'store/api/cuverse-api/deposit/types/deposit-error.interface';
import { IDepositPaymentStatusRequestParams } from 'store/api/cuverse-api/deposit/types/deposit-payment-status-request-params.interface';
import {
  IDepositPaymentStatusResponse,
  TPaymentStatus,
} from 'store/api/cuverse-api/deposit/types/deposit-payment-status-response.interface';
import { IDepositRequestBody } from 'store/api/cuverse-api/deposit/types/deposit-request-body.interface';
import {
  IDepositResponse,
  IDepositResponseData,
} from 'store/api/cuverse-api/deposit/types/deposit-response.interface';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

import { setDepositStepAction } from './deposit.reducer';

export const requestDepositThunkAction = createAsyncThunk(
  'dashboard/deposit',
  async (body: IDepositRequestBody, { dispatch }): Promise<IDepositResponseData> => {
    return await requestDeposit(body)
      .then((response: IDepositResponse) => {
        dispatch(setDepositStepAction('deposit-payment'));
        void dispatch(
          requestPaymentStatusThunkAction({
            coin: body.coin,
            address: response.data.address,
          }),
        );
        return response?.data;
      })
      .catch((error: AxiosError<IDepositError>) => {
        if (error.response?.data.data) {
          notifyError(error.response.data.data);
          throw error;
        }
        if (error.response?.data.message) {
          notifyError(error.response.data.message);
          throw error;
        }
        throw error;
      });
  },
);

export const requestPaymentStatusThunkAction = createAsyncThunk(
  'dashboard/fetchPaymentStatus',
  async (params: IDepositPaymentStatusRequestParams): Promise<TPaymentStatus> => {
    return await requestDepositPaymentStatus(params)
      .then((response: IDepositPaymentStatusResponse) => {
        if (response.data.status === 'PAID') {
          notifySuccess(response.data.message);
        }
        if (response.data.status === 'EXPIRED') {
          notifyError(response.data.message);
        }
        return response?.data.status;
      })
      .catch((error) => {
        throw error;
      });
  },
);
