import React, { FC } from 'react';
import dayjs from 'dayjs';
import { NO_AFFILIATE_HISTORY } from 'pages/PageAffiliateProgram/constants/noAffiliateHistoryNotification.consts';
import { rowTitles } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import { IReferralHistoryResponseDataMapped } from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';

import * as S from 'pages/PageAffiliateProgram/components/ui/HistoryTableMobile/HistoryTableMobile.styled';

interface IHistoryTableMobileProps {
  items: IReferralHistoryResponseDataMapped[];
  isLoading: boolean;
}

export const HistoryTableMobile: FC<IHistoryTableMobileProps> = ({ items, isLoading }) => {
  return (
    <S.TableWrapper isEmpty={items.length === 0}>
      {isLoading ? (
        <SSpinnerBlue />
      ) : items.length > 0 ? (
        <table>
          <tbody>
            {items.map((referralData, index) => (
              <React.Fragment key={referralData.userId}>
                <S.Wrapper>
                  {rowTitles.map(({ title, key, isDate, name }) => (
                    <S.Tr key={key}>
                      <S.RowTitle>{title}</S.RowTitle>
                      <S.RowValue>
                        {isDate
                          ? `${dayjs.utc(referralData.dataTimestamp).format('DD.MM.YYYY')}`
                          : referralData[key]}{' '}
                        {name ?? null}
                      </S.RowValue>
                    </S.Tr>
                  ))}
                </S.Wrapper>
                {index < items.length - 1 && <S.Hr />}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <S.NoAffiliateHistory>{NO_AFFILIATE_HISTORY}</S.NoAffiliateHistory>
      )}
    </S.TableWrapper>
  );
};
