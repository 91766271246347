import React from 'react';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { useTabsRefs } from './hooks/use-tabs-refs';
import { ITabsProps } from './types/tabs-props.interface';

import * as S from './Tabs.styled';

export const Tabs: React.FC<ITabsProps> = ({ tabItems, activeTabValue, handleTabClick }) => {
  const { innerWidth } = useInnerWidth();
  const tabsRefs = useTabsRefs();

  const handleTabClickWithScroll = (value: string, index: number): void => {
    const activeButtonRef = tabsRefs[index];

    if (activeButtonRef && innerWidth <= parseInt(BreakPoint.MobileTop)) {
      activeButtonRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }

    handleTabClick(value);
  };

  return (
    <S.TabsContainer>
      <S.TabButtonsList>
        {tabItems.map(({ text, value, icon }, index) => (
          <S.TabButtonsListItem key={value}>
            <S.TabButton
              ref={tabsRefs[index]}
              type="button"
              variant="alter-primary"
              icon={icon && icon}
              text={text}
              onClick={() => handleTabClickWithScroll(value, index)}
              disabled={activeTabValue === value}
            />
          </S.TabButtonsListItem>
        ))}
      </S.TabButtonsList>
    </S.TabsContainer>
  );
};
