import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MINUTE_IN_MILLISECONDS } from 'constants/time/minute-in-milliseconds.const';
import dayjs from 'dayjs';
import { requestAssetsData } from 'store/assets-reducer/assets.thunk-actions';
import { useAppDispatch } from 'store/store';
import {
  selectCooldown,
  selectUptime,
  selectUptimeStatus,
} from 'store/uptime-reducer/uptime.selectors';
import {
  requestUptimeThunkAction,
  setUptimeThunkAction,
} from 'store/uptime-reducer/uptime.thunk-action';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { transformTimestampDate } from 'utils/common/transformTimestampDate';

interface IUseUptimePing {
  handleLimitedUptimeDisabled: () => void;
  handleLimitedUptimeEnabled: () => void;
  uptime: boolean;
  cooldown: boolean;
  uptimeStatus: TFetchStatus;
}

/**
 * Hook for handling limited uptime.
 *
 * @returns this hook returns an object with the following properties:
 * @param handleLimitedUptimeDisabled - function to disable limited uptime
 * @param handleLimitedUptimeEnabled - function to enable limited uptime
 * @param uptime - boolean indicating if limited uptime is enabled
 * @param cooldown - boolean indicating if cooldown is active
 * @param uptimeStatus - string indicating the status of the uptime request
 */
export const useUptime = (): IUseUptimePing => {
  const dispatch = useAppDispatch();
  const { uptime } = useSelector(selectUptime);
  const { cooldown } = useSelector(selectCooldown);
  const { uptimeStatus } = useSelector(selectUptimeStatus);

  const handleLimitedUptimeDisabled = () => {
    void dispatch(setUptimeThunkAction({ uptimeAction: 'disable' }));
  };
  const handleLimitedUptimeEnabled = () => {
    void dispatch(setUptimeThunkAction({ uptimeAction: 'enable' }));
  };

  return {
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,
    uptime,
    cooldown,
    uptimeStatus,
  };
};
