import React from 'react';

import { HistorySection } from './components/logic/HistorySection';

import * as S from './PageTransactionsHistory.styled';

export const PageTransactionsHistory: React.FC = () => {
  return (
    <>
      <S.ContentHeader>
        <S.ContentTitle>Transaction history</S.ContentTitle>
      </S.ContentHeader>
      <HistorySection />
    </>
  );
};
