import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Container = styled.div`
  display: flex;
`;

export const AddToCartButton = styled(Button)`
  gap: 8px;
  padding: 11px 15px;
  border-radius: 24px;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 4px;
    padding: 6px 11px;
  }
`;

export const OutOfStockBadge = styled(SText)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 24px;
  background-color: #c9c9c9;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  user-select: none;

  & svg {
    flex-shrink: 0;

    & path {
      fill: #fff;
    }
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    gap: 4px;
    padding: 7px 12px;
  }
`;

export const CounterButton = styled(Button)`
  min-height: 42px;
  padding: 10px;

  &:first-of-type {
    border-radius: 24px 0 0 24px;
  }

  &:last-of-type {
    border-radius: 0 24px 24px 0;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    min-height: 32px;
    padding: 8px;
  }
`;

export const QuantityValue = styled(SText)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  padding: 12px 10px;
  background-color: #fff6d9;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  user-select: none;
  font-variant-numeric: tabular-nums;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;
