import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { MAX_DESKTOP_TEXTAREA_HEIGHT_PIXELS } from './constants/max-desktop-textarea-height-pixels';

export const TextArea = styled.textarea<{ $heightInPixels: number; $hasErrors: boolean }>`
  width: 100%;
  min-height: 42px;
  padding: 11px 15px;
  border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#c9c9c9')};
  border-radius: 24px;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
  transition:
    border ${TransitionTime.Hover},
    background-color ${TransitionTime.Hover},
    color ${TransitionTime.Hover},
    box-shadow ${TransitionTime.Hover};
  outline: none;
  resize: none;

  &::placeholder {
    font-family: ${FontFamily.Montserrat};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #a1a1a1;
    transition: color ${TransitionTime.Hover};
  }

  @media (min-width: ${BreakPoint.TabletLow}) {
    height: ${({ $heightInPixels }) => `${$heightInPixels}px`};
    max-height: ${MAX_DESKTOP_TEXTAREA_HEIGHT_PIXELS}px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    height: 96px;
    padding: 7px 11px;
    border-radius: 16px;
    font-size: 12px;
    line-height: 16px;

    &::placeholder {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &:hover {
    border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#a1a1a1')};
  }

  &:focus {
    border: 1px solid ${({ $hasErrors }) => ($hasErrors ? '#EC494F' : '#3069E5')};
    box-shadow: 0 0 4px 0 rgb(14 70 192 / 40%);

    &::placeholder {
      color: transparent;
    }
  }

  &:disabled {
    border-color: #c9c9c9;
    background-color: #eaeaea;
    color: #c9c9c9;

    &::placeholder {
      color: #c9c9c9;
    }
  }

  &:-webkit-autofill,
  &:autofill,
  &:-webkit-autofill:focus,
  &:autofill:focus {
    background-color: #fff;
    box-shadow: 0 0 0 1000px #fff inset;
    font-family: ${FontFamily.Montserrat};
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #272727;
    background-clip: text;
    -webkit-text-fill-color: #272727;

    &:focus {
      box-shadow:
        0 0 0 1000px #fff inset,
        0 0 4px 0 rgb(14 70 192 / 40%);
    }
  }
`;
