import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 8px;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StatusSpinner = styled(SSpinnerBlue)`
  width: 16px;
  height: 22px;
`;

export const StatusTitle = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

export const CheckButton = styled(Button)`
  min-height: 22px;

  @media (min-width: ${BreakPoint.TabletLow}) {
    min-width: 172px;
  }
`;
