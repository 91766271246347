import { useEffect } from 'react';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { useInnerWidth } from './useInnerWidth';

type TUseBlockContentScrollVariant = 'default' | 'main-menu';

export const useBlockContentScroll = (variant: TUseBlockContentScrollVariant = 'default'): void => {
  const { innerWidth } = useInnerWidth();

  useEffect(() => {
    if (variant === 'default') {
      document.body.classList.add('no-scroll');
    }
    if (variant === 'main-menu' && innerWidth < parseInt(BreakPoint.DesktopLow)) {
      document.body.classList.add('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [variant, innerWidth]);
};
