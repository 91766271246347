import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CaretRight } from 'assets/icons/dashboard-page-icons/caret-right.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/dashboard-page-icons/invoice.svg';
import dayjs from 'dayjs';
import {
  HISTORY_TRANSACTIONS_COUNT,
  INITIAL_DATE,
  RECENT_TRANSACTIONS_COUNT,
} from 'pages/PageTransactionsHistory/constants/constants';
import { useAppDispatch } from 'store/store';
import {
  selectTransactionHistory,
  selectTransactionHistoryStatus,
} from 'store/transaction-reducer/transaction-history.selectors';
import { requestTransactionHistoryData } from 'store/transaction-reducer/transaction-history.thunk-actions';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { SVisuallyHidden } from 'components/styled/SVisuallyHidden/SVisuallyHidden';
import { TransactionTypeBadge } from 'components/ui/TransactionTypeBadge';
import { getSlicedItemsWithoutIncorrectCategories } from 'utils/common/get-historyItems.utils';
import { AppRoute } from 'utils/route/app-route';

import * as S from './HistorySection.styled';

export const HistorySection: React.FC = () => {
  const dispatch = useAppDispatch();
  const transactionHistory = useSelector(selectTransactionHistory);
  const transactionHistoryStatus = useSelector(selectTransactionHistoryStatus);
  const { slicedItems } = getSlicedItemsWithoutIncorrectCategories(
    transactionHistory,
    0,
    RECENT_TRANSACTIONS_COUNT,
  );

  useEffect(() => {
    void dispatch(
      requestTransactionHistoryData({ count: HISTORY_TRANSACTIONS_COUNT, dateStart: INITIAL_DATE }),
    );
  }, [dispatch]);

  return (
    <S.SectionWrapper as="section">
      <SVisuallyHidden as="h2">Latest transactions history</SVisuallyHidden>
      <S.HistoryArticle>
        <S.Title as="h3">
          <InvoiceIcon />
          Recent transactions
        </S.Title>
        <S.HistoryLink to={AppRoute.TransactionsHistory()}>
          View all transactions
          <CaretRight />
        </S.HistoryLink>
        <S.TransactionsList>
          {transactionHistoryStatus === 'pending' ? (
            <SSpinnerBlue />
          ) : (
            slicedItems?.map(({ category, title, datetime, id }) => (
              <S.ListRow key={id}>
                <S.TransactionType as="dt">
                  <TransactionTypeBadge transactionType={category} />
                </S.TransactionType>
                <S.TransactionDate as="dd">{`${dayjs.utc(datetime).format('DD.MM.YYYY HH:mm')}`}</S.TransactionDate>
                <S.TransactionMessage as="dd">{title}</S.TransactionMessage>
              </S.ListRow>
            ))
          )}
        </S.TransactionsList>
      </S.HistoryArticle>
    </S.SectionWrapper>
  );
};
