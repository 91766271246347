import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { FallbackAuthErrorMessage } from 'constants/notification-messages/fallback-auth-error-message';
import { requestSocialLoginThunkAction } from 'store/auth-reducer/auth.thunk-actions';
import { useAppDispatch } from 'store/store';

import { notifyError } from 'utils/notify/notify.utils';

export const useGoogleLoginCallback = (
  mfaErrorHandlerRef: React.MutableRefObject<AsyncGenerator<
    string | undefined,
    void,
    string
  > | null>,
): (() => void) => {
  const dispatch = useAppDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) =>
      void dispatch(
        requestSocialLoginThunkAction({
          body: { token: response.access_token },
          socialProvider: 'google',
          errorHandlerRef: mfaErrorHandlerRef,
        }),
      ),
    onError: (errorResponse) =>
      errorResponse.error_description
        ? notifyError(errorResponse.error_description)
        : notifyError(FallbackAuthErrorMessage.CannotSignInWithGoogle),
  });

  return () => googleLogin();
};
