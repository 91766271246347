import React from 'react';
import { EPaymentType } from 'pages/PagePurchase/types/payment-type.enum';

import { BtcPaymentCard } from './components/BtcPaymentCard/BtcPaymentCard';
import { CardPaymentCard } from './components/CardPaymentCard';
import { CryptoPaymentCard } from './components/CryptoPaymentCard';

interface PaymentOptionDefaultProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
  balance: number;
  amount: number;
  paymentType: EPaymentType;
}

export const PaymentOption: React.FC<PaymentOptionDefaultProps> = (props) => {
  const { balance, amount, paymentType, handlePaymentButtonClick } = props;

  switch (paymentType) {
    case EPaymentType.CRYPTO:
      return <CryptoPaymentCard handlePaymentButtonClick={handlePaymentButtonClick} />;
    case EPaymentType.CARD:
      return <CardPaymentCard handlePaymentButtonClick={handlePaymentButtonClick} />;
    default:
      return (
        <BtcPaymentCard
          handlePaymentButtonClick={handlePaymentButtonClick}
          balance={balance}
          amount={amount}
        />
      );
  }
};
