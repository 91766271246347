import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/button-icons/copy.svg';
import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import { NBSP_JS } from 'constants/unicode/nbsp-js';
import { DepositBtcWarning } from 'pages/PageDashboard/components/ui/DepositBtcWarning';
import { PaymentStatusInfo } from 'pages/PageDashboard/components/ui/PaymentStatusInfo';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { copyText } from 'utils/common/copy-text';

import { ADDRESS_EXPIRATION_TIME_IN_SECONDS } from './constants/address-expiration-seconds.const';
import {
  ADDRESS_EXPIRATION_WARNING_TEXT,
  PAYMENT_DESCRIPTION_AFTER_AMOUNT,
  PAYMENT_DESCRIPTION_BEFORE_AMOUNT,
  TIMER_TITLE,
} from './constants/ui-text';
import { IDepositBtcPaymentProps } from './types/deposit-btc-payment-props.interface';
import { getFormattedExpirationTime } from './utils/get-formatted-expiration-time.util';

import * as S from './DepositBtcPayment.styled';

export const DepositBtcPayment: React.FC<IDepositBtcPaymentProps> = ({
  amountToPay,
  walletAddress,
  qrCodeUrl,
  paymentStatus,
  handleStatusCheck,
  handleRefreshAddress,
  handleFinishButtonClick,
  isDepositDataPending,
  isPaymentStatusPending,
}) => {
  const [timeDelay, setTimeDelay] = useState<number>(ADDRESS_EXPIRATION_TIME_IN_SECONDS);
  const updateTimeInterval = useRef<ReturnType<typeof setInterval>>();

  const handleCopyClick = () => copyText(walletAddress);

  const handleRefreshButtonClick = () => {
    setTimeDelay(ADDRESS_EXPIRATION_TIME_IN_SECONDS);
    handleRefreshAddress();
  };

  useEffect(() => {
    if (timeDelay > 0) {
      updateTimeInterval.current = setInterval(() => {
        setTimeDelay((current) => current - 1);
      }, SECOND_IN_MILLISECONDS);
    }
    return () => clearInterval(updateTimeInterval.current);
  }, [timeDelay]);

  return (
    <S.Container>
      <S.MainContentWrapper>
        <S.PaymentDescription>
          {PAYMENT_DESCRIPTION_BEFORE_AMOUNT}
          <S.PaymentAmount>{`${amountToPay}${NBSP_JS}BTC`}</S.PaymentAmount>
          {PAYMENT_DESCRIPTION_AFTER_AMOUNT}
        </S.PaymentDescription>

        <S.WalletAddressWrapper>
          {isDepositDataPending ? (
            <S.WalletAddressSpinner />
          ) : (
            <S.WalletAddress>{walletAddress}</S.WalletAddress>
          )}
          <S.CopyButton
            variant="borderless"
            icon={<CopyIcon />}
            onClick={handleCopyClick}
            title="Copy wallet address"
            disabled={isDepositDataPending}
          />
        </S.WalletAddressWrapper>

        <S.QrCodeWrapper>
          <S.QrCode>
            {isDepositDataPending ? (
              <SSpinnerBlue />
            ) : (
              <S.QrCodeImage width={120} height={120} alt="QR-code" src={qrCodeUrl} />
            )}
          </S.QrCode>
          <S.AddressExpirationWrapper>
            <S.AddressExpirationWarning>
              {ADDRESS_EXPIRATION_WARNING_TEXT}
            </S.AddressExpirationWarning>
            <S.AddressExpirationTimer>
              <S.TimerTitle>{TIMER_TITLE}</S.TimerTitle>
              <S.ExpirationTime>{getFormattedExpirationTime(timeDelay)}</S.ExpirationTime>
            </S.AddressExpirationTimer>
          </S.AddressExpirationWrapper>
        </S.QrCodeWrapper>

        <PaymentStatusInfo
          paymentStatus={paymentStatus}
          handleStatusCheck={handleStatusCheck}
          handleRefreshAddress={handleRefreshButtonClick}
          isDataPending={isDepositDataPending || isPaymentStatusPending}
        />

        <DepositBtcWarning />
      </S.MainContentWrapper>

      <S.FinishButton text="Finish" onClick={handleFinishButtonClick} />
    </S.Container>
  );
};
