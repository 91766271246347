import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as ResendIcon } from 'assets/icons/button-icons/refresh.svg';
import { ReactComponent as BackIcon } from 'assets/icons/link-icons/back-icon.svg';
import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import { FormApi } from 'final-form';
import { IOtpFormValues } from 'types/ui/OtpForm/otp-form-values.interface';

import { hideEmailAddress } from 'utils/formatters/hide-email-address.util';
import { removeSpacesAndEmojis } from 'utils/formatters/remove-spaces-and-emojis.util';
import { gtmClickResetPasswordConfirm } from 'utils/gtmSender/gtmSender';
import { getEmailSendTime } from 'utils/storage/date-and-time/get-email-send-time.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { OTP_LENGTH } from 'utils/validators/constants/validation.constants';
import { hasExactLength } from 'utils/validators/has-exact-length';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import { RESEND_TIMEOUT_SECONDS } from './constants/resend-timeout-seconds.const';
import { FIVE_DIGIT_TITLE, STRING_CODE_TITLE } from './constants/ui-text.const';
import { IOtpFormProps } from './types/otp-form-props.interface';
import { getFormattedTimeDelay } from './utils/get-formatted-time-delay.util';
import { getTimerValueInSeconds } from './utils/get-timer-value-in-seconds.util';

import * as S from './OtpForm.styled';

export const OtpForm: React.FC<IOtpFormProps> = ({
  title,
  description,
  codeType = 'five-digit',
  email,
  handleFormSubmit,
  handleResendCode,
  handleBackClick,
  isSubmitting = false,
  hasBackendError = false,
}) => {
  const emailSendTime = getEmailSendTime();
  const initialTime = emailSendTime ? getTimerValueInSeconds(emailSendTime) : 0;
  const [timeDelay, setTimeDelay] = useState<number>(initialTime);
  const updateTimeInterval = useRef<ReturnType<typeof setInterval>>();

  const handleFieldChange = (
    value: string,
    form: FormApi<IOtpFormValues, Partial<IOtpFormValues>>,
  ) => form.change('code', removeSpacesAndEmojis(value));

  const handleResendClick = (): void => {
    handleResendCode();
    setTimeDelay(RESEND_TIMEOUT_SECONDS);
  };

  const handleGtmEvent = (): void => {
    if (title !== 'E-mail confirmation') {
      gtmClickResetPasswordConfirm();
    }
  };

  useEffect(() => {
    if (timeDelay > 0) {
      updateTimeInterval.current = setInterval(() => {
        setTimeDelay((current) => current - 1);
      }, SECOND_IN_MILLISECONDS);
    }
    return () => clearInterval(updateTimeInterval.current);
  }, [timeDelay]);

  return (
    <S.OtpSection>
      <S.MainContentWrapper>
        <S.Title>{title}</S.Title>

        {handleBackClick && (
          <S.BackButton
            variant="borderless"
            text="Return"
            icon={<BackIcon />}
            onClick={handleBackClick}
          />
        )}

        {description && <S.FormDescription>{description}</S.FormDescription>}

        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <S.Form onSubmit={handleSubmit} $isFailed={submitFailed || hasBackendError}>
              <Field
                name="code"
                validate={composeValidators(
                  codeType === 'five-digit'
                    ? [isRequired, isNumber, hasExactLength(OTP_LENGTH)]
                    : [isRequired],
                )}
              >
                {({ input, meta }) => (
                  <S.InputLabel>
                    <S.LabelText>
                      {codeType === 'five-digit' ? FIVE_DIGIT_TITLE : STRING_CODE_TITLE}
                      <br />
                      {hideEmailAddress(email)}
                    </S.LabelText>
                    <S.InputWrapper>
                      <S.CodeInput
                        {...input}
                        type="text"
                        hasErrors={(meta.error && submitFailed) || hasBackendError}
                        onChange={(evt) => handleFieldChange(evt.currentTarget.value, form)}
                        hasValidationIcons={true}
                        autoComplete="off"
                        autoFocus={true}
                      />
                      <S.ResendCodeButton
                        type="button"
                        variant="secondary"
                        text={
                          timeDelay
                            ? `Repeat in ${getFormattedTimeDelay(timeDelay)}`
                            : 'Send it again'
                        }
                        icon={timeDelay ? undefined : <ResendIcon />}
                        isReversed={true}
                        onClick={handleResendClick}
                        disabled={!!timeDelay}
                      />
                    </S.InputWrapper>
                    {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                  </S.InputLabel>
                )}
              </Field>

              <S.SubmitButton
                type="submit"
                text="Confirm"
                disabled={(submitFailed && hasValidationErrors) || isSubmitting}
                onClick={handleGtmEvent}
              />
            </S.Form>
          )}
        </Form>

        <S.AdditionalInfoText>{`If you can't find the email with the code, check your spam folder.`}</S.AdditionalInfoText>
      </S.MainContentWrapper>

      <S.RequiredFieldsNote>Required field</S.RequiredFieldsNote>
    </S.OtpSection>
  );
};
