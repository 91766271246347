import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-content: center;
  }
`;

export const CurrentPage = styled.span`
  display: flex;
  align-items: center;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #646464;
  user-select: none;

  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const ButtonsGroup = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-areas: 'left-direction left-divider buttons right-divider rigth-direction';
  grid-template-columns: 44px 12px 1fr 12px 44px;
  justify-items: center;
`;

export const DirectionButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
`;

export const LeftDirection = styled(DirectionButton)`
  grid-area: left-direction;
  justify-self: start;
`;

export const RightDirection = styled(DirectionButton)`
  grid-area: rigth-direction;
  justify-self: end;
`;

export const NumbersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: buttons;
`;

export const Divider = styled.span`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #272727;
`;

export const LeftDivider = styled(Divider)`
  grid-area: left-divider;
`;

export const RightDivider = styled(Divider)`
  grid-area: right-divider;
`;
