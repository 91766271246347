import axios from 'axios';

import { deleteAuthToken } from 'utils/storage/auth-token/delete-auth-token';
import { getAuthToken } from 'utils/storage/auth-token/get-auth-token';

export const cuverseApi = axios.create({
  baseURL: 'https://backv3hf3igk1i4oj8.cryptouniverse.io/api',
  responseType: 'json',
});

cuverseApi.interceptors.request.use(async (req) => {
  const token = getAuthToken();
  if (token) {
    req.headers['Authorization'] = `Bearer ${token}`;
  }

  return Promise.resolve(req);
});

cuverseApi.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response?.status === 401) {
      deleteAuthToken();
    }

    return Promise.reject(error);
  },
);
