import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const ExplainerArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px;
  }
`;

export const ExplainerParagraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 380px;
  max-height: 320px;
  padding: 12px;
  border-radius: 12px;
  background-color: #f4f4f7;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #c9c9c9 transparent;

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    max-height: 388px;
  }
`;

export const ExplainerParagraph = styled.p`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;
