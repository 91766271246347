import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TElectricityData } from 'store/api/cuverse-api/dashboard/types/dashboard-electricity-response';
import { IDashboardHashRateData } from 'store/api/cuverse-api/dashboard/types/dashboard-hashrate-response';
import { IDashboardMiningTotalData } from 'store/api/cuverse-api/dashboard/types/dashboard-mining-response';
import { IWalletData } from 'store/api/cuverse-api/dashboard/types/dashboard-wallet-response';
import { TFetchStatus } from 'types/api/fetch-status.type';

import {
  requestDashboardElectricity,
  requestDashboardMiningHashRate,
  requestDashboardMiningTotal,
  requestDashboardWalletBalance,
} from './dashboard.thunk-actions';

export interface IDashboardState {
  dashboardWallet: IWalletData;
  dashboardElectricity: TElectricityData;
  dashboardHashRate: IDashboardHashRateData;
  dashboardMining: IDashboardMiningTotalData;
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IDashboardState = {
  dashboardWallet: {
    amount: 0,
    usdAmount: 0,
    isIncreased: true,
    lockedAmount: 0,
  },
  dashboardElectricity: {
    balance: 0,
    dailyTotalFee: 0,
    totalEfficiency: 0,
    availableDays: 0,
  },
  dashboardHashRate: {
    active: {
      hashrate: 0,
      number: 0,
    },
    pending: {
      hashrate: 0,
      number: 0,
    },
  },
  dashboardMining: {
    BTC: {
      yesterday: 0,
      historical: 0,
    },
  },
  status: 'initial',
  error: null,
};

const dashboardBalancesSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestDashboardWalletBalance.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      requestDashboardWalletBalance.fulfilled,
      (state, action: PayloadAction<IWalletData>) => {
        state.status = 'fulfilled';
        state.dashboardWallet = action.payload;
      },
    );
    builder.addCase(requestDashboardWalletBalance.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
    builder.addCase(requestDashboardElectricity.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      requestDashboardElectricity.fulfilled,
      (state, action: PayloadAction<TElectricityData>) => {
        state.status = 'fulfilled';
        state.dashboardElectricity = action.payload;
      },
    );
    builder.addCase(requestDashboardElectricity.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
    builder.addCase(requestDashboardMiningHashRate.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      requestDashboardMiningHashRate.fulfilled,
      (state, action: PayloadAction<IDashboardHashRateData>) => {
        state.status = 'fulfilled';
        state.dashboardHashRate = action.payload;
      },
    );
    builder.addCase(requestDashboardMiningHashRate.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
    builder.addCase(requestDashboardMiningTotal.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      requestDashboardMiningTotal.fulfilled,
      (state, action: PayloadAction<IDashboardMiningTotalData>) => {
        state.status = 'fulfilled';
        state.dashboardMining = action.payload;
      },
    );
    builder.addCase(requestDashboardMiningTotal.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const dashboardReducer = dashboardBalancesSlice.reducer;
