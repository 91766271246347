import { useEffect, useState } from 'react';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EPaymentStatus } from 'types/ui/PaymentStatus/payment-status.enum';

import { TButtonVariant } from 'components/ui/Button/Button.types';

export const useCheckButtonAttributes = (
  status: EPaymentStatus,
): {
  buttonText: string;
  buttonVariant: TButtonVariant;
} => {
  const { innerWidth } = useInnerWidth();

  const [buttonVariant, setButtonVariant] = useState<TButtonVariant>('bordered');

  const isMobileDevice = innerWidth <= parseInt(BreakPoint.MobileTop);

  let buttonText: string;

  switch (status) {
    case EPaymentStatus.New:
    case EPaymentStatus.Pending:
    case EPaymentStatus.Paid:
      buttonText = 'Check status';
      if (!isMobileDevice && buttonVariant !== 'bordered') {
        setButtonVariant('bordered');
      }
      break;
    case EPaymentStatus.Error:
      buttonText = 'Try again';
      if (!isMobileDevice && buttonVariant !== 'primary') {
        setButtonVariant('primary');
      }
      break;
    case EPaymentStatus.Expired:
      buttonText = 'Refresh address';
      if (!isMobileDevice && buttonVariant !== 'primary') {
        setButtonVariant('primary');
      }
  }

  useEffect(() => {
    if (isMobileDevice && buttonVariant !== 'borderless') {
      setButtonVariant('borderless');
    }
  }, [isMobileDevice, buttonVariant]);

  return { buttonText, buttonVariant };
};
