import { verificationStatuses } from 'pages/PageVerification/constants/constants';
import { EVerificationStatusCode } from 'store/api/cuverse-api/profile/types/profile-response.interface';

import { getIsBasicVerificationStatus } from './get-is-basic-verification-status.util';

/**
 * Verification page title
 * @param verificationStatusCode status of verification
 * @returns string title
 */
export const getVerificationPageTitle = (
  verificationStatusCode: EVerificationStatusCode,
): string => {
  return getIsBasicVerificationStatus(verificationStatusCode)
    ? verificationStatuses.fullVerification.statusText
    : verificationStatuses.basicVerification.statusText;
};
