import React from 'react';
import { usePurchaseBody } from 'pages/PagePurchase//hooks/usePurchaseBody';
import { usePurchaseHeader } from 'pages/PagePurchase//hooks/usePurchaseHeader';

export const PagePurchase: React.FC = () => {
  const { headerContent } = usePurchaseHeader();
  const { invoiceBodyContent } = usePurchaseBody();

  return (
    <>
      {headerContent}
      {invoiceBodyContent}
    </>
  );
};
