import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const ScreenWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 24px;
  padding: 32px 24px 64px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    gap: 20px;
    padding: 32px 16px 64px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 16px;
    padding: 24px 16px 64px;
  }
`;

export const CartBlock = styled.section<{ $isEmptyCart: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: start;
  ${({ $isEmptyCart }) => {
    if ($isEmptyCart) {
      return css`
        align-items: center;
        justify-content: center;
        gap: 8px;
      `;
    }
  }};
  min-width: 354px;
  margin-top: 56px;
  padding: 24px 24px 32px;
  border-radius: 24px;
  background: #fff;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    min-width: 310px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    min-width: 232px;
    padding: 16px;
    ${({ $isEmptyCart }) => {
      if ($isEmptyCart) {
        return css`
          padding: 16px 24px 16px 25px;
        `;
      }
    }};
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    align-self: stretch;
    min-width: 288px;
    margin-top: 0;
    padding: 16px;
    ${({ $isEmptyCart }) => {
      if ($isEmptyCart) {
        return css`
          padding: 16px 52px 16px 53px;
        `;
      }
    }};
  }
`;
