import { TSocialLoginProvider } from './types/social-login-provider.type';

export const AuthRoute = {
  Confirm2FA: (): string => '/profile/2fa/confirm',
  Disable2FA: (): string => '/profile/2fa/disable',
  EmailResend: (): string => '/email/resend',
  EmailVerify: (): string => '/email/verify',
  Enable2FA: (): string => '/profile/2fa/enable',
  Login: (): string => '/login',
  LoginSocial: (loginProvider: TSocialLoginProvider): string => `/login/social/${loginProvider}`,
  Logout: (): string => '/logout',
  OtpVerify: (): string => '/password/verify',
  PasswordChangeCode: (): string => '/password/change',
  PasswordReset: (): string => '/password/reset',
  RefreshToken: (): string => '/login/refresh',
  Register: (): string => '/register',
};
