import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputLabel = styled.label<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 100px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }}
`;

export const LabelText = styled(SText)`
  display: inline-flex;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &::after {
    content: '*';
    font-weight: 900;
    color: #ec494f;
  }
`;

export const DepositFormInput = styled(Input)`
  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      height: 42px;
      padding: 11px 120px 11px 92px;
      font-size: 14px;
      line-height: 18px;
    }

    & > div {
      left: 12px;
    }
  }
`;

export const InputDescriptionText = styled(SText)`
  opacity: 0.8;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #646464;
  font-variant-numeric: tabular-nums;
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
  border-radius: 24px;
`;
