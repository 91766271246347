import styled from 'styled-components';

import { SText } from 'components/styled/SText';

export const WarningBanner = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  background-color: #fff6d9;

  & svg {
    flex-shrink: 0;
  }
`;

export const WarningBannerText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
`;
