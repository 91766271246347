import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { LinkItem } from 'components/ui/LinkItem';

export const Container = styled.div<{ $hasBackground: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 32px 26px;
  border-radius: 16px;
  background-color: ${({ $hasBackground }) => ($hasBackground ? '#e5eaf1' : 'transparent')};

  @media (max-width: ${BreakPoint.LaptopTop}) and (min-width: ${BreakPoint.LaptopLow}) {
    margin-right: 24px;
    margin-left: 24px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) and (min-width: ${BreakPoint.TabletLow}) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin: 24px 16px 32px;
    padding: 24px;
  }
`;

export const NotFoundCard = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NotFoundCode = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 60px;
  font-weight: 400;
  line-height: 80px;
  text-align: center;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 48px;
    line-height: 64px;
  }
`;

export const NotFoundTitle = styled.span`
  margin-bottom: 32px;
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NavigationLink = styled(LinkItem)`
  padding: 16px 24px;
  border-radius: 36px;
  background-color: #0e46c0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #03349e;
    color: #fff;
  }

  &:active {
    background-color: #03349e;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 240px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 18px;
  }
`;
