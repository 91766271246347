import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckIcon } from 'assets/icons/button-icons/check-circle.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/button-icons/copy.svg';
import { ReactComponent as EditIcon } from 'assets/icons/button-icons/edit.svg';
import { ReactComponent as BtcIcon } from 'assets/icons/currency-icons/btc.svg';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { IWalletApproveRequestBody } from 'store/api/cuverse-api/profile/types/wallet-approve-request-body.interface';
import { openChangeWalletPopupAction } from 'store/modals-reducer/modals.reducer';
import {
  setChangeWalletAddressStepAction,
  setNewWalletAddressAction,
  setWalletChangeStatusAction,
} from 'store/profile-reducer/profile.reducer';
import {
  selectWalletApproveStatus,
  selectWalletChangeStatus,
  selectWallets,
} from 'store/profile-reducer/profile.selectors';
import {
  requestWalletApproveOtpThunkAction,
  requestWalletsDataThunkAction,
} from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { IWalletAddressFormValues } from 'types/ui/ProfileTabs/wallet-address-form-values.interface';

import { Button } from 'components/ui/Button';
import { RESEND_TIMEOUT_SECONDS } from 'components/ui/OtpForm/constants/resend-timeout-seconds.const';
import { copyText } from 'utils/common/copy-text';
import { removeSpacesAndEmojis } from 'utils/formatters/remove-spaces-and-emojis.util';
import { getEmailSendTime } from 'utils/storage/date-and-time/get-email-send-time.util';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isRequired } from 'utils/validators/is-required';
import { isValidBtcAddress } from 'utils/validators/is-valid-btc-address';

import {
  ADDRESS_NOT_CONFIRMED_TEXT,
  BITCOIN_TEXT,
  WALLET_ADDRESS_LABEL,
} from './constants/ui-text.const';
import { shortenWalletAddress } from './utils/shorten-wallet-address.util';

import * as S from './WalletAddressForm.styled';

export const WalletAddressForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const wallets = useSelector(selectWallets);
  const walletChangeStatus = useSelector(selectWalletChangeStatus);
  const isWalletAddressEditable = walletChangeStatus !== 'initial';
  const isWalletAddressNotConfirmed = walletChangeStatus === 'not-confirmed';
  const walletApproveFetchStatus = useSelector(selectWalletApproveStatus);
  const isWalletApprovePending = walletApproveFetchStatus === 'pending';

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [walletAddress, setWalletAddress] = useState<string>(wallets.btc);

  const { innerWidth } = useInnerWidth();
  const isMobileWidth = innerWidth <= parseInt(BreakPoint.MobileTop);

  const shouldWalletAddressBeHidden = isMobileWidth && walletAddress && !isWalletAddressEditable;

  const displayedWalletAddress = shouldWalletAddressBeHidden
    ? shortenWalletAddress(walletAddress)
    : walletAddress;

  const handleCopyButtonClick = () => copyText(walletAddress);

  const handleChangeAddressButtonClick = () => {
    dispatch(setWalletChangeStatusAction('changing-wallet'));
    // timeout added to provide correct focus on mobile devices
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleWalletAddressInputChange = (
    value: string,
    form: FormApi<IWalletAddressFormValues, Partial<IWalletAddressFormValues>>,
  ) => form.change('wallet', removeSpacesAndEmojis(value));

  const handleSubmit = (values: IWalletAddressFormValues) => {
    const isResendTimeExpired = dayjs().diff(getEmailSendTime(), 'second') > RESEND_TIMEOUT_SECONDS;
    if (!isWalletApprovePending && isResendTimeExpired) {
      const body: IWalletApproveRequestBody = { type: 'btc_wallet' };
      setEmailSendTime();
      void dispatch(requestWalletApproveOtpThunkAction(body));
    }
    setWalletAddress(values.wallet);
    dispatch(setChangeWalletAddressStepAction('wallet-address-confirm'));
    dispatch(openChangeWalletPopupAction());
    dispatch(setNewWalletAddressAction(values.wallet));
  };

  useEffect(() => {
    dispatch(setWalletChangeStatusAction('initial'));
    dispatch(setNewWalletAddressAction(null));
    void dispatch(requestWalletsDataThunkAction());
  }, [dispatch]);

  useEffect(() => {
    setWalletAddress(wallets.btc);
  }, [wallets]);

  return (
    <S.ContentWrapper>
      <S.Currency>
        <BtcIcon />
        <S.CurrencyName>{BITCOIN_TEXT}</S.CurrencyName>
      </S.Currency>

      <Form onSubmit={handleSubmit} initialValues={{ wallet: displayedWalletAddress }}>
        {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
          <form onSubmit={handleSubmit} id="wallet-form">
            <Field name="wallet" validate={composeValidators([isRequired, isValidBtcAddress])}>
              {({ input, meta }) => (
                <S.WalletAddress $isFailed={submitFailed}>
                  <S.InputLabel htmlFor="wallet">{WALLET_ADDRESS_LABEL}</S.InputLabel>
                  <S.AddressWrapper>
                    <S.InputWrapper>
                      <S.WalletAddressInput
                        {...input}
                        ref={inputRef}
                        type="text"
                        id="wallet"
                        isReadOnly={!isWalletAddressEditable}
                        autoComplete="off"
                        tabIndex={isWalletAddressEditable ? 0 : -1}
                        hasErrors={meta.error && submitFailed}
                        onChange={(evt) =>
                          handleWalletAddressInputChange(evt.currentTarget.value, form)
                        }
                      />
                      {!isWalletAddressEditable && (
                        <S.CopyButton
                          type="button"
                          variant="borderless"
                          onClick={handleCopyButtonClick}
                          icon={<CopyIcon />}
                          disabled={!walletAddress}
                        />
                      )}
                    </S.InputWrapper>

                    {!isWalletAddressNotConfirmed && (
                      <S.EditButton
                        type="button"
                        variant={isMobileWidth ? 'borderless' : 'bordered'}
                        text={isWalletAddressEditable ? 'Save address' : 'Change address'}
                        onClick={
                          isWalletAddressEditable ? handleSubmit : handleChangeAddressButtonClick
                        }
                        icon={<EditIcon />}
                        disabled={
                          (submitFailed && hasValidationErrors) ||
                          (isWalletAddressEditable && isWalletApprovePending)
                        }
                      />
                    )}
                  </S.AddressWrapper>
                  {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                </S.WalletAddress>
              )}
            </Field>
          </form>
        )}
      </Form>

      {isWalletAddressNotConfirmed && (
        <S.NotConfirmedBanner>
          <S.NotConfirmedNote>{ADDRESS_NOT_CONFIRMED_TEXT}</S.NotConfirmedNote>
          <Button
            type="submit"
            form="wallet-form"
            variant="borderless"
            text="Confirm address"
            icon={<CheckIcon />}
            disabled={isWalletApprovePending}
          />
        </S.NotConfirmedBanner>
      )}
    </S.ContentWrapper>
  );
};
