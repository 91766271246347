import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { WalletAddressAdding } from 'pages/PageDashboard/components/ui/WalletAddressAdding';
import { ConfirmWalletChangeContent } from 'pages/PageProfile/components/logic/ConfirmWalletChangeContent';
import { selectChangeWalletStep } from 'store/profile-reducer/profile.selectors';


interface IChangeWalletPopupAttributes {
  walletAddressPopupTitle: string;
  walletAddressPopupContent: ReactElement | null;
}

export const useChangeWalletPopupAttributes = (): IChangeWalletPopupAttributes => {
  const changeWalletAddressStep = useSelector(selectChangeWalletStep);

  let walletAddressPopupTitle = '';
  let walletAddressPopupContent = null;

  switch (changeWalletAddressStep) {
    case 'initial':
      walletAddressPopupTitle = 'Withdrawal';
      walletAddressPopupContent = <WalletAddressAdding />;
      break;
    case 'wallet-address-confirm':
      walletAddressPopupTitle = 'Confirm wallet address';
      walletAddressPopupContent = <ConfirmWalletChangeContent />;
  }

  return { walletAddressPopupTitle, walletAddressPopupContent };
};
