import React from 'react';
import { MinersGroupByModel } from 'pages/PageAssets/components/ui/MobileGroupByModel';
import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';

import * as S from './AssetsTableMobile.styled';

interface IAssetsTableMobileProps {
  minersByModel: TMinersGroupByModel[];
  btcToUsdRate: number;
}

export const AssetsTableMobile: React.FC<IAssetsTableMobileProps> = ({
  minersByModel,
  btcToUsdRate,
}) => {
  return (
    <S.TableWrapper>
      {minersByModel.map((minersGroup: TMinersGroupByModel) => {
        const minerModel = Object.keys(minersGroup)[0];
        return (
          <MinersGroupByModel
            key={minerModel}
            minersGroupByModel={minersGroup}
            btcToUsdRate={btcToUsdRate}
          />
        );
      })}
    </S.TableWrapper>
  );
};
