import styled from 'styled-components';
import { datePickerReveal } from 'styles/keyframes/date-picker-reveal';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';
import { ZIndex } from 'styles/style-variables/z-index';

export const DatePickerContainer = styled.div`
  position: relative;
  z-index: ${ZIndex.DatePicker};

  & .react-datepicker-wrapper {
    width: 100%;
  }

  & .react-datepicker {
    position: relative;
    margin-top: -6px;
    padding: 12px 0;
    border: none;
    border-radius: 24px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%);
    animation: ${datePickerReveal} ${AnimationDuration.DatePickerReveal} ease;
    user-select: none;
  }

  & .react-datepicker__view-calendar-icon {

    & > svg {
      top: 50%;
      right: 4px;
      z-index: ${ZIndex.DatePicker};
      cursor: pointer;
      transform: translateY(-50%);
    }

    & input {
      padding: 11px 40px 11px 15px;
      cursor: pointer;
    }
  }

  & .react-datepicker__header {
    padding: 0;
    border: none;
    background: none;
  }

  & .react-datepicker__day-names {
    display: flex;
    margin: 0 8px;
  }

  & .react-datepicker__day-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;
    font-family: ${FontFamily.Montserrat};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    color: #a1a1a1;
  }

  & .react-datepicker__month {
    margin: 0 8px;
  }

  & .react-datepicker__week {
    display: flex;
  }

  & .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;
    border-radius: 12px;
    font-family: ${FontFamily.Montserrat};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #272727;
    transition: all ${TransitionTime.Hover};
    outline: none;

    &:hover,
    &:focus {
      background-color: #eaeaea;
    }

    &.react-datepicker__day--outside-month,
    &.react-datepicker__day--disabled {
      color: #a1a1a1;
    }

    &.react-datepicker__day--today {
      font-weight: 700;
      color: #0e46c0;
    }

    &.react-datepicker__day--keyboard-selected {
      background-color: #eaeaea;
    }

    &.react-datepicker__day--selected {
      background-color: #6a96f6;
      color: #fff;

      &:hover,
      &:focus {
        background-color: #03349e;
      }
    }

    &.react-datepicker__day--disabled {

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
`;
