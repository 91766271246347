import { EVerificationStatusCode } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * Not verified status
 * @param verificationStatusCode status of verification
 * @returns boolean value
 */
export const getIsNotVerifiedStatus = (
  verificationStatusCode: EVerificationStatusCode,
): boolean => {
  return [
    EVerificationStatusCode.KYC_STATUS_UNVERIFIED,
    EVerificationStatusCode.KYC_STATUS_PRIMARY_NEEDS_VERIFICATION,
    EVerificationStatusCode.KYC_STATUS_PRIMARY_INIT,
    EVerificationStatusCode.KYC_STATUS_PRIMARY_PROCESSING,
    EVerificationStatusCode.KYC_STATUS_PRIMARY_RETRY,
    EVerificationStatusCode.KYC_STATUS_PRIMARY_REJECT,
  ].includes(verificationStatusCode);
};
