import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const VerificationPanel = styled.section<{ isWithdrawalView?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: ${({ isWithdrawalView }) => (isWithdrawalView ? '0 24px 24px' : '24px')};
  border-radius: 24px;
  background-color: #fff;
  font-family: ${FontFamily.Montserrat};

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px;
    background-color: ${({ isWithdrawalView }) => (isWithdrawalView ? '#fff' : '#f4f4f7')};
  }
`;

export const WithdrawalLimit = styled.div<{ isWithdrawalView?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    background-color: ${({ isWithdrawalView }) => (isWithdrawalView ? '#fff' : '#f4f4f7')};
  }
`;

export const LimitPanel = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: #f4f4f7;

  @media (max-width: ${BreakPoint.MobileTop}) {
    background-color: #e5eaf1;
  }
`;

export const LimitTitle = styled(SText)`
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
`;

export const LimitValue = styled(SText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;
