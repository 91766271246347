import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const GroupByModelWrapper = styled.article`
  display: flex;
  flex-direction: column;
`;

export const GroupTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
`;

export const TitleText = styled.span`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;
