import React from 'react';
import { ReactComponent as QuestionGrayIcon } from 'assets/icons/button-icons/question-gray.svg';
import { ReactComponent as Calendar } from 'assets/icons/dashboard-page-icons/calendar.svg';
import { ReactComponent as Clock } from 'assets/icons/dashboard-page-icons/clock.svg';
import { ReactComponent as HashrateIcon } from 'assets/icons/dashboard-page-icons/hashrate.svg';
import { ReactComponent as MinerBlack } from 'assets/icons/dashboard-page-icons/miner-black.svg';
import { ReactComponent as MinerGray } from 'assets/icons/dashboard-page-icons/miner-gray.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/dashboard-page-icons/timer.svg';
import { SArticleTitle } from 'pages/PageDashboard/components/styled';
import {
  HASH_RATE_AMOUNT_TITLE,
  LAUNCH_PENDING_TITLE,
  MINED_YESTERDAY_TITLE,
  MINING_SECTION_TITLE,
  TOTAL_MINED_TITLE,
} from 'pages/PageDashboard/constants/constants';

import { Button } from 'components/ui/Button';

import { DISABLED_65_UPTIME, ENABLED_65_UPTIME } from './constants/constants';

import * as S from './MiningArticle.styled';

interface IMiningArticleProps {
  hashrate: string;
  activeMinersCount: string;
  launchPendingHashrate: string;
  launchPendingMinersCount: string;
  minedYesterdayBtc: string;
  totalMinedBtc: string;
  isEnabled: boolean;
  openLimitedUptimePopup: () => void;
}

export const MiningArticle: React.FC<IMiningArticleProps> = ({
  hashrate,
  activeMinersCount,
  launchPendingMinersCount,
  launchPendingHashrate,
  minedYesterdayBtc,
  totalMinedBtc,
  isEnabled,
  openLimitedUptimePopup,
}) => {
  return (
    <S.MiningArticle as="article">
      <S.ArticleTittleWrapper>
        <SArticleTitle as="h3">
          <MinerBlack />
          {MINING_SECTION_TITLE}
        </SArticleTitle>
        <Button
          className="article-button"
          variant={'primary-gray'}
          text={isEnabled ? ENABLED_65_UPTIME : DISABLED_65_UPTIME}
          icon={<QuestionGrayIcon />}
          isReversed
          onClick={openLimitedUptimePopup}
        />
      </S.ArticleTittleWrapper>
      <S.HashrateList as="dl">
        <S.HashrateAmountWrapper>
          <HashrateIcon />
          <S.HashrateTitle as="dt">{HASH_RATE_AMOUNT_TITLE}</S.HashrateTitle>
          <S.HashrateAmountValue as="dd">{hashrate}</S.HashrateAmountValue>
          <S.HashrateMinersCount as="dd">
            <MinerGray />
            {activeMinersCount}
          </S.HashrateMinersCount>
        </S.HashrateAmountWrapper>

        <S.LaunchPendingWrapper>
          <TimerIcon />
          <S.LaunchPendingTitle as="dt">{LAUNCH_PENDING_TITLE}</S.LaunchPendingTitle>
          <S.LaunchPendingHashrateValue as="dd">
            {launchPendingHashrate}
          </S.LaunchPendingHashrateValue>
          <S.LaunchPendingMinersCount as="dd">
            <MinerGray />
            {launchPendingMinersCount}
          </S.LaunchPendingMinersCount>
        </S.LaunchPendingWrapper>
      </S.HashrateList>

      <S.MiningList as="dl">
        <S.MiningItemWrapper>
          <S.MiningTitle as="dt">
            <Clock />
            {MINED_YESTERDAY_TITLE}
          </S.MiningTitle>
          <S.MiningValue as="dd">{minedYesterdayBtc}</S.MiningValue>
        </S.MiningItemWrapper>
        <S.MiningItemWrapper>
          <S.MiningTitle as="dt">
            <Calendar />
            {TOTAL_MINED_TITLE}
          </S.MiningTitle>
          <S.MiningValue as="dd">{totalMinedBtc}</S.MiningValue>
        </S.MiningItemWrapper>
      </S.MiningList>
    </S.MiningArticle>
  );
};
