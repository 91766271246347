import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  & tr {
    border-top: 1px solid #eaeaea;
  }

  & th,
  td {
    padding: 12px 0;
    text-align: start;

    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      min-width: 102px;
    }

    &:nth-child(3) {
      min-width: 116px;
    }

    &:nth-child(4) {
      min-width: 98px;

      @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
        min-width: 52px;
      }

      @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
        min-width: 52px;
      }
    }
  }

  & tr:last-child td {
    padding-bottom: 0;
  }
`;

export const HeadSell = styled.div`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #a1a1a1;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TitleCell = styled.div`
  display: flex;
  align-items: center;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #272727;
`;

export const ValueCell = styled(TitleCell)`
  font-size: 14px;
  line-height: 18px;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const PurchaseTypeCell = styled(TitleCell)`
  font-size: 14px;
  line-height: 21px;
  color: #a1a1a1;
`;
