import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const LimitedUptimeSection = styled.section<{
  $isCooldownActive: boolean;
}>`
  ${({ $isCooldownActive }) => {
    const basic = css`
      display: grid;
      gap: 16px;
      grid-template-areas:
        'title firstButton secondButton'
        'description description description';
      grid-template-columns: 1fr auto auto;
      padding: 24px;
      border-radius: 24px;
      background-color: #fff;
    `;

    const gridStyles = {
      laptop: css`
        grid-template-areas:
          'title firstButton'
          'secondButton secondButton'
          'description description';
        grid-template-columns: 1fr auto;
      `,
      mobile: css`
        gap: 8px;
        grid-template-areas:
          'title'
          'description'
          'firstButton'
          'secondButton';
        grid-template-columns: auto;
      `,
    };

    if ($isCooldownActive) {
      return css`
        ${basic};

        @media (max-width: ${BreakPoint.DesktopLow}) {
          padding: 16px;
        }

        @media (max-width: ${BreakPoint.LaptopLow}) {
          ${gridStyles.laptop};
        }

        @media (max-width: ${BreakPoint.MobileTop}) {
          ${gridStyles.mobile};
        }
      `;
    }

    return css`
      ${basic};
      @media (max-width: ${BreakPoint.DesktopLow}) {
        padding: 16px;
      }

      @media (max-width: ${BreakPoint.MobileTop}) {
        ${gridStyles.mobile};
      }
    `;
  }}
`;

export const LimitedUptimeHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 12px;
  grid-area: title;

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-content: space-between;
  }
`;

export const Title = styled.h4`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  color: #272727;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const FirstButtonWrapper = styled.div`
  align-self: center;
  grid-area: firstButton;
  justify-self: end;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin: 8px 0;
    justify-self: center;

    button {
      width: 100%;
    }
  }
`;

export const SecondButtonWrapper = styled.div`
  grid-area: secondButton;
  justify-self: start;
  width: 100%;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-top: 4px;
    justify-self: center;

    button {
      width: 100%;
    }
  }
`;

export const LimitedUptimeDescription = styled.p`
  grid-area: description;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-bottom: 8px;
  }
`;

export const DescriptionText = styled(SText)`
  display: block;
  line-height: 22px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;
