import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #f4f4f7;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;

  & svg {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
  }
`;
