import React from 'react';
import { ReactComponent as HeaderLogo } from 'assets/icons/logo-icons/header-logo.svg';
import { ReactComponent as MenuBurger } from 'assets/icons/menu-icons/menu-burger.svg';
import { ReactComponent as MenuCross } from 'assets/icons/menu-icons/menu-cross.svg';

import { Profile } from 'components/ui/Profile';
import { AppRoute } from 'utils/route/app-route';

import * as S from './InnerHeader.styled';

export interface IInnerHeaderProps {
  isMainMenuOpened: boolean;
  handleMainMenuOpen: () => void;
}

export const InnerHeader: React.FC<IInnerHeaderProps> = ({
  handleMainMenuOpen,
  isMainMenuOpened,
}) => {
  return (
    <>
      <S.MenuButton
        variant="borderless"
        icon={isMainMenuOpened ? <MenuCross /> : <MenuBurger />}
        onClick={handleMainMenuOpen}
      />
      <S.LogoLink to={AppRoute.Home()}>
        <HeaderLogo />
      </S.LogoLink>
      <S.ProfileLink to={AppRoute.Profile()}>
        <Profile variant="header" />
      </S.ProfileLink>
    </>
  );
};
