export const getBrowserName = (): string => {
  const userAgent = navigator.userAgent;

  switch (true) {
    case userAgent.includes('Firefox'):
      return 'Firefox';
    case userAgent.includes('OPR'):
    case userAgent.includes('Opera'):
      return 'Opera';
    case userAgent.includes('Edge'):
      return 'Edge';
    case userAgent.includes('Chrome'):
    case userAgent.includes('Chromium'):
    case userAgent.includes('CriOS'):
      return 'Chrome';
    case userAgent.includes('Safari'):
      return 'Safari';
    case userAgent.includes('MSIE'):
    case userAgent.includes('Trident/'):
      return 'Internet Explorer';
    default:
      return 'Unknown';
  }
};
