import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as KeyIcon } from 'assets/icons/button-icons/key.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/button-icons/shield.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button-icons/trash.svg';
import { ReactComponent as LockIcon } from 'assets/icons/profile-page-icons/lock.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/profile-page-icons/warning-triangle.svg';
import { setPasswordChangeStepAction } from 'store/auth-reducer/auth.reducer';
import {
  requestEnable2FAThunkAction,
  requestLogoutThunkAction,
  requestPasswordChangeCodeThunkAction,
} from 'store/auth-reducer/auth.thunk-actions';
import { openEnable2FAPopupAction } from 'store/modals-reducer/modals.reducer';
import { selectProfile, selectProfileStatus } from 'store/profile-reducer/profile.selectors';
import { useAppDispatch } from 'store/store';

import { AppRoute } from 'utils/route/app-route';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';
import { setEmail } from 'utils/storage/email/set-email.util';

import * as S from './SecuritySettings.styled';

export const SecuritySettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, is2FAEnabled } = useSelector(selectProfile);
  const profileFetchStatus = useSelector(selectProfileStatus);
  const isProfileDataPending = profileFetchStatus === 'pending';

  const handleChangePassword = () => {
    void dispatch(
      requestLogoutThunkAction({
        onSuccessCb: () => {
          navigate(AppRoute.PasswordReset());
        },
      }),
    );
    setEmail(email);
    setEmailSendTime();
    dispatch(setPasswordChangeStepAction('confirm-code'));
    void dispatch(
      requestPasswordChangeCodeThunkAction({
        email,
        onSuccessCb: () => null,
      }),
    );
  };

  const handleConnectGoogleAuth = () => {
    dispatch(openEnable2FAPopupAction());
    void dispatch(requestEnable2FAThunkAction());
  };

  const handleDisconnectGoogleAuth = () => navigate(AppRoute.Disable2FA());

  return (
    <S.Container>
      <S.SettingsItem>
        <S.SettingsTextWrapper>
          <S.SettingsItemTitle as="h2">Change password</S.SettingsItemTitle>
          <S.SettingsItemParagraph>
            <S.SettingsItemText>
              We do not recommend using passwords from other sites or words that are easy to figure
              out.
            </S.SettingsItemText>
          </S.SettingsItemParagraph>
        </S.SettingsTextWrapper>

        <S.SettingsButton
          variant="bordered"
          text="Change password"
          icon={<KeyIcon />}
          onClick={handleChangePassword}
        />
      </S.SettingsItem>

      <S.SettingsSection>
        <S.SectionHeader>
          <S.SectionTitle>Two-factor authentication</S.SectionTitle>
          <S.WarningBanner>
            <WarningIcon />
            We strongly recommend enabling this option!
          </S.WarningBanner>
        </S.SectionHeader>

        <S.SettingsItem>
          <S.SettingsTextWrapper>
            <S.SettingsItemTitle>Google Authenticator</S.SettingsItemTitle>
            <S.SettingsItemParagraph>
              <S.SettingsItemText>
                Two-factor authentication via Google Authenticator is available for Android or iOS
                devices.
              </S.SettingsItemText>
              <S.SettingsItemText>
                ATTENTION: If you lose your device, you will not be able to access your account
                without assistance.
              </S.SettingsItemText>
            </S.SettingsItemParagraph>
          </S.SettingsTextWrapper>

          <S.AuthButtonWrapper>
            {is2FAEnabled ? (
              <>
                <S.GoogleAuthAddedBadge>
                  <LockIcon />
                  Google Authenticator added
                </S.GoogleAuthAddedBadge>
                <S.DisableGoogleAuthButton
                  variant="bordered"
                  text="Disable"
                  icon={<DeleteIcon />}
                  onClick={handleDisconnectGoogleAuth}
                  disabled={isProfileDataPending}
                />
              </>
            ) : (
              <S.SettingsButton
                variant="bordered"
                text="Connect Google Authenticator"
                icon={<ShieldIcon />}
                onClick={handleConnectGoogleAuth}
                disabled={isProfileDataPending}
              />
            )}
          </S.AuthButtonWrapper>
        </S.SettingsItem>
      </S.SettingsSection>
    </S.Container>
  );
};
