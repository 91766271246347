import React, { useEffect } from 'react';
import { ReactComponent as QuestionIcon } from 'assets/icons/button-icons/question.svg';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { useUptime } from 'hooks/useUptime';
import { LimitedUptimeSetButton } from 'pages/PageAssets/components/ui/LimitedUptimeSetButton';
import { UptimeStatusBadge } from 'pages/PageAssets/components/ui/UptimeStatusBadge';
import { openLimitedUptimeExplainerPopupAction } from 'store/modals-reducer/modals.reducer';
import { useAppDispatch } from 'store/store';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Button } from 'components/ui/Button';

import {
  HOW_IT_WORKS,
  LIMITED_UPTIME_DESCRIPTION_PART1,
  LIMITED_UPTIME_DESCRIPTION_PART2,
  LIMITED_UPTIME_TITLE,
} from './constants/ui-text.const';

import * as S from './LimitedUptimeSection.styled';

export const LimitedUptimeSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { innerWidth } = useInnerWidth();
  const isMobileDevice = innerWidth <= parseInt(BreakPoint.TabletTop);

  const openUptimeLimitPopup = () => dispatch(openLimitedUptimeExplainerPopupAction());
  const {
    cooldown,
    uptime,
    uptimeStatus,
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,
  } = useUptime();

  useEffect(() => {
    void dispatch(requestUptimeThunkAction());
  }, [dispatch]);

  return (
    <S.LimitedUptimeSection $isCooldownActive={cooldown}>
      <S.LimitedUptimeHeader>
        <S.Title>{LIMITED_UPTIME_TITLE}</S.Title>
        <UptimeStatusBadge isEnabled={uptime} />
      </S.LimitedUptimeHeader>
      <S.FirstButtonWrapper>
        <Button
          variant={isMobileDevice ? 'borderless' : 'secondary'}
          text={HOW_IT_WORKS}
          icon={<QuestionIcon />}
          onClick={openUptimeLimitPopup}
          svgProps={{
            svgFill: 'transparent',
            focusSvgFill: 'transparent',
          }}
        />
      </S.FirstButtonWrapper>
      <S.SecondButtonWrapper>
        <LimitedUptimeSetButton
          uptime={uptime}
          uptimeStatus={uptimeStatus}
          cooldown={cooldown}
          onEnable={handleLimitedUptimeEnabled}
          onDisable={handleLimitedUptimeDisabled}
        />
      </S.SecondButtonWrapper>
      <S.LimitedUptimeDescription>
        <S.DescriptionText>{LIMITED_UPTIME_DESCRIPTION_PART1}</S.DescriptionText>
        <S.DescriptionText>{LIMITED_UPTIME_DESCRIPTION_PART2}</S.DescriptionText>
      </S.LimitedUptimeDescription>
    </S.LimitedUptimeSection>
  );
};
