import { ITimezone } from 'pages/PageProfile/types/timezone.interface';

export const aryIannaTimeZones: ITimezone[] = [
  { name: 'Africa/Abidjan', gmt: '0', sign: '+' },
  { name: 'Africa/Accra', gmt: '0', sign: '+' },
  { name: 'Africa/Algiers', gmt: '1', sign: '+' },
  { name: 'Africa/Bissau', gmt: '0', sign: '+' },
  { name: 'Africa/Cairo', gmt: '2', sign: '+' },
  { name: 'Africa/Casablanca', gmt: '1', sign: '+' },
  { name: 'Africa/Ceuta', gmt: '1', sign: '+' },
  { name: 'Africa/El_Aaiun', gmt: '1', sign: '+' },
  { name: 'Africa/Johannesburg', gmt: '2', sign: '+' },
  { name: 'Africa/Juba', gmt: '3', sign: '+' },
  { name: 'Africa/Khartoum', gmt: '3', sign: '+' },
  { name: 'Africa/Lagos', gmt: '1', sign: '+' },
  { name: 'Africa/Maputo', gmt: '2', sign: '+' },
  { name: 'Africa/Monrovia', gmt: '0', sign: '+' },
  { name: 'Africa/Nairobi', gmt: '3', sign: '+' },
  { name: 'Africa/Ndjamena', gmt: '1', sign: '+' },
  { name: 'Africa/Sao_Tome', gmt: '0', sign: '+' },
  { name: 'Africa/Tripoli', gmt: '2', sign: '+' },
  { name: 'Africa/Tunis', gmt: '1', sign: '+' },
  { name: 'Africa/Windhoek', gmt: '2', sign: '+' },
  { name: 'America/Adak', gmt: '10', sign: '-' },
  { name: 'America/Anchorage', gmt: '9', sign: '-' },
  { name: 'America/Araguaina', gmt: '4', sign: '-' },
  { name: 'America/Argentina/Buenos_Aires', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Catamarca', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Cordoba', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Jujuy', gmt: '3', sign: '-' },
  { name: 'America/Argentina/La_Rioja', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Mendoza', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Rio_Gallegos', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Salta', gmt: '3', sign: '-' },
  { name: 'America/Argentina/San_Juan', gmt: '3', sign: '-' },
  { name: 'America/Argentina/San_Luis', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Tucuman', gmt: '3', sign: '-' },
  { name: 'America/Argentina/Ushuaia', gmt: '3', sign: '-' },
  { name: 'America/Asuncion', gmt: '4', sign: '-' },
  { name: 'America/Atikokan', gmt: '5', sign: '-' },
  { name: 'America/Bahia', gmt: '3', sign: '-' },
  { name: 'America/Bahia_Banderas', gmt: '6', sign: '-' },
  { name: 'America/Barbados', gmt: '4', sign: '-' },
  { name: 'America/Belem', gmt: '3', sign: '-' },
  { name: 'America/Belize', gmt: '6', sign: '-' },
  { name: 'America/Blanc-Sablon', gmt: '4', sign: '-' },
  { name: 'America/Boa_Vista', gmt: '4', sign: '-' },
  { name: 'America/Bogota', gmt: '5', sign: '-' },
  { name: 'America/Boise', gmt: '7', sign: '-' },
  { name: 'America/Cambridge_Bay', gmt: '7', sign: '-' },
  { name: 'America/Campo_Grande', gmt: '4', sign: '-' },
  { name: 'America/Cancun', gmt: '5', sign: '-' },
  { name: 'America/Caracas', gmt: '4', sign: '-' },
  { name: 'America/Cayenne', gmt: '3', sign: '-' },
  { name: 'America/Chicago', gmt: '6', sign: '-' },
  { name: 'America/Chihuahua', gmt: '7', sign: '-' },
  { name: 'America/Costa_Rica', gmt: '6', sign: '-' },
  { name: 'America/Creston', gmt: '7', sign: '-' },
  { name: 'America/Cuiaba', gmt: '4', sign: '-' },
  { name: 'America/Curacao', gmt: '4', sign: '-' },
  { name: 'America/Danmarkshavn', gmt: '0', sign: '+' },
  { name: 'America/Dawson', gmt: '8', sign: '-' },
  { name: 'America/Dawson_Creek', gmt: '7', sign: '-' },
  { name: 'America/Denver', gmt: '7', sign: '-' },
  { name: 'America/Detroit', gmt: '5', sign: '-' },
  { name: 'America/Edmonton', gmt: '7', sign: '-' },
  { name: 'America/Eirunepe', gmt: '5', sign: '-' },
  { name: 'America/El_Salvador', gmt: '6', sign: '-' },
  { name: 'America/Fort_Nelson', gmt: '7', sign: '-' },
  { name: 'America/Fortaleza', gmt: '3', sign: '-' },
  { name: 'America/Glace_Bay', gmt: '4', sign: '-' },
  { name: 'America/Godthab', gmt: '3', sign: '-' },
  { name: 'America/Goose_Bay', gmt: '4', sign: '-' },
  { name: 'America/Grand_Turk', gmt: '5', sign: '-' },
  { name: 'America/Guatemala', gmt: '6', sign: '-' },
  { name: 'America/Guayaquil', gmt: '5', sign: '-' },
  { name: 'America/Guyana', gmt: '4', sign: '-' },
  { name: 'America/Halifax', gmt: '4', sign: '-' },
  { name: 'America/Havana', gmt: '5', sign: '-' },
  { name: 'America/Hermosillo', gmt: '7', sign: '-' },
  { name: 'America/Indiana/Indianapolis', gmt: '5', sign: '-' },
  { name: 'America/Indiana/Knox', gmt: '5', sign: '-' },
  { name: 'America/Indiana/Marengo', gmt: '5', sign: '-' },
  { name: 'America/Indiana/Petersburg', gmt: '5', sign: '-' },
  { name: 'America/Indiana/Tell_City', gmt: '6', sign: '-' },
  { name: 'America/Indiana/Vevay', gmt: '5', sign: '-' },
  { name: 'America/Indiana/Vincennes', gmt: '5', sign: '-' },
  { name: 'America/Indiana/Winamac', gmt: '5', sign: '-' },
  { name: 'America/Inuvik', gmt: '7', sign: '-' },
  { name: 'America/Iqaluit', gmt: '5', sign: '-' },
  { name: 'America/Jamaica', gmt: '5', sign: '-' },
  { name: 'America/Juneau', gmt: '9', sign: '-' },
  { name: 'America/Kentucky/Louisville', gmt: '5', sign: '-' },
  { name: 'America/Kentucky/Monticello', gmt: '5', sign: '-' },
  { name: 'America/La_Paz', gmt: '4', sign: '-' },
  { name: 'America/Lima', gmt: '5', sign: '-' },
  { name: 'America/Los_Angeles', gmt: '8', sign: '-' },
  { name: 'America/Maceio', gmt: '3', sign: '-' },
  { name: 'America/Managua', gmt: '6', sign: '-' },
  { name: 'America/Manaus', gmt: '4', sign: '-' },
  { name: 'America/Martinique', gmt: '4', sign: '-' },
  { name: 'America/Matamoros', gmt: '6', sign: '-' },
  { name: 'America/Mazatlan', gmt: '7', sign: '-' },
  { name: 'America/Menominee', gmt: '6', sign: '-' },
  { name: 'America/Merida', gmt: '6', sign: '-' },
  { name: 'America/Metlakatla', gmt: '9', sign: '-' },
  { name: 'America/Mexico_City', gmt: '6', sign: '-' },
  { name: 'America/Miquelon', gmt: '3', sign: '-' },
  { name: 'America/Moncton', gmt: '4', sign: '-' },
  { name: 'America/Monterrey', gmt: '6', sign: '-' },
  { name: 'America/Montevideo', gmt: '3', sign: '-' },
  { name: 'America/Nassau', gmt: '5', sign: '-' },
  { name: 'America/New_York', gmt: '5', sign: '-' },
  { name: 'America/Nipigon', gmt: '5', sign: '-' },
  { name: 'America/Nome', gmt: '9', sign: '-' },
  { name: 'America/Noronha', gmt: '2', sign: '-' },
  { name: 'America/North_Dakota/Beulah', gmt: '5', sign: '-' },
  { name: 'America/North_Dakota/Center', gmt: '6', sign: '-' },
  { name: 'America/North_Dakota/New_Salem', gmt: '6', sign: '-' },
  { name: 'America/Ojinaga', gmt: '7', sign: '-' },
  { name: 'America/Panama', gmt: '5', sign: '-' },
  { name: 'America/Pangnirtung', gmt: '5', sign: '-' },
  { name: 'America/Paramaribo', gmt: '3', sign: '-' },
  { name: 'America/Phoenix', gmt: '7', sign: '-' },
  { name: 'America/Port-au-Prince', gmt: '5', sign: '-' },
  { name: 'America/Port_of_Spain', gmt: '4', sign: '-' },
  { name: 'America/Porto_Velho', gmt: '4', sign: '-' },
  { name: 'America/Puerto_Rico', gmt: '4', sign: '-' },
  { name: 'America/Punta_Arenas', gmt: '3', sign: '-' },
  { name: 'America/Rainy_River', gmt: '6', sign: '-' },
  { name: 'America/Rankin_Inlet', gmt: '6', sign: '-' },
  { name: 'America/Recife', gmt: '3', sign: '-' },
  { name: 'America/Regina', gmt: '6', sign: '-' },
  { name: 'America/Resolute', gmt: '6', sign: '-' },
  { name: 'America/Rio_Branco', gmt: '5', sign: '-' },
  { name: 'America/Santarem', gmt: '3', sign: '-' },
  { name: 'America/Santiago', gmt: '4', sign: '-' },
  { name: 'America/Santo_Domingo', gmt: '4', sign: '-' },
  { name: 'America/Sao_Paulo', gmt: '3', sign: '-' },
  { name: 'America/Scoresbysund', gmt: '1', sign: '-' },
  { name: 'America/Sitka', gmt: '9', sign: '-' },
  { name: 'America/St_Johns', gmt: '3', sign: '-' },
  { name: 'America/Swift_Current', gmt: '6', sign: '-' },
  { name: 'America/Tegucigalpa', gmt: '6', sign: '-' },
  { name: 'America/Thule', gmt: '4', sign: '-' },
  { name: 'America/Thunder_Bay', gmt: '5', sign: '-' },
  { name: 'America/Tijuana', gmt: '8', sign: '-' },
  { name: 'America/Toronto', gmt: '5', sign: '-' },
  { name: 'America/Vancouver', gmt: '8', sign: '-' },
  { name: 'America/Whitehorse', gmt: '8', sign: '-' },
  { name: 'America/Winnipeg', gmt: '6', sign: '-' },
  { name: 'America/Yakutat', gmt: '9', sign: '-' },
  { name: 'America/Yellowknife', gmt: '7', sign: '-' },
  { name: 'Antarctica/Casey', gmt: '8', sign: '+' },
  { name: 'Antarctica/Davis', gmt: '7', sign: '+' },
  { name: 'Antarctica/DumontDUrville', gmt: '10', sign: '+' },
  { name: 'Antarctica/Macquarie', gmt: '11', sign: '+' },
  { name: 'Antarctica/Mawson', gmt: '5', sign: '+' },
  { name: 'Antarctica/Palmer', gmt: '4', sign: '-' },
  { name: 'Antarctica/Rothera', gmt: '3', sign: '-' },
  { name: 'Antarctica/Syowa', gmt: '3', sign: '+' },
  { name: 'Antarctica/Troll', gmt: '0', sign: '+' },
  { name: 'Antarctica/Vostok', gmt: '6', sign: '+' },
  { name: 'Asia/Almaty', gmt: '6', sign: '+' },
  { name: 'Asia/Amman', gmt: '2', sign: '+' },
  { name: 'Asia/Anadyr', gmt: '12', sign: '+' },
  { name: 'Asia/Aqtau', gmt: '5', sign: '+' },
  { name: 'Asia/Aqtobe', gmt: '5', sign: '+' },
  { name: 'Asia/Ashgabat', gmt: '5', sign: '+' },
  { name: 'Asia/Atyrau', gmt: '5', sign: '+' },
  { name: 'Asia/Baghdad', gmt: '3', sign: '+' },
  { name: 'Asia/Baku', gmt: '4', sign: '+' },
  { name: 'Asia/Bangkok', gmt: '7', sign: '+' },
  { name: 'Asia/Barnaul', gmt: '7', sign: '+' },
  { name: 'Asia/Beirut', gmt: '2', sign: '+' },
  { name: 'Asia/Bishkek', gmt: '6', sign: '+' },
  { name: 'Asia/Brunei', gmt: '8', sign: '+' },
  { name: 'Asia/Chita', gmt: '9', sign: '+' },
  { name: 'Asia/Choibalsan', gmt: '8', sign: '+' },
  { name: 'Asia/Colombo', gmt: '5:30', sign: '+' },
  { name: 'Asia/Damascus', gmt: '2', sign: '+' },
  { name: 'Asia/Dhaka', gmt: '6', sign: '+' },
  { name: 'Asia/Dili', gmt: '9', sign: '+' },
  { name: 'Asia/Dubai', gmt: '4', sign: '+' },
  { name: 'Asia/Dushanbe', gmt: '5', sign: '+' },
  { name: 'Asia/Famagusta', gmt: '2', sign: '+' },
  { name: 'Asia/Gaza', gmt: '2', sign: '+' },
  { name: 'Asia/Hebron', gmt: '2', sign: '+' },
  { name: 'Asia/Ho_Chi_Minh', gmt: '7', sign: '+' },
  { name: 'Asia/Hong_Kong', gmt: '8', sign: '+' },
  { name: 'Asia/Hovd', gmt: '7', sign: '+' },
  { name: 'Asia/Irkutsk', gmt: '8', sign: '+' },
  { name: 'Asia/Jakarta', gmt: '7', sign: '+' },
  { name: 'Asia/Jayapura', gmt: '9', sign: '+' },
  { name: 'Asia/Jerusalem', gmt: '2', sign: '+' },
  { name: 'Asia/Kabul', gmt: '4:30', sign: '+' },
  { name: 'Asia/Kamchatka', gmt: '12', sign: '+' },
  { name: 'Asia/Karachi', gmt: '5', sign: '+' },
  { name: 'Asia/Kathmandu', gmt: '5:45', sign: '+' },
  { name: 'Asia/Khandyga', gmt: '9', sign: '+' },
  { name: 'Asia/Kolkata', gmt: '5:30', sign: '+' },
  { name: 'Asia/Krasnoyarsk', gmt: '7', sign: '+' },
  { name: 'Asia/Kuala_Lumpur', gmt: '8', sign: '+' },
  { name: 'Asia/Kuching', gmt: '8', sign: '+' },
  { name: 'Asia/Macau', gmt: '8', sign: '+' },
  { name: 'Asia/Magadan', gmt: '11', sign: '+' },
  { name: 'Asia/Makassar', gmt: '8', sign: '+' },
  { name: 'Asia/Manila', gmt: '8', sign: '+' },
  { name: 'Asia/Nicosia', gmt: '2', sign: '+' },
  { name: 'Asia/Novokuznetsk', gmt: '7', sign: '+' },
  { name: 'Asia/Novosibirsk', gmt: '7', sign: '+' },
  { name: 'Asia/Omsk', gmt: '7', sign: '+' },
  { name: 'Asia/Oral', gmt: '5', sign: '+' },
  { name: 'Asia/Pontianak', gmt: '7', sign: '+' },
  { name: 'Asia/Pyongyang', gmt: '9', sign: '+' },
  { name: 'Asia/Qatar', gmt: '3', sign: '+' },
  { name: 'Asia/Qostanay', gmt: '6', sign: '+' },
  { name: 'Asia/Qyzylorda', gmt: '6', sign: '+' },
  { name: 'Asia/Riyadh', gmt: '3', sign: '+' },
  { name: 'Asia/Sakhalin', gmt: '11', sign: '+' },
  { name: 'Asia/Samarkand', gmt: '5', sign: '+' },
  { name: 'Asia/Seoul', gmt: '9', sign: '+' },
  { name: 'Asia/Shanghai', gmt: '8', sign: '+' },
  { name: 'Asia/Singapore', gmt: '8', sign: '+' },
  { name: 'Asia/Srednekolymsk', gmt: '11', sign: '+' },
  { name: 'Asia/Taipei', gmt: '8', sign: '+' },
  { name: 'Asia/Tashkent', gmt: '5', sign: '+' },
  { name: 'Asia/Tbilisi', gmt: '4', sign: '+' },
  { name: 'Asia/Tehran', gmt: '3:30', sign: '+' },
  { name: 'Asia/Thimphu', gmt: '6', sign: '+' },
  { name: 'Asia/Tokyo', gmt: '9', sign: '+' },
  { name: 'Asia/Tomsk', gmt: '7', sign: '+' },
  { name: 'Asia/Ulaanbaatar', gmt: '8', sign: '+' },
  { name: 'Asia/Urumqi', gmt: '6', sign: '+' },
  { name: 'Asia/Ust-Nera', gmt: '10', sign: '+' },
  { name: 'Asia/Vladivostok', gmt: '10', sign: '+' },
  { name: 'Asia/Yakutsk', gmt: '9', sign: '+' },
  { name: 'Asia/Yangon', gmt: '6:30', sign: '+' },
  { name: 'Asia/Yekaterinburg', gmt: '5', sign: '+' },
  { name: 'Asia/Yerevan', gmt: '4', sign: '+' },
  { name: 'Atlantic/Azores', gmt: '1', sign: '-' },
  { name: 'Atlantic/Bermuda', gmt: '4', sign: '-' },
  { name: 'Atlantic/Canary', gmt: '0', sign: '+' },
  { name: 'Atlantic/Cape_Verde', gmt: '1', sign: '-' },
  { name: 'Atlantic/Faroe', gmt: '0', sign: '+' },
  { name: 'Atlantic/Madeira', gmt: '0', sign: '+' },
  { name: 'Atlantic/Reykjavik', gmt: '0', sign: '+' },
  { name: 'Atlantic/South_Georgia', gmt: '2', sign: '-' },
  { name: 'Atlantic/Stanley', gmt: '3', sign: '-' },
  { name: 'Australia/Adelaide', gmt: '9:30', sign: '+' },
  { name: 'Australia/Brisbane', gmt: '10', sign: '+' },
  { name: 'Australia/Broken_Hill', gmt: '9:30', sign: '+' },
  { name: 'Australia/Currie', gmt: '10', sign: '+' },
  { name: 'Australia/Darwin', gmt: '9:30', sign: '+' },
  { name: 'Australia/Eucla', gmt: '8:45', sign: '+' },
  { name: 'Australia/Hobart', gmt: '10', sign: '+' },
  { name: 'Australia/Lindeman', gmt: '10', sign: '+' },
  { name: 'Australia/Lord_Howe', gmt: '10:30', sign: '+' },
  { name: 'Australia/Melbourne', gmt: '10', sign: '+' },
  { name: 'Australia/Perth', gmt: '8', sign: '+' },
  { name: 'Australia/Sydney', gmt: '10', sign: '+' },
  { name: 'Europe/Amsterdam', gmt: '1', sign: '+' },
  { name: 'Europe/Andorra', gmt: '1', sign: '+' },
  { name: 'Europe/Astrakhan', gmt: '4', sign: '+' },
  { name: 'Europe/Athens', gmt: '2', sign: '+' },
  { name: 'Europe/Belgrade', gmt: '1', sign: '+' },
  { name: 'Europe/Berlin', gmt: '1', sign: '+' },
  { name: 'Europe/Brussels', gmt: '1', sign: '+' },
  { name: 'Europe/Bucharest', gmt: '2', sign: '+' },
  { name: 'Europe/Budapest', gmt: '1', sign: '+' },
  { name: 'Europe/Chisinau', gmt: '2', sign: '+' },
  { name: 'Europe/Copenhagen', gmt: '1', sign: '+' },
  { name: 'Europe/Dublin', gmt: '0', sign: '+' },
  { name: 'Europe/Gibraltar', gmt: '1', sign: '+' },
  { name: 'Europe/Helsinki', gmt: '2', sign: '+' },
  { name: 'Europe/Istanbul', gmt: '3', sign: '+' },
  { name: 'Europe/Kaliningrad', gmt: '2', sign: '+' },
  { name: 'Europe/Kiev', gmt: '2', sign: '+' },
  { name: 'Europe/Kirov', gmt: '3', sign: '+' },
  { name: 'Europe/Lisbon', gmt: '0', sign: '+' },
  { name: 'Europe/London', gmt: '0', sign: '+' },
  { name: 'Europe/Luxembourg', gmt: '1', sign: '+' },
  { name: 'Europe/Madrid', gmt: '1', sign: '+' },
  { name: 'Europe/Malta', gmt: '1', sign: '+' },
  { name: 'Europe/Minsk', gmt: '3', sign: '+' },
  { name: 'Europe/Monaco', gmt: '1', sign: '+' },
  { name: 'Europe/Moscow', gmt: '3', sign: '+' },
  { name: 'Europe/Oslo', gmt: '1', sign: '+' },
  { name: 'Europe/Paris', gmt: '1', sign: '+' },
  { name: 'Europe/Prague', gmt: '1', sign: '+' },
  { name: 'Europe/Riga', gmt: '2', sign: '+' },
  { name: 'Europe/Rome', gmt: '1', sign: '+' },
  { name: 'Europe/Samara', gmt: '4', sign: '+' },
  { name: 'Europe/Saratov', gmt: '4', sign: '+' },
  { name: 'Europe/Simferopol', gmt: '3', sign: '+' },
  { name: 'Europe/Sofia', gmt: '2', sign: '+' },
  { name: 'Europe/Stockholm', gmt: '1', sign: '+' },
  { name: 'Europe/Tallinn', gmt: '2', sign: '+' },
  { name: 'Europe/Tirane', gmt: '1', sign: '+' },
  { name: 'Europe/Ulyanovsk', gmt: '4', sign: '+' },
  { name: 'Europe/Uzhgorod', gmt: '2', sign: '+' },
  { name: 'Europe/Vienna', gmt: '1', sign: '+' },
  { name: 'Europe/Vilnius', gmt: '2', sign: '+' },
  { name: 'Europe/Volgograd', gmt: '3', sign: '+' },
  { name: 'Europe/Warsaw', gmt: '1', sign: '+' },
  { name: 'Europe/Zaporozhye', gmt: '2', sign: '+' },
  { name: 'Europe/Zurich', gmt: '1', sign: '+' },
  { name: 'Indian/Chagos', gmt: '6', sign: '+' },
  { name: 'Indian/Christmas', gmt: '7', sign: '+' },
  { name: 'Indian/Cocos', gmt: '6:30', sign: '+' },
  { name: 'Indian/Kerguelen', gmt: '5', sign: '+' },
  { name: 'Indian/Mahe', gmt: '4', sign: '+' },
  { name: 'Indian/Maldives', gmt: '5', sign: '+' },
  { name: 'Indian/Mauritius', gmt: '4', sign: '+' },
  { name: 'Indian/Reunion', gmt: '4', sign: '+' },
  { name: 'Pacific/Apia', gmt: '13', sign: '+' },
  { name: 'Pacific/Auckland', gmt: '12', sign: '+' },
  { name: 'Pacific/Bougainville', gmt: '11', sign: '+' },
  { name: 'Pacific/Chatham', gmt: '12:45', sign: '+' },
  { name: 'Pacific/Chuuk', gmt: '10', sign: '+' },
  { name: 'Pacific/Easter', gmt: '6', sign: '-' },
  { name: 'Pacific/Efate', gmt: '11', sign: '+' },
  { name: 'Pacific/Enderbury', gmt: '13', sign: '+' },
  { name: 'Pacific/Fakaofo', gmt: '13', sign: '+' },
  { name: 'Pacific/Fiji', gmt: '12', sign: '+' },
  { name: 'Pacific/Funafuti', gmt: '12', sign: '+' },
  { name: 'Pacific/Galapagos', gmt: '6', sign: '-' },
  { name: 'Pacific/Gambier', gmt: '9', sign: '-' },
  { name: 'Pacific/Guadalcanal', gmt: '11', sign: '+' },
  { name: 'Pacific/Guam', gmt: '10', sign: '+' },
  { name: 'Pacific/Honolulu', gmt: '10', sign: '-' },
  { name: 'Pacific/Kiritimati', gmt: '14', sign: '+' },
  { name: 'Pacific/Kosrae', gmt: '11', sign: '+' },
  { name: 'Pacific/Kwajalein', gmt: '12', sign: '+' },
  { name: 'Pacific/Majuro', gmt: '12', sign: '+' },
  { name: 'Pacific/Marquesas', gmt: '9:30', sign: '-' },
  { name: 'Pacific/Nauru', gmt: '12', sign: '+' },
  { name: 'Pacific/Niue', gmt: '11', sign: '-' },
  { name: 'Pacific/Norfolk', gmt: '11', sign: '+' },
  { name: 'Pacific/Noumea', gmt: '11', sign: '+' },
  { name: 'Pacific/Pago_Pago', gmt: '11', sign: '-' },
  { name: 'Pacific/Palau', gmt: '9', sign: '+' },
  { name: 'Pacific/Pitcairn', gmt: '8', sign: '-' },
  { name: 'Pacific/Pohnpei', gmt: '11', sign: '+' },
  { name: 'Pacific/Port_Moresby', gmt: '10', sign: '+' },
  { name: 'Pacific/Rarotonga', gmt: '10', sign: '-' },
  { name: 'Pacific/Tahiti', gmt: '10', sign: '-' },
  { name: 'Pacific/Tarawa', gmt: '12', sign: '+' },
  { name: 'Pacific/Tongatapu', gmt: '13', sign: '+' },
  { name: 'Pacific/Wake', gmt: '13', sign: '+' },
  { name: 'Pacific/Wallis', gmt: '12', sign: '+' },
];
