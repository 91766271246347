import React from 'react';

import { ISuccessDescriptionProps } from './types/success-description-props.interface';

import * as S from './SuccessDescription.styled';

export const SuccessDescription: React.FC<ISuccessDescriptionProps> = ({ variant }) => {
  switch (variant) {
    case 'password-reset':
      return (
        <S.DescriptionTextCentered>
          Now you can log in the Dashboard with new password
        </S.DescriptionTextCentered>
      );
    case 'registration':
      return (
        <>
          <S.DescriptionText>Congratulations! You have registered on Cuverse</S.DescriptionText>
          <S.DescriptionText>
            You can manage your assets, replenish and withdraw funds from your balance in the
            control panel
          </S.DescriptionText>
        </>
      );
  }
};
