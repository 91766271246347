import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFiltersState {
  startDate: string | null;
  endDate: string | null;
  transactionType: string | null;
}

const initialState: IFiltersState = {
  startDate: null,
  endDate: null,
  transactionType: null,
};

const filtersSlice = createSlice({
  name: 'transaction-history-filters',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<IFiltersState>) {
      return { ...state, ...action.payload };
    },
    resetFilters(state) {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersSlice.actions;

export const transactionFiltersHistoryReducer = filtersSlice.reducer;
