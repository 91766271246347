import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

export const FooterLinksWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  padding-top: 12px;
  border-top: 1px solid #c9c9c9;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
  padding: 8px 24px;
  border: none;
  background-color: transparent;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
  transition: background-color ${TransitionTime.Hover};
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: #f4f4f7;
    color: #272727;
    transition: background-color ${TransitionTime.Hover};
    outline: none;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    display: flex;
    align-items: center;
    width: auto;
    margin-bottom: 0;
    padding: 12px 16px;
    border-radius: 24px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 4px;
    padding: 16px 24px;
  }
`;

export const MainSiteLink = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 8px 24px;
  background-color: #eef3fa;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  color: #272727;
  transition: background-color ${TransitionTime.Hover};

  &:focus-visible {
    outline: none;
  }

  &:hover,
  &:focus {
    background-color: #e0e5ed;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    width: max-content;
    min-height: 42px;
    padding: 12px 16px;
    border-radius: 24px;
    white-space: nowrap;
  }
`;
