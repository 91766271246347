export const FallbackAuthErrorMessage = {
  IncorrectEmail: 'Email address is incorrect',
  IncorrectOtp: 'Incorrect code',
  FailedToResendEmail: 'Failed to resend email',
  FailedToVerifyEmail: 'Failed to verify, email or code is incorrect',
  FailedToRegister: 'Failed to register',
  SessionExpired: 'Your session has expired, please log in again',
  SomethingWentWrong: 'Something went wrong',
  CannotLoad2FACode: 'Cannot load 2FA code',
  CannotSignInWithGoogle: 'Cannot sign in with Google',
};
