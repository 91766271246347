import React from 'react';

import { ICashbackButtonProps } from './types/cashback-button-props.interface';

import * as S from './CashbackButton.styled';

export const CashbackButton: React.FC<ICashbackButtonProps> = ({
  cashbackText,
  handleCashbackButtonClick,
}) => {
  return (
    <S.CashbackButton
      type="button"
      variant="alter-primary"
      text={cashbackText}
      onClick={handleCashbackButtonClick}
    />
  );
};
