import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { App } from './components/logic/App';
import { ToastProvider } from './components/logic/ToastProvider';
import { setUserAuthStatusAction } from './store/auth-reducer/auth.reducer';
import { requestTokenProlongationThunkAction } from './store/auth-reducer/auth.thunk-actions';
import { store } from './store/store';
import { GlobalStyles } from './styles/GlobalStyles';
import { ResetStyles } from './styles/ResetStyles';
import { StorageKey } from './types/storage/storage-key.enum';
import { getAuthToken } from './utils/storage/auth-token/get-auth-token';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(utc);

localStorage.removeItem(StorageKey.OuterRoute);
const outerRoute = window.location.pathname;
localStorage.setItem(StorageKey.OuterRoute, outerRoute);
const googleClientId = process.env?.REACT_APP_GOOGLE_CLIENT_ID || '';

const token = getAuthToken();
if (token) {
  void store.dispatch(requestTokenProlongationThunkAction());
} else {
  store.dispatch(setUserAuthStatusAction('unauthorized'));
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ResetStyles />
      <GlobalStyles />
      <BrowserRouter>
        <ToastProvider>
          <GoogleOAuthProvider clientId={googleClientId}>
            <App />
          </GoogleOAuthProvider>
        </ToastProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
