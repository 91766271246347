import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchInvoicePdfLinksAction } from './Invoice-pdf-links.thunk-action';

export interface IInvoicePdfLinksState {
  invoicePdfLinksData: string[];
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IInvoicePdfLinksState = {
  invoicePdfLinksData: [],
  status: 'initial',
  error: null,
};

const invoicePdfLinksSlice = createSlice({
  name: 'download/invoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvoicePdfLinksAction.pending, (state) => {
      state.status = 'pending';
      state.error = null;
    });
    builder.addCase(
      fetchInvoicePdfLinksAction.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        state.status = 'fulfilled';
        state.invoicePdfLinksData.push(payload);
      },
    );
    builder.addCase(fetchInvoicePdfLinksAction.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const invoicePdfLinksReducer = invoicePdfLinksSlice.reducer;
