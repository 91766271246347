import styled, { css } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { IFormattedSvgProps } from './Button.types';

const applySvgFillStyles = ({
  $svgFill,
  $activeSvgFill,
  $disabledSvgFill,
  $hoverSvgFill,
  $focusSvgFill,
}: IFormattedSvgProps) => css`
  ${$svgFill &&
  css`
    & svg path {
      fill: ${$svgFill};
    }
  `}

  ${$activeSvgFill &&
  css`
    &:active {

      & svg path {
        fill: ${$activeSvgFill};
      }
    }
  `}

  ${$disabledSvgFill &&
  css`
    &:disabled {

      & svg path {
        fill: ${$disabledSvgFill};
      }
    }
  `}

  ${$hoverSvgFill &&
  css`
    &:hover,
    &:focus {

      & svg path {
        fill: ${$hoverSvgFill};
      }
    }
  `}

  ${$focusSvgFill &&
  css`
    &:focus {

      & svg path {
        fill: ${$focusSvgFill};
      }
    }
  `}
`;

const BasicButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: auto;
  height: auto;
  padding: 7px 12px;
  border: none;
  border-radius: 16px;
  background-color: transparent;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  transition: all ${TransitionTime.Hover};
  cursor: pointer;
  outline: none;

  & svg {
    flex-grow: 0;
    flex-shrink: 0;

    path {
      transition: all ${TransitionTime.Hover};
    }
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    user-select: none;
  }

  &:hover,
  &:focus {
    transition: all ${TransitionTime.Hover};

    & svg path {
      transition: all ${TransitionTime.Hover};
    }
  }

  &:active {
    transition: all ${TransitionTime.Active};

    & svg path {
      transition: all ${TransitionTime.Active};
    }
  }

  &:disabled {
    transition: all ${TransitionTime.Disabled};
    cursor: auto;

    & svg path {
      transition: all ${TransitionTime.Disabled};
    }
  }
`;

export const PrimaryButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      background-color: #0e46c0;
      color: #fff;

      & svg path {
        fill: #fff;
      }

      &:hover,
      &:focus {
        background-color: #03349e;
      }

      &:active {
        background-color: #03349e;
      }

      &:disabled {
        background-color: #a1a1a1;
        color: #c9c9c9;

        & svg path {
          fill: #c9c9c9;
        }
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;

export const PrimaryGrayButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      background-color: #f5f5f5;
      color: #646464;

      & svg path {
        fill: #eaeaea;
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;

export const SecondaryButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      background-color: #e5eaf1;
      color: #0e46c0;

      & svg path {
        fill: #0e46c0;
      }

      &:hover,
      &:focus {
        background-color: #eaeaea;
      }

      &:active {
        background-color: #c9c9c9;
      }

      &:disabled {
        background-color: #e5eaf1;
        color: #a1a1a1;

        & svg path {
          fill: #a1a1a1;
        }
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;

export const BorderlessButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      gap: 2px;
      padding: 0;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #0e46c0;

      & svg path {
        fill: #0e46c0;
      }

      &:hover,
      &:focus {
        color: #6a96f6;

        & svg path {
          fill: #6a96f6;
        }
      }

      &:active {
        color: #03349e;

        & svg path {
          fill: #03349e;
        }
      }

      &:disabled {
        color: #a1a1a1;

        & svg path {
          fill: #a1a1a1;
        }
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;

export const BorderedButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      padding: 6px 12px;
      border: 1px solid #0e46c0;
      color: #0e46c0;

      & svg path {
        fill: #0e46c0;
      }

      &:hover,
      &:focus {
        background-color: #0e46c0;
        color: #fff;

        & svg path {
          fill: #fff;
        }
      }

      &:active {
        border-color: #03349e;
        background-color: #03349e;

        & svg path {
          fill: #fff;
        }
      }

      &:disabled {
        border-color: #a1a1a1;
        background-color: transparent;
        color: #a1a1a1;

        & svg path {
          fill: #a1a1a1;
        }
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;

export const AlterPrimaryButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      background-color: #fff;
      color: #0e46c0;

      & svg path {
        fill: #0e46c0;
      }

      &:hover,
      &:focus {
        background-color: #eaeaea;
      }

      &:active {
        background-color: #c9c9c9;
      }

      &:disabled {
        background-color: #eaeaea;
        color: #a1a1a1;

        & svg path {
          fill: #a1a1a1;
        }
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;

export const AlterBorderedButton = styled(BasicButton)<IFormattedSvgProps>`
  ${({ $svgFill, $activeSvgFill, $disabledSvgFill, $hoverSvgFill, $focusSvgFill }) => {
    const basicStyles = css`
      border-color: #fff;
      color: #fff;

      & svg path {
        fill: #fff;
      }

      &:hover,
      &:focus {
        background-color: #fff;
        color: #0e46c0;

        & svg path {
          fill: #0e46c0;
        }
      }

      &:active {
        border-color: #c9c9c9;
        background-color: #c9c9c9;

        & svg path {
          fill: #0e46c0;
        }
      }

      &:disabled {
        border-color: #fff;
        background-color: transparent;
        opacity: 0.3;
        color: #fff;

        & svg path {
          fill: #fff;
        }
      }
    `;

    return css`
      ${basicStyles}
      ${applySvgFillStyles({
        $svgFill,
        $activeSvgFill,
        $disabledSvgFill,
        $hoverSvgFill,
        $focusSvgFill,
      })}
    `;
  }}
`;
