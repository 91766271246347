import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { HeaderHeight } from 'styles/style-variables/header-height';

export const Wrapper = styled.div`
  display: flex;
  flex: 1 0;
  width: 100%;
  height: 100%;
`;

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${HeaderHeight.Desktop};
  padding: 16px 24px;
  background-color: #3069e5;

  @media (max-width: ${BreakPoint.MobileTop}) {
    min-height: ${HeaderHeight.Mobile};
    padding: 12px 16px;
  }
`;

export const ContentWrapper = styled.main`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: ${HeaderHeight.Desktop};
  padding: 48px 32px 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-top: ${HeaderHeight.Mobile};
    padding: 0 0 32px;
  }
`;
