/**
 * Extracts the value of the `secret` parameter from the given URL.
 *
 * @param {string} url - The URL from which to extract the `secret` parameter.
 * @returns {string} - The value of the `secret` parameter, or an empty string if the parameter is not found.
 */
export const getSecretFromUrl = (url: string): string => {
  const secretMatch = url.match(/secret=([A-Za-z0-9]+)/);
  return secretMatch ? secretMatch[1] : '';
};
