import React from 'react';
import dayjs from 'dayjs';
import {
  DESCRIPTION,
  REFERENCE,
  TIME,
  TRANSACTION_TYPE,
} from 'pages/PageTransactionsHistory/constants/constants';
import { IHistoryByDates } from 'pages/PageTransactionsHistory/types/history-by-dates.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';

import * as S from './HistoryTableDesktop.styled';

interface IDesktopTableProps {
  historyByDates: IHistoryByDates[];
  isTransactionHistoryPending: boolean;
}

export const HistoryTableDesktop: React.FC<IDesktopTableProps> = ({
  historyByDates,
  isTransactionHistoryPending,
}) => {
  return (
    <S.TableWrapper>
      {isTransactionHistoryPending ? (
        <SSpinnerBlue />
      ) : (
        <S.Table as="table">
          <thead>
            <tr>
              <th>
                <S.HeadCell>{TRANSACTION_TYPE}</S.HeadCell>
              </th>
              <th>
                <S.HeadCell>{REFERENCE}</S.HeadCell>
              </th>
              <th>
                <S.HeadCell>{DESCRIPTION}</S.HeadCell>
              </th>
              <th>
                <S.HeadCell>{TIME}</S.HeadCell>
              </th>
            </tr>
          </thead>
          <tbody>
            {historyByDates.map(({ date, historyItems }) => (
              <React.Fragment key={date}>
                <tr>
                  <td colSpan={4}>
                    <S.DateRow as="time" dateTime={new Date(date).toISOString()}>
                      {dayjs(date).format('MMMM DD, YYYY')}
                    </S.DateRow>
                  </td>
                </tr>
                {historyItems.map(({ id, category, title, datetime }) => (
                  <tr key={id}>
                    <td>
                      <S.ValueCell>
                        <S.TransactionType transactionType={category} />
                      </S.ValueCell>
                    </td>
                    <td>
                      <S.ValueCell>{id}</S.ValueCell>
                    </td>
                    <td>
                      <S.ValueCell>{title}</S.ValueCell>
                    </td>
                    <td>
                      <S.TimeCell as="time" dateTime={datetime}>
                        {dayjs.utc(datetime).format('HH:mm')}
                      </S.TimeCell>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </S.Table>
      )}
    </S.TableWrapper>
  );
};
