import { ITimezone } from 'pages/PageProfile/types/timezone.interface';
import { ISelectItem } from 'types/ui/Select/select-item.interface';

/**
 * Converts an array of time zone objects to an array of select items.
 *
 * @param {ITimezone[]} timeZones - The array of time zone objects.
 * @returns {ISelectItem[]} The array of select items formatted with the time zone name and UTC offset.
 */
export const getSelectItemsFromTimezones = (timeZones: ITimezone[]): ISelectItem[] => {
  return timeZones.map(({ name, gmt, sign }): ISelectItem => {
    const zoneNameWithSpaces = name.replaceAll('_', ' ');
    const utcOffset = gmt !== '0' ? `UTC${sign}${gmt}` : 'UTC';
    return {
      text: `${zoneNameWithSpaces} ${utcOffset}`,
      value: name,
    };
  });
};
