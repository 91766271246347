import React from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/affiliate-program-page-icons/copy.svg';
import { ReactComponent as UsersWhite } from 'assets/icons/affiliate-program-page-icons/users-white.svg';
import { ReactComponent as WalletWhite } from 'assets/icons/affiliate-program-page-icons/wallet-white.svg';

import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './InfoSection.styled';

interface IInfoSectionProps {
  btcEarned: number;
  btcToUsdRate: number;
  referralsAmount: number;
  affiliateLink: string;
  handleCopyClick: () => void;
  isReferralLinkPending: boolean;
}

export const InfoSection: React.FC<IInfoSectionProps> = ({
  btcEarned,
  btcToUsdRate,
  referralsAmount,
  affiliateLink,
  handleCopyClick,
  isReferralLinkPending,
}) => {
  return (
    <S.InfoSection as="section">
      <S.InfoTitle>Affiliate program</S.InfoTitle>

      <S.List as="dl">
        <S.Group as="div">
          <S.Term as="dt">
            <WalletWhite />
            You have earned
          </S.Term>
          <S.BtcEarnedValue>{`${formatBtcOutput(btcEarned)} BTC`}</S.BtcEarnedValue>
          <S.UsdEarnedValue>{`≈ $${formatUsdOutput(btcToUsdRate * btcEarned)}`}</S.UsdEarnedValue>
        </S.Group>

        <S.Group as="div">
          <S.Term as="dt">
            <UsersWhite />
            Number of referrals
          </S.Term>
          <S.ReferralsValue>{referralsAmount}</S.ReferralsValue>
        </S.Group>

        <S.LinkGroup as="div">
          <S.AffiliateTitle as="dt">your affiliate link</S.AffiliateTitle>
          <S.AffiliateLinkValue>
            {isReferralLinkPending ? <S.AffiliateLinkLoader /> : affiliateLink}
          </S.AffiliateLinkValue>
          <S.ButtonWrapper>
            <S.AffiliateLinkCopyButton
              variant="alter-primary"
              text="Copy link"
              icon={<CopyIcon />}
              onClick={handleCopyClick}
              disabled={isReferralLinkPending}
            />
          </S.ButtonWrapper>
        </S.LinkGroup>
      </S.List>
    </S.InfoSection>
  );
};
