import { AxiosResponse } from 'axios';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IWithdrawConfirmBody } from './types/withdraw-confirm-request-body.interface';
import { IWithdrawConfirmResponse } from './types/withdraw-confirm-response.interface';
import { IWithdrawRequestBody } from './types/withdraw-request-body.interface';
import { IWithdrawResponse } from './types/withdraw-response.interface';
import { WithdrawApiRoute } from './withdraw.route';

export const requestWithdraw = async (
  body: IWithdrawRequestBody,
): Promise<AxiosResponse<IWithdrawResponse>> =>
  cuverseApi
    .post<IWithdrawResponse, AxiosResponse<IWithdrawResponse>>(WithdrawApiRoute.Request(), body)
    .then((res) => res);

export const confirmWithdraw = async (body: IWithdrawConfirmBody): Promise<IWithdrawConfirmResponse> => {
  return cuverseApi
    .post<IWithdrawConfirmResponse, AxiosResponse<IWithdrawConfirmResponse>>(WithdrawApiRoute.Confirm(), body)
    .then((res) => res?.data)
};
