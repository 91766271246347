import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SuccessMessage } from 'constants/notification-messages/success-message';
import { IWithdrawConfirmBody } from 'store/api/cuverse-api/withdraw/types/withdraw-confirm-request-body.interface';
import { IWithdrawError } from 'store/api/cuverse-api/withdraw/types/withdraw-error.interface';
import { IWithdrawRequestBody } from 'store/api/cuverse-api/withdraw/types/withdraw-request-body.interface';
import { confirmWithdraw, requestWithdraw } from 'store/api/cuverse-api/withdraw/withdraw.services';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

import { setWithdrawStepAction } from './withdraw.reducer';

export const requestWithdrawThunkAction = createAsyncThunk(
  'withdraw/request',
  async (
    { body, onSuccessCb }: { body: IWithdrawRequestBody; onSuccessCb: () => void },
    { dispatch },
  ): Promise<boolean> => {
    return await requestWithdraw(body)
      .then(() => {
        notifySuccess(SuccessMessage.WithdrawAllowed);
        onSuccessCb();
        dispatch(setWithdrawStepAction('withdrawal-confirm'));
        return true;
      })
      .catch((error: AxiosError<IWithdrawError>) => {
        if (error.response?.data.message) {
          notifyError(error.response.data.message);
        } else {
          notifyError('Cannot handle your request at this moment');
        }
        throw error;
      });
  },
);

export const confirmWithdrawThunkAction = createAsyncThunk(
  'withdraw/confirm',
  async (
    { body, onSuccessCb }: { body: IWithdrawConfirmBody; onSuccessCb: () => void },
    { dispatch },
  ): Promise<boolean> => {
    return await confirmWithdraw(body)
      .then((res) => {
        notifySuccess(res.data);
        onSuccessCb();
        void dispatch(closePopupAction());
        return true;
      })

      .catch((error: AxiosError<IWithdrawError>) => {
        if (error.response?.data.message) {
          notifyError(error.response.data.message);
        }
        throw error;
      });
  },
);
