import React from 'react';
import { ReactComponent as ClockIcon } from 'assets/icons/button-icons/clock.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/button-icons/minus-bold.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/button-icons/plus-bold.svg';
import { ReactComponent as CartIcon } from 'assets/icons/button-icons/shopping-basket.svg';

import { ICartManageButtonProps } from './types/cart-manage-button-props.interface';

import * as S from './CartManageButton.styled';

export const CartManageButton: React.FC<ICartManageButtonProps> = ({
  quantity,
  handleIncrement,
  handleDecrement,
  availableMinersNumber,
  unit = 'pcs.',
  isSoldOut,
}) => {
  return (
    <S.Container>
      {quantity > 0 ? (
        <>
          <S.CounterButton 
            type="button" 
            icon={<MinusIcon />} 
            onClick={handleDecrement}
            data-testid={'minusButton'} 
          />
          <S.QuantityValue>{unit ? `${quantity} ${unit}` : quantity}</S.QuantityValue>
          <S.CounterButton
            type="button"
            icon={<PlusIcon />}
            disabled={availableMinersNumber <= 0 }
            onClick={handleIncrement}
            data-testid={'plusButton'}
          />
        </>
      ) : isSoldOut ? (
        <S.OutOfStockBadge>
          <ClockIcon />
          Out of stock
        </S.OutOfStockBadge>
      ) : (
        <S.AddToCartButton
          type="button"
          variant="bordered"
          text="Add to cart"
          icon={<CartIcon />}
          onClick={handleIncrement}
          data-testid={'AddToCartButton'}
        />
      )}
    </S.Container>
  );
};
