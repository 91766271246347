import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const CartTitle = styled.h2`
  margin-bottom: 16px;
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  color: #272727;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const CartGroup = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    gap: 16px;
    margin-bottom: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
    margin-bottom: 16px;
  }
`;

export const CartTotalDescription = styled.dl`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin-bottom: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 16px;
  }
`;

export const CartTotalTitle = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #272727;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`;

export const CartTotalValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  color: #272727;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`;

/*--------------------------------------------------------------------------- */
/*CheckoutButton*/
export const CartCheckoutButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    padding: 7px 12px;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 7px 12px;
    border-radius: 16px;
  }
`;
