import React from 'react';
import { ReactComponent as Lightning } from 'assets/icons/dashboard-page-icons/lightning.svg';
import { ReactComponent as Plus } from 'assets/icons/dashboard-page-icons/plus.svg';
import {
  ELECTRICITY_DAILY_PAYMENT_TITLE,
  ELECTRICITY_PAYMENT_BALANCE_TITLE,
  ELECTRICITY_SUFFICIENT_FOR_TITLE,
} from 'pages/PageDashboard/constants/constants';

import * as S from './ElectricityArticle.styled';

interface IElectricityArticleProps {
  paymentBalanceUsd: string;
  dailyPaymentUsd: string;
  paidDays: string;
  handleReplenishClick: () => void;
}

export const ElectricityArticle: React.FC<IElectricityArticleProps> = ({
  paymentBalanceUsd,
  dailyPaymentUsd,
  paidDays,
  handleReplenishClick,
}) => {
  return (
    <S.ElectricityArticle as="dl">
      <S.BalanceTitle as="dt">
        <Lightning />
        {ELECTRICITY_PAYMENT_BALANCE_TITLE}
      </S.BalanceTitle>
      <S.BalanceValue as="dd">{paymentBalanceUsd}</S.BalanceValue>
      <S.ReplenishButton text="Replenish" icon={<Plus />} onClick={handleReplenishClick} />
      <S.Separator />
      <S.PaymentTitle>{ELECTRICITY_DAILY_PAYMENT_TITLE}</S.PaymentTitle>
      <S.PaymentValue>{dailyPaymentUsd}</S.PaymentValue>
      <S.SufficientTitle>{ELECTRICITY_SUFFICIENT_FOR_TITLE}</S.SufficientTitle>
      <S.SufficientValue>{paidDays}</S.SufficientValue>
    </S.ElectricityArticle>
  );
};
