import React from 'react';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './CartItem.styled';

interface ICartItemProps {
  cartItem: IPurchaseCartItemMapped;
}

export const CartItem: React.FC<ICartItemProps> = ({ cartItem }) => {
  return (
    <S.CartItemElement as="li">
      <S.CartItemDescription as="dl">
        <S.CartItemName as="dt">{cartItem.name}</S.CartItemName>
        <S.CartItemPrice>{`$${formatUsdOutput(cartItem.price)}`}</S.CartItemPrice>
        <S.CartItemCount>{`×${cartItem.quantity}`}</S.CartItemCount>
        <S.CartItemSum>{`$${formatUsdOutput(cartItem.sum)}`}</S.CartItemSum>
      </S.CartItemDescription>
    </S.CartItemElement>
  );
};
