import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const InvoiceStatus = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px;
  border-radius: 24px;
  background-color: #fff;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    border-radius: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
  }
`;

export const Title = styled(SText)`
  font-size: 36px;
  font-style: normal;
  line-height: 48px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const LaunchNotion = styled(SText)`
  margin-bottom: 24px;
  font-style: normal;
  line-height: 22px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const LaunchDate = styled(LaunchNotion)`
  font-weight: 700;
  line-height: 20px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    line-height: 16px;
  }
`;

export const WarningBlock = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 24px;
  padding: 16px;
  padding-left: 40px;
  border-radius: 8px;
  background: #f4f4f7;

  & svg {
    position: absolute;
    top: 18px;
    left: 16px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    margin-bottom: 24px;

    & svg {
      top: 16px;
    }
  }
`;

export const WarningText = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const WarningSpan = styled(WarningText)`
  font-weight: 700;
  line-height: 18px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    line-height: 16px;
  }
`;

export const ElectricityBalanceBlock = styled.dl`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-wrap: wrap;
    gap: 0;
  }
`;

export const BalanceTitle = styled.dt`
  max-width: 156px;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    margin-bottom: 8px;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const BalanceValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const ReplenishButton = styled(Button)`
  gap: 8px;
  margin-left: auto;
  padding: 12px 16px;
  border-radius: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
  }
`;
