import { ReactComponent as Spinner } from 'assets/icons/spinner/spinner-white.svg';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }`;

export const SSpinnerWhite = styled(Spinner)`
  flex-shrink: 0;
  animation: ${rotate} 1s linear infinite;
`;
