import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  requestDashboard,
  requestElectricity,
  requestHashRate,
  requestMinigTotal,
} from 'store/api/cuverse-api/dashboard/dashboard.service';
import {
  IDashboardElectricityResponse,
  TElectricityData,
} from 'store/api/cuverse-api/dashboard/types/dashboard-electricity-response';
import {
  IDashboardHashRateData,
  IDashboardHashRateResponse,
} from 'store/api/cuverse-api/dashboard/types/dashboard-hashrate-response';
import {
  IDashboardMiningTotalData,
  IDashboardMiningTotalResponse,
} from 'store/api/cuverse-api/dashboard/types/dashboard-mining-response';
import {
  IDashboardWalletResponse,
  IWalletData,
} from 'store/api/cuverse-api/dashboard/types/dashboard-wallet-response';

import { mapDashboardElectricity, mapDashboardWallet } from 'utils/mapper/mapper';

export const requestDashboardWalletBalance = createAsyncThunk(
  'dashboard/wallet',
  async (): Promise<IWalletData> => {
    return await requestDashboard()
      .then((response: IDashboardWalletResponse) => {
        return mapDashboardWallet(response);
      })
      .catch((error) => {
        throw error;
      });
  },
);

export const requestDashboardElectricity = createAsyncThunk(
  'dashboard/electricity',
  async (): Promise<TElectricityData> => {
    return await requestElectricity()
      .then((response: IDashboardElectricityResponse) => {
        return mapDashboardElectricity(response);
      })
      .catch((error) => {
        throw error;
      });
  },
);

export const requestDashboardMiningHashRate = createAsyncThunk(
  'dashboard/mining/hashrate',
  async (): Promise<IDashboardHashRateData> => {
    return await requestHashRate()
      .then((response: IDashboardHashRateResponse) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  },
);

export const requestDashboardMiningTotal = createAsyncThunk(
  'dashboard/mining/total',
  async (): Promise<IDashboardMiningTotalData> => {
    return await requestMinigTotal()
      .then((response: IDashboardMiningTotalResponse) => {
        return response?.data;
      })
      .catch((error) => {
        throw error;
      });
  },
);
