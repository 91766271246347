import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ReactComponent as DepositIcon } from 'assets/icons/button-icons/download.svg';
import { FormApi } from 'final-form';
import { selectElectricityReplenishFetchStatus } from 'store/replenish-reducer/replenish.selectors';
import { fetchReplenishThunkAction } from 'store/replenish-reducer/replenish.thunk-actions';
import { useAppDispatch } from 'store/store';

import { formatInputValue } from 'utils/formatters/format-input-value.util';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { gtmClickReplenish } from 'utils/gtmSender/gtmSender';
import { composeValidators } from 'utils/validators/compose-validators';
import { isGreaterOrEqual } from 'utils/validators/is-greater-or-equal';
import { isLessOrEqual } from 'utils/validators/is-less-or-equal';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import { MAXIMUM_REPLENISH_AMOUNT_USD } from './constants/maximum-replenish-amount-usd';
import { MINIMUM_REPLENISH_AMOUNT_USD } from './constants/minimum-replenish-amount-usd';
import { SUGGESTED_AMOUNTS } from './constants/suggested-amounts.const';
import { ENTER_ANOTHER_AMOUNT_TEXT, SELECTED_AMOUNT_TEXT } from './constants/ui-text.const';
import { IDepositElectricityFormValues } from './types/deposit-electricity-form-values.interface';

import * as S from './DepositElectricityForm.styled';

export const DepositElectricityForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const replenishFetchStatus = useSelector(selectElectricityReplenishFetchStatus);
  const isReplenishPending = replenishFetchStatus === 'pending';

  const [activeSuggestedAmount, setActiveSuggestedAmount] = useState<number | null>(null);

  const handleAmountButtonClick = (
    value: number,
    form: FormApi<IDepositElectricityFormValues, Partial<IDepositElectricityFormValues>>,
  ) => {
    if (!isReplenishPending) {
      setActiveSuggestedAmount(value);
      form.change('amount', String(value));
    }
  };

  const handleInputChange = (value: string, form: FormApi<IDepositElectricityFormValues>) => {
    if (!isReplenishPending) {
      activeSuggestedAmount && setActiveSuggestedAmount(null);
      const formattedAmount = removeNonNumericSymbols(formatInputValue(value, 0));
      form.change('amount', formattedAmount);
    }
  };

  const handleFormSubmit = (values: IDepositElectricityFormValues) => {
    if (!isReplenishPending) {
      void dispatch(fetchReplenishThunkAction(values));
      gtmClickReplenish(values.amount);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
        <S.Form onSubmit={handleSubmit}>
          <S.FormInnerWrapper>
            <S.SuggestedAmountsBlock>
              <S.SuggestedAmountTitle>{SELECTED_AMOUNT_TEXT}</S.SuggestedAmountTitle>

              <S.SuggestedAmountsList>
                {SUGGESTED_AMOUNTS.map((suggestedAmount) => (
                  <S.SuggestedAmount key={suggestedAmount}>
                    <S.AmountButton
                      type="button"
                      text={`$${suggestedAmount}`}
                      onClick={() => handleAmountButtonClick(suggestedAmount, form)}
                      disabled={suggestedAmount === activeSuggestedAmount}
                    />
                  </S.SuggestedAmount>
                ))}
              </S.SuggestedAmountsList>
            </S.SuggestedAmountsBlock>

            <Field
              name="amount"
              validate={composeValidators([
                isRequired,
                isNumber,
                isGreaterOrEqual(MINIMUM_REPLENISH_AMOUNT_USD),
                isLessOrEqual(MAXIMUM_REPLENISH_AMOUNT_USD),
              ])}
            >
              {({ input, meta }) => (
                <S.InputLabel $isFailed={submitFailed}>
                  <S.LabelText>{ENTER_ANOTHER_AMOUNT_TEXT}</S.LabelText>
                  <S.AmountInput
                    {...input}
                    type="text"
                    hasErrors={meta.error && submitFailed}
                    onChange={(evt) => handleInputChange(evt.currentTarget.value, form)}
                    autoComplete="off"
                    isDisabled={isReplenishPending}
                  />
                  {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                </S.InputLabel>
              )}
            </Field>
          </S.FormInnerWrapper>

          <S.DepositButton
            type="submit"
            text="Deposit"
            disabled={(submitFailed && hasValidationErrors) || isReplenishPending}
            icon={<DepositIcon />}
          />
        </S.Form>
      )}
    </Form>
  );
};
