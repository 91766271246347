import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import dayjs from 'dayjs';

import { RESEND_TIMEOUT_SECONDS } from 'components/ui/OtpForm/constants/resend-timeout-seconds.const';

export const getTimerValueInSeconds = (emailSendTime: string): number => {
  const emailResendTime = dayjs(emailSendTime).add(RESEND_TIMEOUT_SECONDS * SECOND_IN_MILLISECONDS);
  const diff = dayjs(emailResendTime).diff(dayjs(), 'seconds');
  return diff > 0 ? diff : 0;
};
