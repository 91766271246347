import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';
import { IMinersDataMapped } from 'store/api/cuverse-api/assets/types/assets-response.interface';

export const transformMinersByStatusToMinersByModel = (
  minersByStatus: IMinersDataMapped[],
): TMinersGroupByModel[] => {
  const minersByModel: TMinersGroupByModel[] = [];

  minersByStatus.forEach((minerByStatus) => {
    const existingMiner = minersByModel.find((model) => model[minerByStatus.assetName]);

    if (existingMiner) {
      existingMiner[minerByStatus.assetName][minerByStatus.status] = minerByStatus;
    } else {
      minersByModel.push({
        [minerByStatus.assetName]: {
          [minerByStatus.status]: minerByStatus,
        },
      });
    }
  });

  return minersByModel;
};
