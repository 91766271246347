import React from 'react';

import { CashbackButton } from 'components/ui/CashbackButton';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import { IElectricityWithCashbackProps } from './types/electricity-with-cashback-props.interface';
import * as S from './ElectricityWithCashback.style';

export const ElectricityWithCashback: React.FC<IElectricityWithCashbackProps> = ({
  dailyElectricityFee,
  electricityCashback,
  handleCashbackButtonClick,
}) => {
  return (
    <S.ElectricityValueWrapper>
      <S.InitialElectricityPrice>
        {`$${formatUsdOutput(dailyElectricityFee)}`}
      </S.InitialElectricityPrice>
      <S.ElectricityPriceWithCashback>
        {`$${formatUsdOutput(dailyElectricityFee * (1 - electricityCashback))}`}
      </S.ElectricityPriceWithCashback>
      <S.CashbackButtonWrapper>
        <CashbackButton
          cashbackText={`${electricityCashback * 100}% cashback`}
          handleCashbackButtonClick={handleCashbackButtonClick}
        />
      </S.CashbackButtonWrapper>
    </S.ElectricityValueWrapper>
  );
};
