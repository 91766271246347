import { keyframes } from 'styled-components';

export const datePickerReveal = keyframes`
  0% {
    top: -4px;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
`;
