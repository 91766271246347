import { ReactComponent as PersonalInfoIcon } from 'assets/icons/button-icons/contacts.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/button-icons/shield.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/button-icons/wallet.svg';
import { IProfileTabItem } from 'pages/PageProfile/types/profile-tab-item.interface';

export const profileTabItems: IProfileTabItem[] = [
  {
    text: 'Personal information',
    value: 'personal',
    icon: <PersonalInfoIcon />,
  },
  {
    text: 'Wallet',
    value: 'wallet',
    icon: <WalletIcon />,
  },
  {
    text: 'Security',
    value: 'security',
    icon: <SecurityIcon />,
  },
];
