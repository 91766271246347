import React from 'react';
import { ReactComponent as ListIcon } from 'assets/icons/button-icons/list.svg';
import { setPurchaseStepAction } from 'store/purchase-reducer/purchase.reducer';
import { useAppDispatch } from 'store/store';

import { CHOOSE_PAYMENT_OPTION } from './constants/payment-options-header.consts';

import * as S from './PaymentOptionsHeader.styled';

export const PaymentOptionsHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  
  const handleBackClick = () => {
    dispatch(setPurchaseStepAction('checkout'));
  }
  
  return (
    <S.Container>
      <S.Title>{CHOOSE_PAYMENT_OPTION}</S.Title>
      <S.BackButton
        variant="bordered"
        text="Back to invoice"
        icon={<ListIcon />}
        onClick={handleBackClick}
      />
    </S.Container>
  );
};
