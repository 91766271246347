import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/profile-page-icons/warning-triangle.svg';

import { DEPOSIT_WARNING_TEXT } from './constants/ui-text.const';

import * as S from './DepositBtcWarning.styled';

export const DepositBtcWarning: React.FC = () => {
  return (
    <S.WarningBanner>
      <WarningIcon />
      <S.WarningBannerText>{DEPOSIT_WARNING_TEXT}</S.WarningBannerText>
    </S.WarningBanner>
  );
};
