import styled, { css } from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

import { AvatarSize } from './constants/avatar-size';

export const ProfileWrapper = styled.div<{ $isMenuVariant: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 112px;

  ${({ $isMenuVariant }) =>
    $isMenuVariant &&
    css`
      padding: 8px;
      border-radius: 32px;
      background-color: #eef3fa;
    `};
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${AvatarSize.Width};
  height: ${AvatarSize.Height};
  border-radius: 50%;
  background-color: #6a96f6;
  font-size: 8px;

  & svg {
    flex-shrink: 0;
    width: ${AvatarSize.Width};
    height: ${AvatarSize.Height};
  }
`;

export const ProfileText = styled(SText)<{ $isMenuVariant: boolean }>`
  display: flex;
  align-items: center;
  min-width: 72px;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: ${({ $isMenuVariant }) => ($isMenuVariant ? '#272727' : '#ffffff')};
`;
