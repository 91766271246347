import React from 'react';
import { ReactComponent as Plus } from 'assets/icons/purchase-page-icons/plus-white.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/purchase-page-icons/warning.svg';
import dayjs from 'dayjs';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import {
  ELECTRICITY_BALANCE_TITLE,
  ELECTRICITY_FEE_NOTIFICATION_TEXT,
  INVOICE_PAID_TITLE,
  MINER_DATE_START_TEXT,
} from './constants';

import * as S from './InvoiceStatus.styled';

interface IInvoiceStatusProps {
  launchDate?: string;
  paymentBalanceUsd: number;
  handleReplenishClick: () => void;
  electricityFee: number;
}

export const InvoiceStatus: React.FC<IInvoiceStatusProps> = ({
  launchDate,
  paymentBalanceUsd,
  handleReplenishClick,
  electricityFee,
}) => {
  return (
    <S.InvoiceStatus>
      <S.Title>{INVOICE_PAID_TITLE}</S.Title>
      <S.LaunchNotion>
        {MINER_DATE_START_TEXT} <S.LaunchDate>{launchDate}</S.LaunchDate>
      </S.LaunchNotion>
      <S.WarningBlock>
        <WarningIcon />
        <S.WarningText>
          {ELECTRICITY_FEE_NOTIFICATION_TEXT}{' '}
          <S.WarningSpan>(${formatUsdOutput(electricityFee)})</S.WarningSpan>
        </S.WarningText>
      </S.WarningBlock>
      <S.ElectricityBalanceBlock as="dl">
        <S.BalanceTitle as="dt">{ELECTRICITY_BALANCE_TITLE}</S.BalanceTitle>
        <S.BalanceValue as="dd">{`$${formatUsdOutput(paymentBalanceUsd)}`}</S.BalanceValue>
        <S.ReplenishButton text="Replenish" icon={<Plus />} onClick={handleReplenishClick} />
      </S.ElectricityBalanceBlock>
    </S.InvoiceStatus>
  );
};
