import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  gap: 12px;
  width: 100%;
  max-width: 352px;
  max-height: 246px;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    max-width: 100%;
    padding: 16px;
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  align-content: center;
  gap: 8px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const CartTitle = styled.h3`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CardText = styled(SText)`
  font-size: 14px;
  line-height: 20px;
  color: #646464;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const IconText = styled(SText)`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #646464;

  @media (width <= 420px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const IconsContainer = styled.div<{ $iconGap: number }>`
  display: flex;
  align-items: center;
  gap: ${({ $iconGap }) => `${$iconGap}px`};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    margin: 12px 0 0;
  }
`;

export const PayButton = styled(Button)`
  width: 100%;
  padding: 12px;
  border-radius: 24px;
  font-weight: 400;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: #fff;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: flex-end;
    max-width: 200px;
    max-height: 32px;
    padding: 7px 20px;
    justify-self: flex-end;
  }
`;
