import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const ContentHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 32px 24px;
  background-color: #0e46c0;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 24px 16px;
  }
`;

export const ContentTitle = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: #fff;
`;
