import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackAffiliateErrorMessage } from 'constants/notification-messages/fallback-affiliate-error-message';
import {
  requestReferralHistory,
  requestReferralLinks,
  requestReferralStats,
} from 'store/api/cuverse-api/affiliate/affiliate.service';
import { IReferralStatsMapped } from 'store/api/cuverse-api/affiliate/types/affiliate-referral-stats-response.interface';
import {
  IReferralHistoryResponse,
  IReferralHistoryResponseDataMapped,
} from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';
import {
  IReferralLinkMapped,
  IReferralLinksResponse,
} from 'store/api/cuverse-api/affiliate/types/referral-links-response.interface';

import { mapReferralHistory, mapReferralLink, mapReferralStats } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

export const fetchReferralStatsThunkAction = createAsyncThunk<IReferralStatsMapped, undefined>(
  'affiliate/fetchReferralsStats',
  async (): Promise<IReferralStatsMapped> => {
    return await requestReferralStats()
      .then((res) => mapReferralStats(res))
      .catch((error) => {
        notifyError(FallbackAffiliateErrorMessage.CannotLoadReferralStats);
        throw error;
      });
  },
);

export const fetchReferralLinksThunkAction = createAsyncThunk(
  'affiliate/fetchReferralLinks',
  async (): Promise<IReferralLinkMapped> => {
    return await requestReferralLinks()
      .then((response: IReferralLinksResponse) => {
        const linkKey = Object.keys(response.data)[0];
        return mapReferralLink(response.data[linkKey]);
      })
      .catch((error) => {
        notifyError(FallbackAffiliateErrorMessage.CannotLoadReferralLink);
        throw error;
      });
  },
);

export const fetchReferralHistory = createAsyncThunk(
  'affiliate/referralHistory',
  async (): Promise<IReferralHistoryResponseDataMapped[]> => {
    return await requestReferralHistory()
      .then((response: IReferralHistoryResponse) => {
        return mapReferralHistory(response.data);
      })
      .catch((error) => {
        notifyError(FallbackAffiliateErrorMessage.CannotLoadReferralHistory);
        throw error;
      });
  },
);
