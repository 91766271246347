import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IAssetsState } from './assets.reducer';

export const getAssetsState = (state: TRootState): IAssetsState => state.assetsReducer;

export const selectAssets = createSelector([getAssetsState], (assetsState) => assetsState.assets);

export const selectAssetsStatus = createSelector(
  [getAssetsState],
  (assetsState) => assetsState.status,
);

export const selectAssetsError = createSelector(
  [getAssetsState],
  (assetsState) => assetsState.error,
);
