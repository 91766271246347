import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { HistoryTableDesktop } from 'pages/PageAffiliateProgram/components/ui/HistoryTableDesktop/HistoryTableDesktop';
import { HistoryTableMobile } from 'pages/PageAffiliateProgram/components/ui/HistoryTableMobile/HistoryTableMobile';
import { TOTAL_HISTORY_ITEMS_ON_PAGE } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import {
  selectReferralHistory,
  selectReferralLinkFetchStatus,
} from 'store/affiliate-reducer/affiliate.selectors';
import { fetchReferralHistory } from 'store/affiliate-reducer/affiliate.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';
import { Pagination } from 'components/ui/Pagination';

import * as S from './HistorySection.styled';

export const HistorySection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { innerWidth } = useInnerWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const referralHistoryStatus = useSelector(selectReferralLinkFetchStatus);
  const referralHistoryData = useSelector(selectReferralHistory);

  const handleSetCurrentPage = (newPage: number): void => {
    if (newPage <= 0) {
      setCurrentPage(1);
    } else if (newPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(newPage);
    }
  };

  const totalPages = referralHistoryData
    ? Math.ceil(referralHistoryData?.length / TOTAL_HISTORY_ITEMS_ON_PAGE)
    : 1;

  const slicedItems =
    referralHistoryData?.slice(
      (currentPage - 1) * TOTAL_HISTORY_ITEMS_ON_PAGE,
      currentPage * TOTAL_HISTORY_ITEMS_ON_PAGE,
    ) || [];

  useEffect(() => {
    void dispatch(fetchReferralHistory());
  }, [dispatch]);
  return (
    <>
      <S.SectionWrapper>
        <SVisuallyHidden as="h2">Referral history</SVisuallyHidden>
        {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
          <HistoryTableDesktop
            items={slicedItems}
            isLoading={referralHistoryStatus === 'pending'}
          />
        ) : (
          <HistoryTableMobile items={slicedItems} isLoading={referralHistoryStatus === 'pending'} />
        )}
      </S.SectionWrapper>
      <S.PaginationWrapper>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleSetCurrentPage={handleSetCurrentPage}
        />
      </S.PaginationWrapper>
    </>
  );
};
