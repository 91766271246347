import { createSelector } from '@reduxjs/toolkit';
import { IIpResponseDataMapped } from 'store/api/ip-api/types/ip-response-data.interface';
import { TRootState } from 'store/store';

import { IIpState } from './ip.reducer';

export const getIpState = (state: TRootState): IIpState => state.ipReducer;

export const selectIpData = createSelector(
  [getIpState],
  (state): IIpResponseDataMapped | null => state.ipData,
);
