import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackTransactionHistoryErrorMessage } from 'constants/notification-messages/fallback-transaction-history-error-message';
import { requestTransactionHistory } from 'store/api/cuverse-api/transaction/transaction.service';
import {
  ITransactionHistoryDataMapped,
  ITransactionHistoryRequestParams,
  ITransactionHistoryResponse,
} from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';

import { mapTransactionHistory } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

export const requestTransactionHistoryData = createAsyncThunk(
  'transaction/fetch',
  async ({
    count,
    dateStart,
  }: ITransactionHistoryRequestParams): Promise<ITransactionHistoryDataMapped> => {
    return await requestTransactionHistory({ count, dateStart })
      .then((response: ITransactionHistoryResponse) => {
        return mapTransactionHistory(response);
      })
      .catch((error) => {
        notifyError(FallbackTransactionHistoryErrorMessage.CannotLoadTransactionHistoryData);
        throw error;
      });
  },
);
