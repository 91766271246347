import { AxiosResponse } from 'axios';

import { IIpResponseData } from './types/ip-response-data.interface';
import { ipApi } from './ip.api';
import { IpRoute } from './ip.route';

export const requestIpData = async (): Promise<IIpResponseData> =>
  ipApi
    .get<IIpResponseData, AxiosResponse<IIpResponseData>>(IpRoute.IpFullData())
    .then((res) => res?.data);
