import { HOUR_IN_SECONDS } from 'constants/time/hour-in-seconds.const';
import { MINUTE_IN_SECONDS } from 'constants/time/minute-in-seconds.const';

/**
 * Converts a given expiration time delay in seconds to a formatted string "hh : mm : ss".
 *
 * @param {number|string} expirationTime - The expiration time delay in seconds, can be a number or a string representation of a number.
 * @returns {string} The formatted time string in "hh : mm : ss" format. If the input is invalid or negative, returns "00 : 00 : 00".
 */
export const getFormattedExpirationTime = (expirationTime: number | string): string => {
  const expirationTimeNumber = Number(expirationTime);
  if (isNaN(expirationTimeNumber) || expirationTimeNumber < 0) {
    return '00 : 00 : 00';
  }
  const hours = Math.floor(expirationTimeNumber / HOUR_IN_SECONDS);
  const remainingSecondsAfterHours = expirationTimeNumber % HOUR_IN_SECONDS;
  const minutes = Math.floor(remainingSecondsAfterHours / MINUTE_IN_SECONDS);
  const seconds = remainingSecondsAfterHours % MINUTE_IN_SECONDS;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours} : ${formattedMinutes} : ${formattedSeconds}`;
};
