import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const CartList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.li`
  display: flex;
  flex-direction: column;

  &:last-child dl {
    padding-bottom: 0;
  }
`;

export const MainerItem = styled.dl`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-areas:
    'title total'
    'type multiply';
  grid-template-columns: 1fr auto;
  padding: 16px 0;
  row-gap: 4px;
  border-top: 1px solid #eaeaea;
`;

export const Title = styled.dt`
  grid-area: title;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;

const DataValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const Total = styled(DataValue)`
  grid-area: total;
  justify-self: end;
`;

export const PurchaseType = styled(DataValue)`
  grid-area: type;
  font-size: 12px;
  line-height: 16px;
  color: #a1a1a1;
`;

export const MultiplyItem = styled(DataValue)`
  grid-area: multiply;
  justify-self: end;
  font-size: 12px;
  line-height: 16px;
  color: #a1a1a1;
`;
