import fromExponential from 'from-exponential';

import { cutFractionDigits } from './cut-fraction-digits.util';
import { deleteTailZeros } from './delete-tail-zeros.util';
import { splitDigits } from './split-digits.util';

//adds spaces between digits groups and cuts fraction digits
//returns stringified number with spaces and specified fraction digits
export const formatToNumberWithSpaces = (
  value: number | string,
  fractionDigits?: number,
): string => {
  const normalizedValue = Number(fromExponential(value)).toString().replace(',', '.');
  const prunedValue = deleteTailZeros(cutFractionDigits(normalizedValue, fractionDigits ?? 0));

  if (prunedValue.includes('.')) {
    const [beforeDot, afterDot] = prunedValue.split('.');

    return afterDot.length > 0 ? `${splitDigits(beforeDot)}.${afterDot}` : splitDigits(beforeDot);
  }

  return splitDigits(prunedValue);
};
