import styled from 'styled-components';

import { SText } from 'components/styled/SText';

export const DescriptionText = styled(SText)`
  font-size: 14px;
  line-height: 20px;
`;

export const DescriptionTextCentered = styled(DescriptionText)`
  text-align: center;
`;
