import React from 'react';

import {
  BUTTONS_DESCRIPTION_TEXT,
  REQUIRED_NOTE_TEXT,
  SECTION_TITLE,
} from './constants/ui-text.const';
import { IDisable2FAConfirmationProps } from './types/disable-2fa-confirmation-props.interface';

import * as S from './Disable2FAConfirmation.styled';

export const Disable2FAConfirmation: React.FC<IDisable2FAConfirmationProps> = ({
  handleConfirm,
  handleCancel,
  isPending,
}) => {
  return (
    <S.ConfirmationSection>
      <S.MainContentWrapper>
        <S.Title>{SECTION_TITLE}</S.Title>

        <S.ButtonsWrapper>
          <S.ButtonsDescription>{BUTTONS_DESCRIPTION_TEXT}</S.ButtonsDescription>
          <S.ConfirmButton
            text="Disable Google Authenticator"
            onClick={handleConfirm}
            autoFocus={true}
            disabled={isPending}
          />
          <S.CancelButton
            variant="bordered"
            text="Cancel"
            onClick={handleCancel}
            disabled={isPending}
          />
        </S.ButtonsWrapper>
      </S.MainContentWrapper>

      <S.RequiredFieldsNote>{REQUIRED_NOTE_TEXT}</S.RequiredFieldsNote>
    </S.ConfirmationSection>
  );
};
