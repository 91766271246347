import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IPaymentRequestBody } from './types/payment-request-body.interface';
import {
  IAlphaPoPaymentRequestBody,
  IAlphaPoPaymentResponseData,
  ICardPaymentRequestBody,
  ICardPaymentResponseData,
  IPaymentResponse,
} from './types/payment-response.interface';
import { PaymentRoute } from './payment.route';

export const requestPayment = async (body: IPaymentRequestBody): Promise<IPaymentResponse> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });

export const requestAlphaPoPayment = async (
  body: IAlphaPoPaymentRequestBody,
): Promise<IAlphaPoPaymentResponseData> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });

export const requestCardPayment = async (
  body: ICardPaymentRequestBody,
): Promise<ICardPaymentResponseData> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });
