import styled from 'styled-components';

import { SText } from 'components/styled/SText';

const Status = styled(SText)`
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;

  & svg {
    flex-shrink: 0;
  }
`;

export const PendingStatus = styled(Status)`
  background: #fff6d9;
  color: #f0b90b;

  & svg path {
    fill: #f0b90b;
  }
`;

export const SuccessStatus = styled(Status)`
  background: #e0fbed;
  color: #00bc81;

  & svg path {
    fill: #00bc81;
  }
`;

export const ErrorStatus = styled(Status)`
  background: #fce7e7;
  color: #ec494f;

  & svg path {
    fill: #ec494f;
  }
`;
