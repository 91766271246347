import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';
import { EPaymentStatus } from 'types/ui/PaymentStatus/payment-status.enum';

import { IDepositState } from './deposit.reducer';

export const getDepositState = (state: TRootState): IDepositState => state.depositReducer;

export const selectDepositData = createSelector(
  [getDepositState],
  (depositState) => depositState.depositData,
);

export const selectDepositDataFetchStatus = createSelector(
  [getDepositState],
  (depositState) => depositState.depositDataFetchStatus,
);

export const selectPaymentStatus = createSelector([getDepositState], (depositState) => {
  switch (depositState.paymentStatus) {
    case 'NEW':
      return EPaymentStatus.New;
    case 'PENDING0':
    case 'PENDING1':
    case 'PENDING':
      return EPaymentStatus.Pending;
    case 'PAID':
      return EPaymentStatus.Paid;
    case 'EXPIRED':
      return EPaymentStatus.Expired;
    case 'ERROR':
      return EPaymentStatus.Error;
  }
});

export const selectPaymentFetchStatus = createSelector(
  [getDepositState],
  (depositState) => depositState.paymentFetchStatus,
);

export const selectDepositStep = createSelector(
  [getDepositState],
  (depositState) => depositState.depositStep,
);
