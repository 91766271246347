import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';
import { selectIpData } from 'store/ip-reducer/ip.selectors';
import { fetchIpData } from 'store/ip-reducer/ip.thunk-actions';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { useAppDispatch } from 'store/store';

import { getBrowserName } from 'utils/common/get-browser-name.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import {
  ACCOMPLISHMENT_1_TABLE_TITLE,
  ACCOMPLISHMENT_1_TERM,
  ACCOMPLISHMENT_2_TERM,
  ACCOMPLISHMENT_3_TERM,
  ACCOMPLISHMENT_4_TERM,
  ACCOMPLISHMENT_5_TABLE_TITLE,
  ACCOMPLISHMENT_5_TERM,
  ACCOMPLISHMENT_6_TABLE_TITLE,
  ACCOMPLISHMENT_6_TERM,
  ACCOMPLISHMENT_7_TERM,
  ACCOMPLISHMENT_8_TERM,
  ACCOMPLISHMENT_9_TERM,
  ACCOMPLISHMENT_10_TERM,
  ACCOMPLISHMENT_11_TERM,
  ACCOMPLISHMENT_12_TERM,
  ACCOMPLISHMENT_TERMS_TITLE,
  ACCOUNT_NAME_TITLE,
  AGREEMENT_SPECIFICATIONS_TITLE,
  AGREEMENT_TEXT,
  AGREEMENT_TITLE,
  ALGORITHM_SHA256_DESCRIPTION,
  ALGORITHM_SHA256_TERM,
  ALGORITHM_TITLE,
  AMOUNT_TITLE,
  BALANCE_DESCRIPTION,
  BALANCE_TERM,
  BILLING_ADDRESS_TITLE,
  BROWSER_TITLE,
  COMPENSABLE_DOWNTIME_TABLE_DATA,
  COMPENSABLE_DOWNTIME_TABLE_HEADING,
  COMPENSATION_TABLE_HEADING,
  COUNTRY_TITLE,
  CUSTOMER_INFO_ID_TITLE,
  CUSTOMERS_EQUIPMENT_DESCRIPTION,
  CUSTOMERS_EQUIPMENT_TERM,
  DASHBOARD_DESCRIPTION,
  DASHBOARD_TERM,
  DATE_AND_TIME_TITLE,
  DEFINITIONS_TITLE,
  DESCRIPTION_1_PARAGRAPH,
  DESCRIPTION_2_PARAGRAPH,
  DESCRIPTION_3_PARAGRAPH,
  DESCRIPTION_4_PARAGRAPH,
  DESCRIPTION_5_PARAGRAPH,
  DISCLAIMER_TEXT,
  DISCLAIMER_TITLE,
  DISCOUNT_TITLE,
  EFFECTIVE_1_PARAGRAPH,
  EFFECTIVE_2_PARAGRAPH,
  EFFECTIVE_3_PARAGRAPH,
  EFFECTIVE_TERM,
  ELECTRICITY_FEE_DEFAULT_VALUE,
  ELECTRICITY_FEE_TITLE,
  FORCE_MAJEURE_1_TERM,
  FORCE_MAJEURE_2_TERM,
  FORCE_MAJEURE_3_TERM,
  FORCE_MAJEURE_4_TERM,
  FORCE_MAJEURE_TERMS_TITLE,
  GUARANTEED_AVAILABILITY_TABLE_DATA,
  GUARANTEED_AVAILABILITY_TABLE_HEADING,
  HARD_GRACE_DESCRIPTION,
  HARD_GRACE_TERM,
  HASH_FUNCTION_DESCRIPTION,
  HASH_FUNCTION_TERM,
  HASH_RATE_DESCRIPTION,
  HASH_RATE_TERM,
  HASH_RATE_TITLE,
  HASHING_DESCRIPTION,
  HASHING_TERM,
  IP_ADDRESS_TITLE,
  MANAGEMENT_FEE_TITLE,
  MANUFACTURER_TITLE,
  MODEL_TITLE,
  MONTHLY_HEALTH_TABLE_DATA,
  MONTHLY_HEALTH_TABLE_HEADING,
  NON_COMPENSABLE_DOWNTIME_TABLE_DATA,
  NON_COMPENSABLE_DOWNTIME_TABLE_HEADING,
  OBLIGATIONS_1_TERM,
  OBLIGATIONS_2_TERM,
  OBLIGATIONS_3_TERM,
  OBLIGATIONS_4_TERM,
  OBLIGATIONS_5_TERM,
  OBLIGATIONS_6_TERM,
  OBLIGATIONS_7_TERM,
  OBLIGATIONS_TERMS_TITLE,
  PARTIES_DESCRIPTION,
  PARTIES_TERM,
  PAYMENT_1_TERM,
  PAYMENT_2_TERM,
  PAYMENT_3_1_TERM,
  PAYMENT_3_TERM,
  PAYMENT_4_TERM,
  PAYMENT_5_TERM,
  PAYMENT_6_TERM,
  PAYMENT_7_TERM,
  PAYMENT_8_TERM,
  PAYMENT_TERMS_TITLE,
  PRODUCT_NAME_TITLE,
  PROVISION_1_TERM,
  PROVISION_2_TERM,
  PROVISION_3_TERM,
  PROVISION_4_TERM,
  PROVISION_5_TERM,
  PROVISION_6_TERM,
  PROVISION_7_TERM,
  PROVISION_8_TERM,
  PROVISION_TERMS_TITLE,
  PROXY_SERVER_DESCRIPTION,
  PROXY_SERVER_TERM,
  SERVER_DESCRIPTION,
  SERVER_TERM,
  SERVICE_AVAILABILITY_TABLE_HEADING,
  SERVICE_MAINTENANCE_TABLE_HEADING,
  SERVICES_DESCRIPTION,
  SERVICES_TERM,
  SIGNATURE_INFO_TITLE,
  SOFT_GRACE_DESCRIPTION,
  SOFT_GRACE_TERM,
  START_DATE_TITLE,
  SUSPENSION_1_TERM,
  SUSPENSION_2_TERM,
  SUSPENSION_TERMS_TITLE,
  TERM_TITLE,
  TERMINATION_1_TERM,
  TERMINATION_2_1_1_TERM,
  TERMINATION_2_1_2_TERM,
  TERMINATION_2_1_3_TERM,
  TERMINATION_2_1_TERM,
  TERMINATION_2_2_1_TERM,
  TERMINATION_2_2_2_TERM,
  TERMINATION_2_2_TERM,
  TERMINATION_2_TERM,
  TERMINATION_3_TERM,
  TERMINATION_4_TERM,
  TERMINATION_5_TERM,
  TERMINATION_6_1_TERM,
  TERMINATION_6_2_TERM,
  TERMINATION_6_3_TERM,
  TERMINATION_6_4_TERM,
  TERMINATION_6_TERM,
  TERMINATION_TERMS_DESCRIPTION,
  TERMINATION_TERMS_TITLE,
  UPFRONT_FEES_TITLE,
  USER_NAME_TITLE,
  WARRANTY_1_TERM,
  WARRANTY_2_TERM,
  WARRANTY_TERMS_TITLE,
} from './constants/ui-text.const';

import * as S from './AdditionalTerms.styled';

export const AdditionalTerms: React.FC = () => {
  const dispatch = useAppDispatch();
  const today = dayjs();
  const profile = useSelector(selectProfile);
  const invoice = useSelector(selectInvoiceData);
  const invoiceMiners = invoice ? Object.values(invoice?.invoices) : [];
  const ipData = useSelector(selectIpData);
  const browserName = getBrowserName();

  useEffect(() => {
    void dispatch(fetchIpData());
  }, [dispatch]);

  return (
    <S.AdditionalTermsArticle>
      <S.AdditionalTermsTitle>{`Cuverse Miner Services Agreement №${invoice?.id}`}</S.AdditionalTermsTitle>
      <S.TermDescription>
        <S.DescriptionParagraph>{DESCRIPTION_1_PARAGRAPH}</S.DescriptionParagraph>
        <S.DescriptionParagraph>{DESCRIPTION_2_PARAGRAPH}</S.DescriptionParagraph>
        <S.DescriptionParagraph>{DESCRIPTION_3_PARAGRAPH}</S.DescriptionParagraph>
        <S.DescriptionParagraph>{DESCRIPTION_4_PARAGRAPH}</S.DescriptionParagraph>
        <S.DescriptionParagraph>{DESCRIPTION_5_PARAGRAPH}</S.DescriptionParagraph>
      </S.TermDescription>

      <S.TermsCategoriesList>
        <S.TermsCategory>
          <S.CategoryTitle>{PROVISION_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{PROVISION_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_4_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_5_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_6_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_7_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PROVISION_8_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{PAYMENT_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{PAYMENT_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_3_TERM}</S.TermText>
              <S.TermsInner1LevelList>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{PAYMENT_3_1_TERM}</S.TermInner1LevelText>
                </S.TermInner1LevelItem>
              </S.TermsInner1LevelList>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_4_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_5_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_6_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_7_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{PAYMENT_8_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{ACCOMPLISHMENT_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_1_TERM}</S.TermText>
              <S.TableWrapper>
                <S.TableTitle>{ACCOMPLISHMENT_1_TABLE_TITLE}</S.TableTitle>
                <S.HorizontalTable>
                  <tr>
                    <th>{GUARANTEED_AVAILABILITY_TABLE_HEADING}</th>
                    <td>{GUARANTEED_AVAILABILITY_TABLE_DATA}</td>
                  </tr>
                  <tr>
                    <th>{MONTHLY_HEALTH_TABLE_HEADING}</th>
                    <td>{MONTHLY_HEALTH_TABLE_DATA}</td>
                  </tr>
                </S.HorizontalTable>
              </S.TableWrapper>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_4_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_5_TERM}</S.TermText>
              <S.TableWrapper>
                <S.TableTitle>{ACCOMPLISHMENT_5_TABLE_TITLE}</S.TableTitle>
                <S.VerticalTable>
                  <tr>
                    <th>{SERVICE_AVAILABILITY_TABLE_HEADING}</th>
                    <th>{SERVICE_MAINTENANCE_TABLE_HEADING}</th>
                    <th>{COMPENSATION_TABLE_HEADING}</th>
                  </tr>
                  <tr>
                    <td>over 99.8%</td>
                    <td>up to 1 hr. 30 min.</td>
                    <td>not compensated</td>
                  </tr>
                  <tr>
                    <td>from 99.79 to 99%</td>
                    <td>from 1 hr. 31 min. to 7 hr. 18 min.</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>from 98.99 to 98%</td>
                    <td>from 7 hrs. 19 min. to 14 hrs. 36 min.</td>
                    <td>30%</td>
                  </tr>
                  <tr>
                    <td>from 97.991 to 96.7%</td>
                    <td>from 14 hrs. 37 min. to 23 hrs. 59 min.</td>
                    <td>70%</td>
                  </tr>
                  <tr>
                    <td>under 96.6%</td>
                    <td>from 24 hours</td>
                    <td>100%</td>
                  </tr>
                </S.VerticalTable>
              </S.TableWrapper>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_6_TERM}</S.TermText>
              <S.TableWrapper>
                <S.TableTitle>{ACCOMPLISHMENT_6_TABLE_TITLE}</S.TableTitle>
                <S.VerticalTable>
                  <tr>
                    <th>{COMPENSABLE_DOWNTIME_TABLE_HEADING}</th>
                    <th>{NON_COMPENSABLE_DOWNTIME_TABLE_HEADING}</th>
                  </tr>
                  <tr>
                    <td>{COMPENSABLE_DOWNTIME_TABLE_DATA}</td>
                    <td>{NON_COMPENSABLE_DOWNTIME_TABLE_DATA}</td>
                  </tr>
                </S.VerticalTable>
              </S.TableWrapper>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_7_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_8_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_9_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_10_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_11_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{ACCOMPLISHMENT_12_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{OBLIGATIONS_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_4_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_5_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_6_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{OBLIGATIONS_7_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{SUSPENSION_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{SUSPENSION_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{SUSPENSION_2_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{TERMINATION_TERMS_TITLE}</S.CategoryTitle>
          <S.CategoryDescription>{TERMINATION_TERMS_DESCRIPTION}</S.CategoryDescription>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{TERMINATION_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{TERMINATION_2_TERM}</S.TermText>
              <S.TermsInner1LevelList>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{TERMINATION_2_1_TERM}</S.TermInner1LevelText>
                  <S.TermsInner2LevelList>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>{TERMINATION_2_1_1_TERM}</S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>{TERMINATION_2_1_2_TERM}</S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>{TERMINATION_2_1_3_TERM}</S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                  </S.TermsInner2LevelList>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{TERMINATION_2_2_TERM}</S.TermInner1LevelText>
                  <S.TermsInner2LevelList>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>{TERMINATION_2_2_1_TERM}</S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>{TERMINATION_2_2_2_TERM}</S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                  </S.TermsInner2LevelList>
                </S.TermInner1LevelItem>
              </S.TermsInner1LevelList>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{TERMINATION_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{TERMINATION_4_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{TERMINATION_5_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{TERMINATION_6_TERM}</S.TermText>
              <S.TermsInner1LevelList>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{TERMINATION_6_1_TERM}</S.TermInner1LevelText>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{TERMINATION_6_2_TERM}</S.TermInner1LevelText>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{TERMINATION_6_3_TERM}</S.TermInner1LevelText>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>{TERMINATION_6_4_TERM}</S.TermInner1LevelText>
                </S.TermInner1LevelItem>
              </S.TermsInner1LevelList>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{DISCLAIMER_TITLE}</S.CategoryTitle>
          <S.CategoryDescriptionUppercase>{DISCLAIMER_TEXT}</S.CategoryDescriptionUppercase>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{AGREEMENT_TITLE}</S.CategoryTitle>
          <S.CategoryDescription>{AGREEMENT_TEXT}</S.CategoryDescription>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{WARRANTY_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{WARRANTY_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{WARRANTY_2_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{FORCE_MAJEURE_TERMS_TITLE}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{FORCE_MAJEURE_1_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{FORCE_MAJEURE_2_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{FORCE_MAJEURE_3_TERM}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{FORCE_MAJEURE_4_TERM}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{DEFINITIONS_TITLE}</S.CategoryTitle>
          <S.DefinitionsList>
            <div>
              <S.DefinitionTerm>{SERVER_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${SERVER_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{SERVICES_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${SERVICES_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{CUSTOMERS_EQUIPMENT_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${CUSTOMERS_EQUIPMENT_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{PROXY_SERVER_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${PROXY_SERVER_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{DASHBOARD_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${DASHBOARD_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{SOFT_GRACE_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${SOFT_GRACE_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{HARD_GRACE_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${HARD_GRACE_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{HASH_RATE_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${HASH_RATE_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{HASHING_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${HASHING_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{HASH_FUNCTION_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${HASH_FUNCTION_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{ALGORITHM_SHA256_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${ALGORITHM_SHA256_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{PARTIES_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${PARTIES_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{BALANCE_TERM}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${BALANCE_DESCRIPTION}`}</S.DefinitionDescription>
            </div>
          </S.DefinitionsList>
        </S.TermsCategory>
      </S.TermsCategoriesList>

      <S.AgreementSpecificationTitle>
        {AGREEMENT_SPECIFICATIONS_TITLE}
      </S.AgreementSpecificationTitle>
      <S.DataList>
        <div>
          <S.DataTerm>{`${EFFECTIVE_TERM}: `}</S.DataTerm>
          <S.DataDescription>{today.format('DD.MM.YYYY')}</S.DataDescription>
        </div>
        <S.TermDescription>
          <S.DescriptionParagraph>{EFFECTIVE_1_PARAGRAPH}</S.DescriptionParagraph>
          <S.DescriptionParagraph>{EFFECTIVE_2_PARAGRAPH}</S.DescriptionParagraph>
          <S.DescriptionParagraph>{EFFECTIVE_3_PARAGRAPH}</S.DescriptionParagraph>
        </S.TermDescription>
        <div>
          <S.DataTerm>{`${CUSTOMER_INFO_ID_TITLE}: `}</S.DataTerm>
          <S.DataDescription>{profile.id}</S.DataDescription>
        </div>
        <div>
          <S.DataTerm>{`${ACCOUNT_NAME_TITLE}: `}</S.DataTerm>
          <S.DataDescription>{profile.email}</S.DataDescription>
        </div>
        <div>
          <S.DataTerm>{`${USER_NAME_TITLE}: `}</S.DataTerm>
          <S.DataDescription>{`${profile.firstname} ${profile.lastname}`}</S.DataDescription>
        </div>
        <div>
          <S.DataTerm>{`${BILLING_ADDRESS_TITLE}: `}</S.DataTerm>
          <S.DataDescription>{profile.address}</S.DataDescription>
        </div>
      </S.DataList>

      <ul>
        {invoiceMiners.map((miner) => (
          <S.InvoiceMiner key={miner.name}>
            <S.DataList>
              <div>
                <S.DataTerm>{`${PRODUCT_NAME_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{miner.name}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${MANUFACTURER_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{miner.manufacturer}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${MODEL_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{miner.model}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${AMOUNT_TITLE}: `}</S.DataTerm>
                <S.DataDescription>
                  {`${(Number(miner.price_usd) / Number(miner.unit_price)).toFixed(0)} pcs.`}
                </S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${ALGORITHM_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{miner.algorithm}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${HASH_RATE_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{`${miner.hashrate.toFixed(0)} TH/s`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${UPFRONT_FEES_TITLE}: `}</S.DataTerm>
                <S.DataDescription>
                  {`$${formatUsdOutput(Number(miner.price_usd) / miner.hashrate)} per TH/s`}
                </S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${ELECTRICITY_FEE_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{`$${ELECTRICITY_FEE_DEFAULT_VALUE} per TH/s`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${MANAGEMENT_FEE_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{`${Number(miner.management_fee).toFixed(2)}%`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${TERM_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{`${miner.term.toFixed(0)} days`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${START_DATE_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{miner.start_at}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${DISCOUNT_TITLE}: `}</S.DataTerm>
                <S.DataDescription>{`$${miner.discount}`}</S.DataDescription>
              </div>
            </S.DataList>
          </S.InvoiceMiner>
        ))}
      </ul>

      <S.SignatureWrapper>
        <S.SignatureTitle>{SIGNATURE_INFO_TITLE}</S.SignatureTitle>
        <S.DataList>
          <div>
            <S.DataTerm>{`${IP_ADDRESS_TITLE}: `}</S.DataTerm>
            <S.DataDescription>{ipData?.ip}</S.DataDescription>
          </div>
          <div>
            <S.DataTerm>{`${COUNTRY_TITLE}: `}</S.DataTerm>
            <S.DataDescription>{ipData?.countryName}</S.DataDescription>
          </div>
          <div>
            <S.DataTerm>{`${DATE_AND_TIME_TITLE}: `}</S.DataTerm>
            <S.DataDescription>{today.format('MMM DD, YYYY h:mm:ss a')}</S.DataDescription>
          </div>
          <div>
            <S.DataTerm>{`${BROWSER_TITLE}: `}</S.DataTerm>
            <S.DataDescription>{browserName}</S.DataDescription>
          </div>
        </S.DataList>
      </S.SignatureWrapper>
    </S.AdditionalTermsArticle>
  );
};
