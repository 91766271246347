export const DESCRIPTION_1_PARAGRAPH =
  'This is a current version of Cuverse Miner Services agreement (hereinafter referred to as "Agreement"). This Agreement was last updated on July 01, 2024.';
export const DESCRIPTION_2_PARAGRAPH =
  'This Agreement is an integral part of the Customer Agreement ("Terms"). Capitalized terms (used but not defined in these Agreement) shall have the meanings assigned to them in the Terms.';
export const DESCRIPTION_3_PARAGRAPH =
  'This Agreement is made and entered into by and between Cuverse and the entity or person agreeing to these terms (“Customer”).';
export const DESCRIPTION_4_PARAGRAPH =
  'This Agreement is effective as of the date that the Customer clicks to accept the Agreement (the “Effective Date”). If you are accepting on behalf of the Customer, you represent and warrant that: (i) you have full legal authority to bind the Customer to this Agreement; (ii) you have read and understood this Agreement; and (iii) you agree to this Agreement on behalf of the Customer. If you do not have the legal authority to bind the Customer, please do not click to accept.';
export const DESCRIPTION_5_PARAGRAPH =
  'This Agreement governs the Customer’s access to and use of the computing power for Hashing based on Dedicated Server offered by Cuverse located in the physical infrastructure of Cuverse (“Services”). Use of the Service is carried out remotely. Customer accepts and pays Cuverse for Services. You may contact Cuverse for more information and an offline version of this Agreement at: support@cuverse.com.';

// PROVISION OF SERVICES
export const PROVISION_TERMS_TITLE = 'PROVISION OF SERVICES';
export const PROVISION_1_TERM =
  'Cuverse will provide the Customer with computational power of a Dedicated Server at a Hash Rate that is fixed for the Agreement, subject to Section 3.1. Hash Rate selected by the Customer is reflected in the Agreement Specifications.';
export const PROVISION_2_TERM =
  'This Agreement is for the use of an algorithm for transaction verification of one or more Hash Functions. At the commencement of the Term of the Agreement, the algorithm selected by the Customer may be employed for mining certain cryptocurrencies. As described in Section 4 below, Customer acknowledges the risks associated with blockchain technologies and acknowledges that variations may occur with the protocols used to perform blockchain transaction verifications (“mining”) for cryptocurrencies using the algorithm selected by the Customer. This algorithm is reflected in Cuverse Cloud Services Agreement Specifications (the “Agreement Specifications”).';
export const PROVISION_3_TERM =
  'Customer’s selection of an algorithm, selection of a quantity of Hash Rate, allocation of Hash Rate, and use of the Services may result in the receipt of cryptocurrencies, subject to Maintenance Fees, Blockchain Transaction Fees, and Third-Party Fees (each described in Section 2 and Section 4 below). Cuverse has the discretion to accumulate or bundle the resulting cryptocurrency Hash Rate output in the Customer’s Dashboard until the accumulated value is sufficiently high to exceed the requirements the Blockchain Transaction Fees. Cuverse may set and adjust the threshold for delivering the Hash Rate output at its sole discretion upon notice to the Customer. Adjustments are generally made due to material increase or decrease to Blockchain Transaction Fees or operational degradation, congestion, failure or other disruption of the blockchain network used by the Customer. For the avoidance of doubt, the Customer remains the beneficial owner of any such accumulated Hash Rate output.';
export const PROVISION_4_TERM =
  'As described in Section 1.3 above, Customer’s selection of an algorithm, allocation of computational power, and use of the Services may result in the reward being paid out in one or more cryptocurrencies. Where applicable, Cuverse may provide the Customer with the option to receive the Hash Rate output in one or more cryptocurrency in the amount equal in value to the cryptocurrency mined by Customer.';
export const PROVISION_5_TERM =
  'During the duration of the Agreement, Customer may: (i) use the Services, and (ii) use any Software provided by Cuverse as part of the Services. The Customer may not sublicense or transfer these rights without prior approval from Cuverse.';
export const PROVISION_6_TERM =
  "As part of receiving the Services, the Customer will have access to the Dashboard through which the Customer may administer the Services, including management and allocation of its deployment of computation power in connection with the Customer’s selected algorithm. The Dashboard also provides the Customer with the tools for a direct transfer of cryptocurrencies to the Customer's cryptocurrency wallet as described in Section 1.4.";
export const PROVISION_7_TERM =
  'Cuverse may: (i) make available new applications, tools, features or functions from time to time through the Services; and (ii) add new services to Section 1 from time to time, the use of which may be contingent upon Customer’s agreement to additional terms.';
export const PROVISION_8_TERM =
  'Cuverse may make commercially reasonable updates to Services from time to time. If Cuverse makes a material change to Services, it will inform the Customer, if Customer has subscribed with Cuverse to be informed of such change. Cuverse may make changes to this Agreement, and any associated documents, from time to time. Unless otherwise noted, material changes to the Agreement will become effective five (5) days after they are posted, except if the changes apply to new functions, in which case, they will be effective immediately. Cuverse will provide at least five (5) days’ advance notice for materially adverse changes to any Service Level Agreement by either: (i) sending an email to Customer’s primary point of contact; (ii) posting a notice in Dashboard; or (iii) posting a notice to applicable Service Level Agreement or Customer Support webpage. Cuverse will post any modification to this Agreement to its website.';

// PAYMENT TERMS, FEES, AND TAXES
export const PAYMENT_TERMS_TITLE = 'PAYMENT TERMS, FEES, AND TAXES';
export const PAYMENT_1_TERM =
  'This Agreement and the provision of Services agreed to herein are subject to Customer’s satisfaction of an upfront payment of a fixed amount, set in US Dollars for the given hash rate, costs associated with reserving this capacity for the Customer, collateral and installation. (“Upfront Fees”). In case of early termination of Service, whose actual period of use is up to 6 (six) months, the refund of the previous payments for the unused months is made in full, after the deduction of the upfront fees.';
export const PAYMENT_2_TERM =
  'Customer shall pay and owe Cuverse certain data center operation maintenance fees (“Maintenance Fees”) for Dedicated Server rental and use of the Services as described in this Agreement and the relevant Agreement Specifications.';
export const PAYMENT_3_TERM =
  'Customer can choose the payment period for the Service when ordering or the auto-renewal (auto payment) function. If necessary, Customer can change the period of payment for Service, or disable the auto-renewal (auto payment) function in Dashboard.';
export const PAYMENT_3_1_TERM =
  'Cuverse shall calculate and automatically deduct the Maintenance Fees from Customer’s Personal Account Balance daily if auto-renewal function is enabled.';
export const PAYMENT_4_TERM =
  'The cost of Service, valid at the time of ordering Service, is valid throughout the Service rendering period. The cost of Service may be changed in accordance with the terms of Agreement.';
export const PAYMENT_5_TERM =
  'Cuverse shall have a right to render Services on credit until the end of the Service period. Services rendered on credit are subject to mandatory payment. The provision of services on credit is carried out daily. The service will not be provided by Cuverse if the Customer terminates the Service before the end of the paid maintenance fee period.';
export const PAYMENT_6_TERM =
  'Delivery and receipt of any of the Customer’s cryptocurrencies is subject to network or transaction fees charged by the blockchain associated with the algorithm selected by the Customer (“Blockchain Transaction Fees”). Blockchain Transaction Fees are paid to emit, record, verify, and process a transaction on the blockchain and are not retained by Cuverse.';
export const PAYMENT_7_TERM =
  'Certain digital wallets, wallet addresses, tools, and third-party software and devices (“Third-Party Wallets”) used by the Customer may also charge the Customer a fee, including a per-transaction or transfer fee. The Customer is responsible for being aware of and covering any such fee. Customer should note that any such fees may significantly reduce the Customer’s Hash Rate output, therefore the Customer is responsible for managing the selection, use, and rate and frequency of their receipt of Hash Rate output to any such Third-Party Wallets.';
export const PAYMENT_8_TERM =
  'The Customer is responsible for all taxes, and shall pay Cuverse for Services without any tax deductions. If Cuverse is obligated to collect or pay Taxes, Taxes will be invoiced to the Customer and/or deducted from the Customer’s Personal Account Balance, unless the Customer provides Cuverse with a timely and valid tax exemption certificate authorized by the appropriate taxing authority. In some countries, states, and provinces, the sales tax is due on the total purchase price at the time of sale and must be invoiced and collected at the time of the sale. If the Customer is required by law to withhold any taxes from its payments to Cuverse, the Customer must provide Cuverse with an official tax receipt or other appropriate documentation to support such withholding. If under the applicable tax legislation, Services are subject to local value added tax (“VAT”) and Customer is required to make a withholding of local VAT from amounts payable to Cuverse, the value of Services calculated in accordance with the above procedure will be increased (grossed up) by the Customer for the respective amount of local VAT, and the grossed-up amount will be regarded as a VAT-inclusive price. Local VAT amount withheld from the VAT-inclusive price will be remitted to the applicable local tax entity by the Customer, and the Customer will ensure that Cuverse receives payment for its services for the net amount as would otherwise be due (the VAT-inclusive price less the local VAT withheld and remitted to applicable tax authority). If required under applicable law, the Customer will provide Cuverse with applicable tax identification information that Cuverse may require to ensure its compliance with applicable tax regulations and authorities in applicable jurisdictions. The Customer will be liable to pay (or reimburse Cuverse for) any taxes, interest, penalties or fines arising out of any mis-declaration by Customer.';

// SERVICE LEVEL ACCOMPLISHMENT (SLA) AND VARIANCES
export const ACCOMPLISHMENT_TERMS_TITLE = 'SERVICE LEVEL ACCOMPLISHMENT (SLA) AND VARIANCES';
export const ACCOMPLISHMENT_1_TERM =
  'Customer acknowledges that the Services shall be rendered on a best-effort basis. The availability of Hash Rate and, accordingly, the output and results of any Services may vary up to 5%. Notwithstanding the aforementioned variance, Cuverse shall make reasonable efforts to ensure that all facilities and the relevant supplies for providing the Services are maintained in good working order to avoid any variance with the same diligence it applies in its own dealings.';
export const ACCOMPLISHMENT_2_TERM =
  'Cuverse ensures the Service availability during the term specified in Section 3.1, except for the time for emergency and preventive maintenance and the Hard grace period. The time of emergency and preventive work, as well as the Hard Grace Period is not the time of unavailability of the Service.';
export const ACCOMPLISHMENT_3_TERM =
  'Compensation is calculated for the Service based on the total unavailability of the Service during a month according to Table 1. The rate of compensation is applied to the calculation base, which is equal to the sum of monthly Service Electricity Fees.';
export const ACCOMPLISHMENT_4_TERM =
  'The unavailability of the Service (ineffective time) is defined as the time interval between the moment of sending a message via the Ticket System to the Cuverse support service and the moment of completion of the recovery work by Cuverse.';
export const ACCOMPLISHMENT_5_TERM =
  'If Service Availability during a calendar month does not comply with Section 3.1 of this Agreement, Cuverse shall provide compensation as follows:';
export const ACCOMPLISHMENT_6_TERM = 'Agreement defines the following types of downtime:';
export const ACCOMPLISHMENT_7_TERM =
  'Compensation is defined as the funds transferred by Cuverse exclusively to the Bonus Balance in the Customer Dashboard. Transfer is carried out during the first 7 (seven) business days of the month following the month in which the service was unavailable. If bonus compensation is technically impossible, a compensation period proportional to the amount of compensation is provided for the Service.';
export const ACCOMPLISHMENT_8_TERM =
  "The maximum amount of compensation is one month of free Service provision. The compensation cannot be carried out as the payment of funds or be credited to the Customer's Personal Account Balance.";
export const ACCOMPLISHMENT_9_TERM =
  'The ineffective time is not subject to compensation if it occurs due to force majeure or other circumstances that transpired through no fault of Cuverse. The ineffective time is not subject to compensation if it occurs due to actions (inaction) of the Customer.';
export const ACCOMPLISHMENT_10_TERM =
  'To receive compensation, the Customer sends an application for compensation to Cuverse after the end of each month during which the Service was unavailable. The Cuverse application is filled out specifying the ticket number, indicating the Service unavailability, the total downtime, and the dates of Service unavailability.';
export const ACCOMPLISHMENT_11_TERM =
  'Based on the Customer’s application for compensation, Cuverse calculates the compensation within 10 (ten) working days or declines the application for compensation if the Service failed due to reasons not dependent on Cuverse, or it was intended by Cuverse, of which intention the Customer was duly notified.';
export const ACCOMPLISHMENT_12_TERM =
  'After deciding on the compensation provision or refusal, Cuverse shall notify the Customer thereof.';

// TABLE 1
export const ACCOMPLISHMENT_1_TABLE_TITLE = 'Table 1';
export const GUARANTEED_AVAILABILITY_TABLE_HEADING =
  'Guaranteed availability (if there are no reasons for the unavailability of the Service)';
export const GUARANTEED_AVAILABILITY_TABLE_DATA = '24 hours a day, 7 days a week, 365 days a year';
export const MONTHLY_HEALTH_TABLE_HEADING = '% (percent) of monthly health for the Service';
export const MONTHLY_HEALTH_TABLE_DATA = '99,8%';

// TABLE 2
export const ACCOMPLISHMENT_5_TABLE_TITLE = 'Table 2';
export const SERVICE_AVAILABILITY_TABLE_HEADING = 'Service availability per month';
export const SERVICE_MAINTENANCE_TABLE_HEADING = 'Service maintenance objective';
export const COMPENSATION_TABLE_HEADING = 'Amount of compensation (%)';

// TABLE 3
export const ACCOMPLISHMENT_6_TABLE_TITLE = 'Table 3';
export const COMPENSABLE_DOWNTIME_TABLE_HEADING = 'Compensable downtime';
export const NON_COMPENSABLE_DOWNTIME_TABLE_HEADING = 'Non-compensable downtime';
export const COMPENSABLE_DOWNTIME_TABLE_DATA =
  'Service unavailability due to a failure of the Cuverse infrastructure. This type of downtime is compensated according to Section 3.3.';
export const NON_COMPENSABLE_DOWNTIME_TABLE_DATA =
  'Inaccessibility of the control middleware. Complete inability to perform any actions via the Dashboard or Proxy Server, complete inability to perform operations via API.';

// CUSTOMER OBLIGATIONS
export const OBLIGATIONS_TERMS_TITLE = 'CUSTOMER OBLIGATIONS';
export const OBLIGATIONS_1_TERM =
  'The Customer is responsible for the selection of the algorithm and understands and accepts the risks associated with blockchain technologies, cryptographic currencies, and cryptocurrency mining.';
export const OBLIGATIONS_2_TERM =
  'The Customer is responsible for the mining pool allocation of the Customer’s hash rate purchased under this Agreement. The Customer acknowledges that Cuverse is not responsible for the selection or timing of cryptocurrencies to be mined under this Agreement and protocols selected for use in connection with the Services. The Customer shall monitor and allocate the hash rate through the Dashboard. The Customer acknowledges that the difficulty of mining may vary and will likely increase during the Term of this Agreement and Customer shall monitor, supervise, and determine which cryptocurrencies to mine under this Agreement. The Customer acknowledges that the selected mining pool may charge the Customer a certain service fee. The service fee is solely determined and collected by the mining pool.';
export const OBLIGATIONS_3_TERM =
  'The Customer represents and warrants that the Customer is responsible for the preservation of confidentiality of the Customer’s login credentials. Cuverse login credentials generated by the Services are for the Customer’s personal use only and the Customer is strictly prohibited from selling, transferring, or sublicensing them to any other entity or person.';
export const OBLIGATIONS_4_TERM =
  'The Customer represents and warrants that the Customer accepts the risks of blockchain protocol and network, including instability, congestion, high transaction costs, network latency, information security, regulatory risk, and technological and operational errors. The Customer understand that these risks may result in delay or failure to process transactions, failure to deliver Hash Rate, and high Blockchain Transaction Fees. The Customer corroborates that the Customer understands and agrees that the Cuverse is not responsible for any reduced Services, related features, or capabilities resulting from blockchain network risk.';
export const OBLIGATIONS_5_TERM =
  'The Customer represents and warrants that the Customer is familiar with and accepts the risks associated with blockchain development and code changes, including the risks described in this Section 4.5. Blockchain technologies are still under development and may undergo significant changes over time. Blockchain developers may make changes to features and specifications of the algorithm selected by the Customer. Such changes may include or result in the elimination for support for and/or the efficient use of chips used by Cuverse.';
export const OBLIGATIONS_6_TERM =
  'In addition to the blockchain modification risk, blockchain developers may also choose to modify the cryptographic verification process in a way that the blockchains can no longer be verified through proof-of-work, and adopt proof-of-stake methodologies instead. The Customer accepts and acknowledges that in circumstances where the protocol of a given blockchain used by the Customer has been modified to only use proof-of-stake methodologies, the Customer accepts such risk and shall allocate Customer’s hash rate to other available blockchains and mining processes that use proof-of-work methodologies using the algorithm selected under this Agreement. Certain blockchain algorithms at this time have no alternative blockchain applications that support proof-of-work mining, and any such switch from proof-of-work to proof-of-stake would result in the impossibility of using the Customer’s hash rate for the residual Term of the Agreement, should such a protocol switch occur.';
export const OBLIGATIONS_7_TERM =
  'In connection with this Agreement, the Customer agrees that the Customer will comply with all applicable import, re-import, sanctions, anti-boycott, export, and re-export control laws and regulations, including all such laws and regulations that apply to European Union and U.S. companies, such as the Export Administration Regulations, the International Traffic in Arms Regulations, and economic sanctions programs implemented by the Office of Foreign Assets Control and the European Union’s Common Foreign and Security Policy (“CFSP”) (collectively, “Trade Sanctions Laws”). The Customer represents and warrants that the Customer and Customer’s financial institutions, or any party that owns or controls the Customer or the Customer’s financial institutions, are not subject to sanctions or otherwise designated on any list of prohibited or restricted parties, including but not limited to the lists maintained by the United Nations Security Council, the U.S. Government (e.g., the Specially Designated Nationals List and Foreign as Evaders List of the U.S. Department of Treasury, and the Entity List of the U.S. Department of Commerce), the European Union or its Member States, or other applicable government authority.';

// TEMPORARY SUSPENSION
export const SUSPENSION_TERMS_TITLE = 'TEMPORARY SUSPENSION';
export const SUSPENSION_1_TERM =
  'Cuverse may suspend the Customer’s right to access or use any portion or all of the Services immediately upon notice to the Customer if: (i) Cuverse determines the Customer’s use of the Services poses a security risk to the Services or any third party, could adversely impact Cuverse systems, the Services or any other Cuverse customer, could subject Cuverse, its affiliates, or any third party to liability, or could be fraudulent; (ii) the Customer is in breach of this Agreement; (iii) the Customer initiated a chargeback or dispute with respect to any payment or purchase of the Service; or (iv) the Customer has ceased to operate in the ordinary course, made an assignment for the benefit of creditors or similar disposition assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.';
export const SUSPENSION_2_TERM =
  'If Cuverse suspends the Customer’s right to access or use any portion or all of the Services, the Customer may remain responsible for all fees and charges that the Customer incurs during the period of suspension; and the Customer will not be entitled to any hashing results that may have occurred during the period when the Customer’s use of the Services was temporarily suspended.';

// TERM AND TERMINATION
export const TERMINATION_TERMS_TITLE = 'TERM AND TERMINATION';
export const TERMINATION_TERMS_DESCRIPTION =
  'The term of this Agreement will commence on the Effective Date and will remain in effect until terminated under this Section.';
export const TERMINATION_1_TERM =
  'The term of this Agreement will commence on the Effective Date and will remain in effect until the date set forth in the Agreement Specifications or the date of a Termination for Breach, the conditions of which are set forth in Section 6.2 below, whichever comes earlier.';
export const TERMINATION_2_TERM =
  'As a general rule, the end of the provision of the Service occurs after the expiration of the Soft Grace Period and the Hard Grace Period. At the end of the Service, all information located on the Dedicated Server is deleted.';
export const TERMINATION_2_1_TERM =
  'The termination of Service provision with an enabled Service auto-renewal function (automatic payment, including automatic daily or monthly payment) is used is performed as follows:';
export const TERMINATION_2_1_1_TERM =
  'For Dedicated Servers booked starting on July 01, 2024, the Soft Grace Period is fourteen (14) calendar days. The Hard Grace Period ends after thirty (30) calendar days from the end of the paid period.';
export const TERMINATION_2_1_2_TERM =
  "If the Customer's Personal Account Balance is replenished during the Soft Grace Period or Hard Grace Period with an amount sufficient to pay for the booked Service period, the said amount shall be automatically written off and the Service shall be extended for a new period from the day following the last day of the previous paid Service period.";
export const TERMINATION_2_1_3_TERM =
  "If the Customer's Personal Account Balance is not replenished during the Soft Grace Period or Hard Grace Period with an amount sufficient to pay for the booked Service period, the provision of the Service shall be terminated, the Dedicated Server and the information placed on it shall be deleted.";
export const TERMINATION_2_2_TERM =
  'The termination of Service provision with a disabled Service auto-renewal function (automatic payment, including automatic daily or monthly payment) is performed as follows:';
export const TERMINATION_2_2_1_TERM =
  'For Dedicated servers booked starting on July 01, 2024, the Soft Grace Period does not apply. The Hard Grace Period ends after thirty (30) days from the end of the paid period.';
export const TERMINATION_2_2_2_TERM =
  'The provision of the Services ceases immediately after the expiration of the Hard Grace Period, and all information located on the Dedicated Server is deleted.';
export const TERMINATION_3_TERM =
  'Either party may terminate this Agreement for breach if the other party is in material breach of the Agreement and fails to cure that breach within thirty (30) days after receipt of written notice. In addition, if Cuverse ceases its business operations or becomes subject to insolvency proceedings and the proceedings are not dismissed within ninety (90) days, Cuverse may terminate the Service.';
export const TERMINATION_4_TERM =
  'Upon the Termination Date, all the Customer’s rights under this Agreement are immediately terminated and the Customer shall remain responsible for all Service Fees to Cuverse incurred through the termination date.';
export const TERMINATION_5_TERM =
  "Upon the Termination Date, Cuverse must put at the Client's disposal the Dedicated Server specified in the Agreement Specifications.";
export const TERMINATION_6_TERM =
  'Upon the Termination Date, the Dedicated Server is moved to a temporary storage warehouse for the subsequent transfer to the Client by pickup or delivery by a shipping company.';
export const TERMINATION_6_1_TERM =
  'The maximum storage time of the Dedicated Server in the warehouse is 30 days. During this time, the client needs to pick up the Dedicated Server.';
export const TERMINATION_6_2_TERM =
  'The cost of storing 1 Dedicated Server unit is 4.95 USD per day. The invoice for storage is generated at the time of transferring the Dedicated Server to the client or after 30 days, whichever comes first.';
export const TERMINATION_6_3_TERM =
  'An unclaimed Dedicated Server can be recycled after the maximum storage time in the warehouse. The customer gives his full consent to the recycling of an unclaimed Dedicated Server.';
export const TERMINATION_6_4_TERM =
  "Delivery of the Dedicated Server by a shipping company is available only to the customer's name listed in the Agreement Specifications after full payment. The contact person for delivery cannot be any third party, including a close relative.";

// DISCLAIMER
export const DISCLAIMER_TITLE = 'DISCLAIMER';
export const DISCLAIMER_TEXT =
  'EXCEPT AS EXPRESSLY PROVIDED FOR IN THIS AGREEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CUVERSE, ITS AFFILIATES, AND ITS SUPPLIERS DO NOT MAKE ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND NON-INFRINGEMENT. CUVERSE, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR THE DELETION, FAILURE TO STORE, OR ANY LOSS OF ANY CUSTOMER DATA, INCLUDING BLOCKCHAIN DATA AND CRYPTOCURRENCY REWARDS DERIVED, MAINTAINED, OR TRANSMITTED THROUGH USE OF THE SERVICES. THE CUSTOMER IS SOLELY RESPONSIBLE FOR SECURING ITS CUSTOMER DATA AND CRYPTOCURRENCY REWARDS. NEITHER CUVERSE, ITS AFFILIATES, NOR ITS SUPPLIERS, WARRANT THAT THE OPERATION OF THE SOFTWARE OR THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED. CUVERSE, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR ANY LOSSES OR MISSED OPPORTUNITY COSTS RESULTING FROM BLOCKCHAIN NETWORK AND PROTOCOL OR THIRD-PARTY SOFTWARE ISSUES, WHICH MAY IN TURN RESULT IN THE INABILITY TO PROCESS TRANSACTION ON THE BLOCKCHAIN AT ALL OR WITHOUT INCURRING SUBSTANTIAL FEES.';

// ENTIRE AGREEMENT
export const AGREEMENT_TITLE = 'ENTIRE AGREEMENT';
export const AGREEMENT_TEXT =
  'This Agreement sets out all terms agreed between the parties. The Terms regulates all relations between the Customer and the Cuverse except those which are prescribed in this Agreement. In entering into this Agreement, neither party has relied on, and neither party will have any right or remedy based on, any statement, representation or warranty (whether made negligently or innocently), except those expressly set out in this Agreement. The terms located at a URL referenced in this Agreement and the Documentation are incorporated by reference into the Agreement. After the Effective Date, Cuverse may provide an updated URL in place of any URL in this Agreement.';

// WARRANTY
export const WARRANTY_TERMS_TITLE = 'WARRANTY';
export const WARRANTY_1_TERM =
  'The warranty for a Dedicated Server is effective for 1 year. Cuverse conducts repair and maintenance of all physical equipment, all costs will also be borne by us during the warranty period.';
export const WARRANTY_2_TERM =
  'After the warranty period, we will charge for repairs based on actual costs. The repair fee will be charged once every 30 days, based on actual costs incurred during the last 30 days.';

// FORCE MAJEURE
export const FORCE_MAJEURE_TERMS_TITLE = 'FORCE MAJEURE';
export const FORCE_MAJEURE_1_TERM =
  'Neither Party will be liable for any failure or delay in performance of obligation under this Agreement where the failures or delay results from any cause beyond our reasonable control, including, but not limited to, acts of God, labor disputes or other industrial disturbances, electrical or power outages, utilities or other telecommunications failures, earthquake, storms or other elements of nature, blockages, embargoes, riots, acts or orders of government, acts of terrorism, or war. Force Majeure events include, but are not limited to, upgrades to the validation rules of a given blockchain (e.g., a “hard fork” or “soft fork”).';
export const FORCE_MAJEURE_2_TERM =
  'If such circumstances do set in, the Party is obliged to notify the other Party within 15 days.';
export const FORCE_MAJEURE_3_TERM =
  'The document issued by Chamber of Commerce, authorized public authority, etc. is sufficient confirmation of existence and duration of force majeure.';
export const FORCE_MAJEURE_4_TERM =
  'If force majeure circumstances continue to be in place for over 30 days, then each Party has the right to terminate the Contract unilaterally.';

// DEFINITIONS
export const DEFINITIONS_TITLE = 'DEFINITIONS';
export const SERVER_TERM = 'Dedicated Server, Server';
export const SERVER_DESCRIPTION =
  'the complex of Cuverse computing resources providing computing power for hashing and including (but not limited to): computing servers, data storage systems (further – “DSS”), the data network, software for virtualization, backup, management and monitoring connected to power supply system, the internet and other engineering infrastructures. Both the entire physical devices and a part of their resources (computing power, disk capacity, etc.) can enter a complex of computing resources. The Cuverse equipment is in the Data Center. The Cuverse selects Equipment lineup and structure independently and does not coordinate with the Customer if not otherwise determined by the agreement specifications.';
export const SERVICES_TERM = 'Services';
export const SERVICES_DESCRIPTION =
  "rendered electronic Services that entail the real-time provision of computing power for hashing using the SHA-256 algorithm and transfer of results to the Customer's Proxy Server. The amount of rendered services is measured in Th/s provided within 24 (twenty-four) hours to the customer's Proxy Server as an average value.";
export const CUSTOMERS_EQUIPMENT_TERM = "Customer's equipment";
export const CUSTOMERS_EQUIPMENT_DESCRIPTION =
  'the equipment complex (including without limitation, server racks, telecommunication equipment, separate servers, disk arrays and hardware and software systems, engineering infrastructure), the result of joint operation of which will be the Proxy Server connected to the Internet for routing of the incoming and outgoing information flows in real time.';
export const PROXY_SERVER_TERM = 'Proxy Server';
export const PROXY_SERVER_DESCRIPTION =
  'the system intended for the 24-hour (24/7/365) use of Customer’s infrastructure in real time through the Internet. The proxy Server in the automatic mode issues tasks for computing/hashing and accepts computing results with the subsequent routing at the discretion of the Customer.';
export const DASHBOARD_TERM = 'Dashboard';
export const DASHBOARD_DESCRIPTION =
  'is a webpage at the following URL https://cuverse.com/, which is designed to manage Cuverse functions and Services, provide information required for usage of functions and Services. The Cuverse provides the Customer with access to the Dashboard after the Customer has been identified via the Cuverse Account through the Proxy Server.';
export const SOFT_GRACE_TERM = 'Soft Grace Period';
export const SOFT_GRACE_DESCRIPTION =
  'the Service provision period following the expiration of the paid period when the service was provided by the Cuverse to the Customer in full.';
export const HARD_GRACE_TERM = 'Hard Grace Period';
export const HARD_GRACE_DESCRIPTION =
  'the Service provision period after the expiration of the paid period and the Soft Grace Period when reduced services are provided by the Cuverse to the Customer, the Dedicated Server is disconnected, but the Customer’s information is stored on the Dedicated Server.';
export const HASH_RATE_TERM = 'Hash Rate';
export const HASH_RATE_DESCRIPTION =
  'measurement unit of number of the computing transactions conducted by the Cuverse Equipment as part of the Services delivered by the Cuverse to the Customer, Tera hash per second (1,000,000,000,000 transactions of hashing per second), (further TH/s).';
export const HASHING_TERM = 'Hashing';
export const HASHING_DESCRIPTION = 'the transformation conducted by a hash function.';
export const HASH_FUNCTION_TERM = 'Hash Function';
export const HASH_FUNCTION_DESCRIPTION =
  'the convolution function that conducts array conversion of input data of arbitrary length to output bit string of the set length, executed on the SHA-256 algorithm on the Cuverse Equipment.';
export const ALGORITHM_SHA256_TERM = 'Algorithm SHA-256';
export const ALGORITHM_SHA256_DESCRIPTION = 'FIPS PUB 180-2';
export const PARTIES_TERM = 'The Parties';
export const PARTIES_DESCRIPTION = 'the Cuverse and the Customer.';
export const BALANCE_TERM = 'Customer’s Personal Account Balance';
export const BALANCE_DESCRIPTION =
  "is a record in the Dashboard, which shows financial arrangements between the Cuverse and the Customer. Customer's Personal Account Balance is to be increased by the amount of payments transferred by the Customer to Cuverse as the advance payment for functions and services performed by Cuverse in favor of the Customer, and to be decreased by the cost of functions and services selected, used and received by the Customer.";

// CUVERSE MINING SERVICES AGREEMENT SPECIFICATIONS
export const AGREEMENT_SPECIFICATIONS_TITLE = 'CUVERSE MINING SERVICES AGREEMENT SPECIFICATIONS';
export const EFFECTIVE_TERM = 'Effective';
export const EFFECTIVE_1_PARAGRAPH =
  'This Cuverse Mining service agreement specifications (the “Agreement Specifications”) include the specifications for price, computational power/hash rate, term, and other pricing factors for the Customer agreement with Cuverse governed by the Cuverse Mining Services Agreement Specifications (the “Agreement”). All capitalized terms shall have the meaning of the Agreement in effect as of the Effective Date of these Agreement Specifications.';
export const EFFECTIVE_2_PARAGRAPH =
  'The below contract specifications apply to: (i) Section 1.1, (ii) Section 1.2, Section 2.1, (iii) Section 2.2, and (iv) Section 6.1 of the Agreement.';
export const EFFECTIVE_3_PARAGRAPH =
  'By clicking to agree to Agreement Specifications, the Customer accepts and agrees to all terms and conditions of the Agreement, the Agreement Specifications and the Terms.';
export const CUSTOMER_INFO_ID_TITLE = 'Customer Information ID';
export const ACCOUNT_NAME_TITLE = 'Account Name';
export const USER_NAME_TITLE = 'Name';
export const BILLING_ADDRESS_TITLE = 'Billing Address';

// MINERS
export const PRODUCT_NAME_TITLE = 'Product name';
export const MANUFACTURER_TITLE = 'Dedicated Server manufacturer';
export const MODEL_TITLE = 'Dedicated Server model';
export const AMOUNT_TITLE = 'Dedicated Server amount';
export const ALGORITHM_TITLE = 'Algorithm';
export const HASH_RATE_TITLE = 'Total Hash Rate';
export const UPFRONT_FEES_TITLE = 'Upfront Fees';
export const ELECTRICITY_FEE_TITLE = 'Maintenance Fees (Electricity Fee)';
export const ELECTRICITY_FEE_DEFAULT_VALUE = 0.075;
export const MANAGEMENT_FEE_TITLE = 'Maintenance Fees (Management Fee)';
export const TERM_TITLE = 'Term';
export const START_DATE_TITLE = 'Dedicated Server start date';
export const DISCOUNT_TITLE = 'Discount';

// Signature information
export const SIGNATURE_INFO_TITLE = 'Signature information';
export const IP_ADDRESS_TITLE = "Customer's IP address";
export const COUNTRY_TITLE = "Customer's country";
export const DATE_AND_TIME_TITLE = "Customer's date and time";
export const BROWSER_TITLE = 'Browser';
