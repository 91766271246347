import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const TableWrapper = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    padding: 16px;
    border-radius: 16px;
  }
`;

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;

  & td,
  th {
    box-sizing: border-box;
    text-align: start;

    &:nth-child(1) {
      max-width: 220px;
    }

    &:nth-child(2) {
      max-width: 180px;
    }

    &:nth-child(3) {
      max-width: 180px;
    }

    &:nth-child(4) {
      max-width: 100%;
    }

    &:nth-child(5) {
      width: 100px;
    }
  }
`;

export const HeadCell = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-bottom: 16px;
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #646464;
  font-variant-numeric: lining-nums tabular-nums;
`;
