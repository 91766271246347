import React from 'react';
import { ReactComponent as BtcGray } from 'assets/icons/assets-page-icons/btc-gray.svg';
import { ReactComponent as Miner } from 'assets/icons/assets-page-icons/miner.svg';
import { MinerStatus } from 'pages/PageAssets/components/ui/MinerStatus';
import { IMinersDataMapped } from 'store/api/cuverse-api/assets/types/assets-response.interface';

import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatToNumberWithSpaces } from 'utils/formatters/format-to-number-with-spaces.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './DesktopRowByStatus.styled';

interface IMinersRowByStatusProps {
  groupData: IMinersDataMapped;
  btcToUsdRate: number;
  statusRowsCount: number;
  shouldShowName: boolean;
  isFirstOfModel: boolean;
  isLastOfModel: boolean;
}

export const DesktopRowByStatus: React.FC<IMinersRowByStatusProps> = ({
  groupData,
  statusRowsCount,
  shouldShowName,
  btcToUsdRate,
  isFirstOfModel,
  isLastOfModel,
}) => {
  const {
    assetName,
    totalHashrate,
    number,
    efficiencyGroup,
    powerConsumption,
    dailyOutput,
    status,
  } = groupData;
  const isTurnedOff = status === 'disabled';

  return (
    <S.MinerModelRow $isFirstOfModel={isFirstOfModel} $isLastOfModel={isLastOfModel}>
      {shouldShowName && (
        <td rowSpan={statusRowsCount}>
          <S.MinerModelCell>
            <Miner /> {assetName}
          </S.MinerModelCell>
        </td>
      )}
      <td>
        <S.DoubleValueCell>
          <S.PrimaryValue $isTurnedOff={isTurnedOff}>
            <BtcGray /> {`${formatToNumberWithSpaces(totalHashrate)} TH/s`}
          </S.PrimaryValue>
          <S.MinersValue
            $isTurnedOff={isTurnedOff}
          >{`${formatToNumberWithSpaces(number)} miners`}</S.MinersValue>
        </S.DoubleValueCell>
      </td>
      <td>
        <S.DoubleValueCell>
          <S.PrimaryValue
            $isTurnedOff={isTurnedOff}
          >{`${formatToNumberWithSpaces(efficiencyGroup, 2)} kW`}</S.PrimaryValue>
          <S.SecondaryValue
            $isTurnedOff={isTurnedOff}
          >{`${formatToNumberWithSpaces(powerConsumption, 3)} W/TH`}</S.SecondaryValue>
        </S.DoubleValueCell>
      </td>
      <td>
        {(status === 'active' || status === 'disabled') && (
          <S.DoubleValueCell>
            <S.PrimaryValue
              $isTurnedOff={isTurnedOff}
            >{`$${formatUsdOutput(dailyOutput * btcToUsdRate)}`}</S.PrimaryValue>
            <S.SecondaryValue
              $isTurnedOff={isTurnedOff}
            >{`≈ ${formatBtcOutput(dailyOutput)} BTC`}</S.SecondaryValue>
          </S.DoubleValueCell>
        )}
      </td>
      <td>
        <MinerStatus status={status} />
      </td>
    </S.MinerModelRow>
  );
};
