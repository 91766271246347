import { IReferralLinkMapped } from 'store/api/cuverse-api/affiliate/types/referral-links-response.interface';

export const EmptyReferralLink: IReferralLinkMapped = {
  name: '',
  code: '',
  link: '',
  path: '',
  salesCommission: 0,
  customerDiscount: 0,
  earned: 0,
  conversion: 0,
};
