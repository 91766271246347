import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';

import { AppRoute } from 'utils/route/app-route';

import * as S from './PageNotFound.styled';

export const PageNotFound: React.FC = () => {
  const userAuthStatus = useSelector(selectUserAuthStatus);
  const isUserAuthorised = userAuthStatus === 'authorized-verified';

  return (
    <S.Container $hasBackground={isUserAuthorised}>
      <S.NotFoundCard>
        <S.NotFoundCode>404</S.NotFoundCode>
        <S.NotFoundTitle>Page not found</S.NotFoundTitle>
        <S.LinkContainer>
          <S.NavigationLink linkTo={AppRoute.Home()} text="Go to Dashboard" />
        </S.LinkContainer>
      </S.NotFoundCard>
    </S.Container>
  );
};
