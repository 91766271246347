import React from 'react';
import { ReactComponent as SuccessIcon } from 'assets/icons/purchase-status-icons/check-circle.svg';
import { ReactComponent as WaitingIcon } from 'assets/icons/purchase-status-icons/clock.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/purchase-status-icons/gear-six.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/purchase-status-icons/x-circle.svg';
import { EPaymentStatus } from 'types/ui/PaymentStatus/payment-status.enum';

import {
  ERROR_STATUS_TEXT,
  EXPIRED_STATUS_TEXT,
  NEW_STATUS_TEXT,
  PAID_STATUS_TEXT,
  PENDING_STATUS_TEXT,
} from './constants/ui-text';
import { IPaymentStatusProps } from './types/payment-status-props.interface';

import * as S from './PaymentStatus.styled';

export const PaymentStatus: React.FC<IPaymentStatusProps> = ({ paymentStatus }) => {
  switch (paymentStatus) {
    case EPaymentStatus.New:
      return (
        <S.PendingStatus>
          <WaitingIcon />
          {NEW_STATUS_TEXT}
        </S.PendingStatus>
      );
    case EPaymentStatus.Pending:
      return (
        <S.PendingStatus>
          <ProcessingIcon />
          {PENDING_STATUS_TEXT}
        </S.PendingStatus>
      );
    case EPaymentStatus.Paid:
      return (
        <S.SuccessStatus>
          <SuccessIcon />
          {PAID_STATUS_TEXT}
        </S.SuccessStatus>
      );
    case EPaymentStatus.Expired:
      return (
        <S.ErrorStatus>
          <ErrorIcon />
          {EXPIRED_STATUS_TEXT}
        </S.ErrorStatus>
      );
    case EPaymentStatus.Error:
      return (
        <S.ErrorStatus>
          <ErrorIcon />
          {ERROR_STATUS_TEXT}
        </S.ErrorStatus>
      );
  }
};
