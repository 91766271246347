import React from 'react';
import { ReactComponent as LogoPicture } from 'assets/icons/logo-icons/logo-picture.svg';
import { ReactComponent as LogoText } from 'assets/icons/logo-icons/logo-text.svg';

import * as S from './MenuLogo.styled';

export const MenuLogo: React.FC = () => {
  return (
    <S.LogoWrapper>
      <LogoPicture />
      <LogoText />
    </S.LogoWrapper>
  );
};
