import React from 'react';
import { VerificationInfo } from 'pages/PageVerification/components/logic/VerificationInfo';
import {
  CURRENT_WITHDRAWALS_LIMIT,
  WITHDRAWALS_VALUE,
} from 'pages/PageVerification/constants/constants';

import * as S from './VerificationPanel.styled';

interface IVerificationPanelDescription {
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  buttonText: string;
}

interface IVerificationPanelProps {
  label: string;
  hasLimitTag?: boolean;
  data: IVerificationPanelDescription[];
  isWithdrawalView?: boolean;
}

export const VerificationPanel: React.FC<IVerificationPanelProps> = ({
  label,
  hasLimitTag,
  data,
  isWithdrawalView,
}) => {
  return (
    <S.VerificationPanel isWithdrawalView={isWithdrawalView}>
      <S.WithdrawalLimit isWithdrawalView={isWithdrawalView}>
        {label}
        {hasLimitTag && (
          <S.LimitPanel>
            <S.LimitTitle>{CURRENT_WITHDRAWALS_LIMIT.toUpperCase()}</S.LimitTitle>
            <S.LimitValue>{WITHDRAWALS_VALUE}</S.LimitValue>
          </S.LimitPanel>
        )}
      </S.WithdrawalLimit>
      <VerificationInfo data={data} isWithdrawalView={isWithdrawalView} />
    </S.VerificationPanel>
  );
};
