import React from 'react';
import { Outlet } from 'react-router-dom';

import { OuterHeader } from './components/ui/OuterHeader';

import * as S from './OuterLayout.styled';

export const OuterLayout: React.FC = () => {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <OuterHeader />
      </S.HeaderWrapper>
      <S.ContentWrapper>
        <Outlet />
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
