import React from 'react';
import { ReactComponent as BasicVerifiedIcon } from 'assets/icons/verify-icons/basic-verification-icon.svg';
import { ReactComponent as FullVerifiedIcon } from 'assets/icons/verify-icons/full-verification-icon.svg';

interface IVerificationPanelDescription {
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  buttonText: string;
}

/**
 * Verification panel text
 * @param isVerified boolean flag
 * @returns an array of information depending on the isVerified flag
 */
export const getVerificationPanelText = (isVerified: boolean): IVerificationPanelDescription[] => {
  return [
    {
      text: 'Basic verification',
      Icon: BasicVerifiedIcon,
      description:
        'We verify your identification document (ID card, passport or driver’s license). Withdrawals are limited to $400 000.',
      buttonText: 'Verify my profile',
    },
    {
      text: 'Full verification',
      Icon: FullVerifiedIcon,
      description:
        'We verify your identification document + proof of address. Withdrawals are unlimited.',
      buttonText: isVerified ? 'Verify my profile' : 'Go through the basic verification first',
    },
  ];
};
