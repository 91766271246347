import React from 'react';

import * as S from './PaginationButton.styled';

interface IPaginationButtonProps {
  pageNumber: number;
  isCurrent: boolean;
  handleSetCurrentPage: (newPage: number) => void;
}

export const PaginationButton: React.FC<IPaginationButtonProps> = ({
  pageNumber,
  isCurrent,
  handleSetCurrentPage,
}) => {
  return (
    <S.Button $isCurrent={isCurrent} onClick={() => handleSetCurrentPage(pageNumber)}>
      {pageNumber}
    </S.Button>
  );
};
