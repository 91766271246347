import styled from 'styled-components';

import { SText } from 'components/styled/SText';

const DefaultCurrencyBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  min-width: 74px;
  height: 24px;
  padding-right: 4px;
  border-radius: 12px;

  & svg {
    flex-shrink: 0;
  }
`;

export const CurrencyName = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const BtcBadge = styled(DefaultCurrencyBadge)`
  background-color: #fff6d9;
`;

export const LtcBadge = styled(DefaultCurrencyBadge)`
  background-color: #eaeaea;
`;

export const EthBadge = styled(DefaultCurrencyBadge)`
  background-color: #d8eeff;
`;

export const UsdtBadge = styled(DefaultCurrencyBadge)`
  background-color: #e0fbed;
`;

export const BnbBadge = styled(DefaultCurrencyBadge)`
  background-color: #fff6d9;
`;

export const BusdBadge = styled(DefaultCurrencyBadge)`
  background-color: #eaeaea;
`;

export const UsdcBadge = styled(DefaultCurrencyBadge)`
  background-color: #d8eeff;
`;

export const BchBadge = styled(DefaultCurrencyBadge)`
  background-color: #e0fbed;
`;
