import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IRatesState } from './rates.reducer';

const getRatesState = (state: TRootState): IRatesState => state.ratesReducer;

export const selectRatesFetchStatus = createSelector(
  [getRatesState],
  (state) => state.ratesFetchStatus,
);

export const selectAllRates = createSelector(
  [getRatesState], 
  ({ ratesData }) => ratesData);

export const selectBtcUsdRate = createSelector([getRatesState], ({ ratesData }) => {
  if (!ratesData) {
    return 0;
  }

  return Number(ratesData.BTCUSD.value);
});

export const selectBtcUsdCommonRate = createSelector([getRatesState], ({ ratesData }) => {
  if (!ratesData) {
    return 0;
  }

  return Number(ratesData.BTCUSD_COMMON.value);
});