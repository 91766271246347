import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestInvoicePdfLink } from 'store/api/cuverse-api/invoice-pdf-links/invoice-pdf-links.service';
import { IInvoicePdfLinksResponse } from 'store/api/cuverse-api/invoice-pdf-links/types/download-invoice-response';

import { notifyError } from 'utils/notify/notify.utils';

export const fetchInvoicePdfLinksAction = createAsyncThunk(
  'invoicePdfLinks/fetch',
  async (orderId: number): Promise<string> => {
    return await requestInvoicePdfLink(orderId)
      .then((response: IInvoicePdfLinksResponse) => {
        return response.data.invoice_path;
      })
      .catch((error) => {
        notifyError('cannot download pdf file');
        throw error;
      });
  },
);
