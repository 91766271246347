import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const AdditionalTermsArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const AdditionalTermsTitle = styled.h3`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TermDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 8px;
  }
`;

export const DescriptionParagraph = styled.p`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TermsCategoriesList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 16px;
  counter-reset: categoryNum;
`;

export const TermsCategory = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  counter-increment: categoryNum;
`;

export const CategoryTitle = styled.h4`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: #272727;

  &::before {
    content: counter(categoryNum) '. ';
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CategoryDescription = styled(SText)`
  line-height: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CategoryDescriptionUppercase = styled(CategoryDescription)`
  text-transform: uppercase;
`;

export const TermsList = styled(TermsCategoriesList)`
  gap: 12px;
  counter-reset: termNum;
`;

export const TermItem = styled(TermsCategory)`
  counter-increment: termNum;
`;

export const TermText = styled(SText)`
  line-height: 24px;

  &::before {
    content: counter(categoryNum) '.' counter(termNum) ' ';
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TermsInner1LevelList = styled(TermsList)`
  counter-reset: termInner1LevelNum;
`;

export const TermInner1LevelItem = styled(TermItem)`
  counter-increment: termInner1LevelNum;
`;

export const TermInner1LevelText = styled(TermText)`
  &::before {
    content: counter(categoryNum) '.' counter(termNum) '.' counter(termInner1LevelNum) ' ';
  }
`;

export const TermsInner2LevelList = styled(TermsInner1LevelList)`
  counter-reset: termInner2LevelNum;
`;

export const TermInner2LevelItem = styled(TermInner1LevelItem)`
  counter-increment: termInner2LevelNum;
`;

export const TermInner2LevelText = styled(TermInner1LevelText)`
  &::before {
    content: counter(categoryNum) '.' counter(termNum) '.' counter(termInner1LevelNum) '.'
      counter(termInner2LevelNum) ' ';
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0;
`;

export const TableTitle = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: start;
  text-transform: uppercase;
  color: #272727;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid #c9c9c9;
  border-radius: 12px;
  border-collapse: separate;

  & th,
  & td {
    padding: 12px;
    font-family: ${FontFamily.Montserrat};
    font-weight: 500;
    text-align: left;

    @media (max-width: ${BreakPoint.MobileTop}) {
      padding-right: 4px;
      padding-left: 4px;
    }
  }

  & td {
    color: #272727;
  }

  & th {
    color: #646464;
  }
`;

export const HorizontalTable = styled(Table)`
  & th,
  & td {
    font-size: 16px;
    line-height: 22px;
    border-top: 1px solid #c9c9c9;

    @media (max-width: ${BreakPoint.MobileTop}) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  & td {
    border-left: 1px solid #c9c9c9;
  }

  & tr:first-of-type th,
  & tr:first-of-type td {
    border-top: none;
  }
`;

export const VerticalTable = styled(Table)`
  & th {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-left: 1px solid #c9c9c9;

    @media (max-width: ${BreakPoint.MobileTop}) {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.3px;
    }
  }

  & td {
    font-size: 14px;
    line-height: 18px;
    border-left: 1px solid #c9c9c9;
    border-top: 1px solid #c9c9c9;

    @media (max-width: ${BreakPoint.MobileTop}) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  & th:first-child,
  & td:first-child {
    border-left: none;
  }
`;

export const DefinitionsList = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 12px;
  counter-reset: termNum;
`;

export const DefinitionTerm = styled.dt`
  display: inline;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #272727;
  counter-increment: termNum;

  &::before {
    content: counter(categoryNum) '.' counter(termNum) ' ';
    font-weight: 500;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const DefinitionDescription = styled.dd`
  display: inline;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const AgreementSpecificationTitle = styled(AdditionalTermsTitle)`
  text-transform: uppercase;
`;

export const DataList = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DataTerm = styled.dt`
  display: inline;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const DataDescription = styled.dd`
  display: inline;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const InvoiceMiner = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #c9c9c9;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #c9c9c9;
    }
  }
`;

export const SignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SignatureTitle = styled.h3`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
