import React from 'react';
import { SContainer, SInvoiceWrapper, SPaymentWrapper } from 'pages/PagePurchase/components/styled';
import { InvoiceStatus } from 'pages/PagePurchase/components/ui/InvoiceStatus';
import { PaymentDetails } from 'pages/PagePurchase/components/ui/PaymentDetails';
import { PromoCodeArea } from 'pages/PagePurchase/components/ui/PromoCodeArea';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { TDiscountType } from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';
import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';

interface IPaymentSuccessScreenProps {
  purchaseStep: TPurchaseStep;
  launchDate?: string;
  paymentBalanceUsd: number;
  handleReplenishClick: () => void;
  sumUsd: number;
  finalSumBtc: number;
  discount: number;
  discountType: TDiscountType;
  btcEarned: number;
  promoStatus: EPromoCodeStatus;
  electricityFee: number;
}

export const PaymentSuccessScreen: React.FC<IPaymentSuccessScreenProps> = ({
  purchaseStep,
  launchDate,
  paymentBalanceUsd,
  handleReplenishClick,
  sumUsd,
  finalSumBtc,
  discount,
  discountType,
  btcEarned,
  promoStatus,
  electricityFee,
}) => {
  return (
    <SContainer>
      <SInvoiceWrapper as="section" $purchaseStep={purchaseStep}>
        <SVisuallyHidden as="h2">Payment details</SVisuallyHidden>
        <InvoiceStatus
          launchDate={launchDate}
          paymentBalanceUsd={paymentBalanceUsd}
          handleReplenishClick={handleReplenishClick}
          electricityFee={electricityFee}
        />
        <SPaymentWrapper>
          {promoStatus === EPromoCodeStatus.Accepted && <PromoCodeArea promoStatus={promoStatus} />}
          <PaymentDetails
            sumUsd={sumUsd}
            finalSumBtc={finalSumBtc}
            discount={discount}
            discountType={discountType}
            btcEarned={btcEarned}
          />
        </SPaymentWrapper>
      </SInvoiceWrapper>
    </SContainer>
  );
};
