export const INFO_SECTION_SEO_TITLE = 'Information about wallet, mining and electricity payments';
export const WALLET_SECTION_TITLE = 'Wallet';
export const TOTAL_BALANCE_TITLE = 'Total balance';
export const ELECTRICITY_PAYMENT_BALANCE_TITLE = 'electricity payment balance';
export const ELECTRICITY_DAILY_PAYMENT_TITLE = 'daily payment';
export const ELECTRICITY_SUFFICIENT_FOR_TITLE = 'the balance is sufficient for';
export const MINING_SECTION_TITLE = 'Mining';
export const HASH_RATE_AMOUNT_TITLE = 'hash rate amount';
export const MINED_YESTERDAY_TITLE = 'MINED YESTERDAY';
export const TOTAL_MINED_TITLE = 'TOTAL MINED';
export const LAUNCH_PENDING_TITLE = 'launch pending';
