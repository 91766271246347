import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IPaymentState } from './payment.reducer';

export const getPaymentState = (state: TRootState): IPaymentState => state.paymentReducer;

export const selectPaymentResult = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.paymentStatusResult,
);

export const selectPaymentOrdersIds = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.ordersIds,
);

export const selectAlphaPoPaymentLink = createSelector(
  [getPaymentState],
  (state) => state.alphaPoPaymentLink,
);

export const selectCardPaymentLink = createSelector(
  [getPaymentState],
  (state) => state.cardPaymentLink,
);

export const selectPaymentStatus = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.paymentStatusResult,
);

export const selectPaymentError = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.error,
);
