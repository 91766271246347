import dayjs from 'dayjs';

type UtCInput = string | null;

export function transformTimestampDate(input: UtCInput): string | null {
  if (!input) {
    return null;
  }

  return dayjs(input).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
}
