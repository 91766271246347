import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const CashbackTermsArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const CashbackTermsTitle = styled.h3`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const DescriptionParagraph = styled.p`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TermsCategoriesList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 16px;
  counter-reset: categoryNum;
`;

export const TermsCategory = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  counter-increment: categoryNum;
`;

export const CategoryTitle = styled.h4`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: #272727;

  &::before {
    content: counter(categoryNum) '. ';
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TermsList = styled(TermsCategoriesList)`
  gap: 12px;
  counter-reset: termNum;
`;

export const TermItem = styled(TermsCategory)`
  counter-increment: termNum;
`;

export const TermText = styled(SText)`
  line-height: 24px;

  &::before {
    content: counter(categoryNum) '.' counter(termNum) ' ';
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const DefinitionsList = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 12px;
  counter-reset: termNum;
`;

export const DefinitionTerm = styled.dt`
  display: inline;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #272727;
  counter-increment: termNum;

  &::before {
    content: counter(categoryNum) '.' counter(termNum) ' ';
    font-weight: 500;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const DefinitionDescription = styled.dd`
  display: inline;
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 14px;
    line-height: 20px;
  }
`;
