import React from 'react';
import { ReactComponent as DownloadIcon } from 'assets/icons/purchase-page-icons/download-white.svg';
import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';

import * as S from './InvoiceHeader.styled';

interface InvoiceHeaderProps {
  invoiceNumber?: number;
  invoiceDate?: string;
  handlePdfClick: () => void;
  step: TPurchaseStep;
}

export const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  invoiceNumber,
  invoiceDate,
  handlePdfClick,
  step,
}) => {
  const [date, time] = invoiceDate?.split(' ') || ['', ''];

  return (
    <S.Container step={step}>
      <S.Title>Invoice #{invoiceNumber}</S.Title>
      {step === 'paid-success' && (
        <S.PdfButton
          variant="alter-bordered"
          text="Save as PDF"
          icon={<DownloadIcon />}
          onClick={handlePdfClick}
        />
      )}
      <S.InvoiceDate>
        created {date} at {time}
      </S.InvoiceDate>
    </S.Container>
  );
};
