import { TProfileTabName } from 'types/ui/ProfileTabs/profile-tab-name.type';

export const AppRoute = {
  Home: (): string => '/',
  AffiliateProgram: (): string => '/affiliate-program',
  Assets: (): string => '/assets',
  Profile: (): string => '/profile',
  ProfileTab: (tabName: TProfileTabName): string => `/profile/${tabName}`,
  Disable2FA: (): string => '/disable-2fa',
  Purchase: (): string => '/purchase',
  TransactionsHistory: (): string => '/transactions-history',
  Login: (): string => '/login',
  Registration: (): string => '/registration',
  PasswordReset: (): string => '/password-reset',
  Verification: (): string => '/verification',
  NotFound: (): string => '/not-found',
  ReferralRegistration: (): string => '/r/*',
} as const;
