export const deleteDecimalTailZeros = (value: string | number): string => {
	const stringValue = String(value).replace(/,/g, '.').replace(/\.+$/, '');
	const [integerPart, decimalPart] = stringValue.split('.');

	if (!decimalPart) {
		return stringValue;
	}

	const trimmedDecimalPart = decimalPart.replace(/0+$/, '');
	return trimmedDecimalPart.length > 0 ? `${integerPart}.${trimmedDecimalPart}` : integerPart;
};
