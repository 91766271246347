import React from 'react';

import { AssetsSection } from './components/logic/AssetsSection';
import { LimitedUptimeSection } from './components/logic/LimitedUptimeSection/LimitedUptimeSection';
import { ASSETS } from './constants/constants';

import * as S from './PageAssets.styled';

export const PageAssets: React.FC = () => {
  return (
    <>
      <S.ContentHeader>
        <S.Title>{ASSETS}</S.Title>
      </S.ContentHeader>
      <S.ContentSectionsWrapper>
        <LimitedUptimeSection />
        <AssetsSection />
      </S.ContentSectionsWrapper>
    </>
  );
};
