import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const ScreenWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 24px;
  padding: 32px 24px 64px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    gap: 12px;
    padding: 32px 16px 64px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 24px 16px 64px;
  }
`;