import styled from 'styled-components';

import { Select } from 'components/ui/Select';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 0 12px 8px;
`;

const HeaderSelect = styled(Select)`
  & > button {
    min-height: 32px;
    padding: 7px 31px 7px 11px;

    & span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  & > div {
    top: 36px;
  }
`;

export const YearSelect = styled(HeaderSelect)`
  min-width: 80px;
`;

export const MonthSelect = styled(HeaderSelect)`
  width: 100%;
`;
