import { createSlice } from '@reduxjs/toolkit';
import { IVerificationDataMapped } from 'store/api/cuverse-api/verification/types/types';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchVerificationThunkAction } from './verification.thunk-actions';

export interface IVerificationState {
  verificationData: IVerificationDataMapped;
  verificationFetchStatus: TFetchStatus;
  debugError: string | null;
}

const initialState: IVerificationState = {
  verificationData: {
    userId: null,
    applicantToken: null,
  },
  verificationFetchStatus: 'initial',
  debugError: null,
};

export const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerificationThunkAction.pending, (state) => {
        state.verificationFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(fetchVerificationThunkAction.fulfilled, (state, action) => {
        state.verificationFetchStatus = 'fulfilled';
        state.verificationData = action.payload;
      })
      .addCase(fetchVerificationThunkAction.rejected, (state, error) => {
        state.verificationFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });
  },
});

export const verificationReducer = verificationSlice.reducer;
