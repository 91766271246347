import { EVerificationStatusCode } from 'store/api/cuverse-api/profile/types/profile-response.interface';

/**
 * basic verified status
 * @param verificationStatusCode status of verification
 * @returns boolean value
 */
export const getIsBasicVerificationStatus = (
  verificationStatusCode: EVerificationStatusCode,
): boolean => {
  return [
    EVerificationStatusCode.KYC_STATUS_NEEDS_VERIFICATION,
    EVerificationStatusCode.KYC_STATUS_INIT,
    EVerificationStatusCode.KYC_STATUS_PROCESSING,
    EVerificationStatusCode.KYC_STATUS_RETRY,
    EVerificationStatusCode.KYC_STATUS_REJECT,
    EVerificationStatusCode.KYC_STATUS_PRIMARY_VERIFIED,
  ].includes(verificationStatusCode);
};
