import React from 'react';
import { ReactComponent as InvoiceIcon } from 'assets/icons/purchase-page-icons/invoice.svg';
import { CartItem } from 'pages/PagePurchase/components/ui/CartItem';
import { IPurchaseCartItemMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import { SELECTED_MINERS_TITLE, TOTAL_SUM_TITLE } from './constants/cart-list-section.consts';

import * as S from './CartListSection.styled';

interface ICartListSectionProps {
  handleCheckoutClick: () => void;
  cartList: IPurchaseCartItemMapped[];
  totalCartSum: number;
}

export const CartListSection: React.FC<ICartListSectionProps> = ({
  handleCheckoutClick,
  cartList,
  totalCartSum,
}) => {
  return (
    <>
      <S.CartTitle>{SELECTED_MINERS_TITLE}</S.CartTitle>
      <S.CartGroup as="ul">
        {cartList.map((item) => (
          <CartItem key={item.productId} cartItem={item} />
        ))}
      </S.CartGroup>

      <S.CartTotalDescription as="dl">
        <S.CartTotalTitle>{TOTAL_SUM_TITLE}</S.CartTotalTitle>
        <S.CartTotalValue>${formatUsdOutput(totalCartSum)}</S.CartTotalValue>
      </S.CartTotalDescription>

      <S.CartCheckoutButton
        variant="primary"
        text="Checkout"
        icon={<InvoiceIcon />}
        onClick={handleCheckoutClick}
      />
    </>
  );
};
