import dayjs from 'dayjs';
import {
  IReferralStatsMapped,
  IReferralStatsResponse,
} from 'store/api/cuverse-api/affiliate/types/affiliate-referral-stats-response.interface';
import {
  IReferralHistoryResponseData,
  IReferralHistoryResponseDataMapped,
} from 'store/api/cuverse-api/affiliate/types/referral-history-response.interface';
import {
  IReferralLink,
  IReferralLinkMapped,
} from 'store/api/cuverse-api/affiliate/types/referral-links-response.interface';
import {
  IMinersData,
  IMinersDataMapped,
  TAssetsData,
  TMiners,
  TMinersStatuses,
} from 'store/api/cuverse-api/assets/types/assets-response.interface';
import {
  IDashboardElectricityResponse,
  TElectricityData,
} from 'store/api/cuverse-api/dashboard/types/dashboard-electricity-response';
import {
  IDashboardWalletResponse,
  IWalletData,
} from 'store/api/cuverse-api/dashboard/types/dashboard-wallet-response';
import {
  ICartCouponResponseData,
  ICartCouponResponseDataMapped,
} from 'store/api/cuverse-api/invoice/types/cart-coupon-response.interface';
import {
  IInvoiceResponseData,
  IInvoiceResponseDataMapped,
} from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import {
  ICardPaymentMessage,
  ICardPaymentMessageMapped,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import {
  IProfileData,
  IProfileResponse,
} from 'store/api/cuverse-api/profile/types/profile-response.interface';
import {
  IMinerDataMapped,
  IMinerDataResponse,
} from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import {
  IPurchaseCartDataMapped,
  IPurchaseCartResponse,
} from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';
import {
  ITransactionHistoryDataMapped,
  ITransactionHistoryResponse,
} from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';
import { IUptimeDataMapped } from 'store/api/cuverse-api/uptime/types/uptime-data-mapped';
import { IUptimeResponseData } from 'store/api/cuverse-api/uptime/types/uptime-response.interface';
import {
  IVerificationData,
  IVerificationDataMapped,
} from 'store/api/cuverse-api/verification/types/types';
import {
  IIpResponseData,
  IIpResponseDataMapped,
} from 'store/api/ip-api/types/ip-response-data.interface';

import { getFullImagePath } from 'utils/common/get-cuverse-image-path';
import { transformTimestampDate } from 'utils/common/transformTimestampDate';

/**
 * Mapping data from server response from snake case to camel case
 * @param data - snake case
 * @returns object - camel case
 */
export const mapDashboardWallet = ({ data }: IDashboardWalletResponse): IWalletData => {
  return {
    amount: data.btc_balance.amount,
    isIncreased: data.btc_balance.is_increased,
    usdAmount: data.btc_balance.usd_amount,
    lockedAmount: data.btc_balance.locked_amount,
  };
};

export const mapDashboardElectricity = ({
  data,
}: IDashboardElectricityResponse): TElectricityData => {
  return {
    balance: data.balance,
    dailyTotalFee: data.daily_total_fee,
    totalEfficiency: data.total_efficiency,
    availableDays: data.available_days,
  };
};

export const mapAssets = ({ active, pending, disabled }: TAssetsData): IMinersDataMapped[] => {
  const getMinersByStatuses = (minerData: TMiners, status: TMinersStatuses) => {
    return Object.values(minerData).map((item) => ({ ...item, status }));
  };

  const activeMiners = getMinersByStatuses(active.miners, 'active');
  const pendingMiners = getMinersByStatuses(pending.miners, 'pending');
  const disabledMiners = getMinersByStatuses(disabled.miners, 'disabled');

  return [...activeMiners, ...pendingMiners, ...disabledMiners].map((item: IMinersData) => ({
    assetName: item.asset_name,
    coin: item.coin,
    dailyOutput: item.daily_output,
    dailyTotalFee: item.daily_total_fee,
    efficiency: item.efficiency,
    efficiencyGroup: item.efficiency_group,
    powerConsumption: item.power_consumption,
    number: item.number,
    operationType: item.operation_type,
    poolId: item.pool_id,
    poolLink: item.pool_link,
    poolName: item.pool_name,
    poolType: item.pool_type,
    pricePerKw: item.price_per_kw,
    productId: item.product_id,
    startsAt: item.starts_at,
    totalHashrate: item.total_hashrate,
    totalPower: item.total_power,
    status: item.status,
  }));
};

export const mapTransactionHistory = ({
  data,
  initial_date,
  count,
}: ITransactionHistoryResponse): ITransactionHistoryDataMapped => {
  return {
    count,
    initialDate: initial_date,
    transactionHistoryData: Object.values(data).flat(),
  };
};

export const mapActiveMiners = ({ data }: IMinerDataResponse): IMinerDataMapped[] => {
  return data.map((item) => ({
    id: item.id,
    model: item.model,
    picture: getFullImagePath(item.picture),
    coin: item.coin,
    code: item.code,
    hashrate: item.hashrate,
    unitSymbol: item.unit_symbol,
    price: item.price,
    oldPrice: item.old_price,
    period: item.period,
    sort: item.sort,
    isSoldOut: Boolean(item.soldout),
    sellLeader: item.sell_leader,
    startsAt: item.starts_at,
    startDelay: item.start_delay,
    count: item.count,
    cashback: item.cashback,
    isNew: Boolean(item.is_new),
    powerConsumption: item.power_consumption,
    efficiency: item.efficiency,
    pricePerKw: item.price_per_kw,
    dailyElectricityFee: item.daily_electricity_fee,
    managementFee: item.management_fee,
    estDailyOutput: item.est_daily_output,
    poolName: item.pool_name,
  }));
};

export const mapCartData = ({ data }: IPurchaseCartResponse): IPurchaseCartDataMapped => {
  const result = {
    cartId: data.cart_id,
    totalCartSum: data.total_cart_price,
    cartItems:
      data.cart_items?.map((item) => ({
        productId: item.product_id,
        name: item.name,
        price: item.price / item.quantity,
        sum: item.price,
        quantity: item.quantity,
        maxQuantity: item.max_quantity,
      })) || [],
  };

  return result;
};

export const mapProfileData = ({ data }: IProfileResponse): IProfileData => {
  return {
    firstname: data.firstname || '',
    lastname: data.lastname || '',
    id: data.id,
    avatar: data.avatar || '',
    level: data.level,
    birthdate: data.dob || '',
    email: data.email || '',
    country: data.country || '',
    timezone: data.timezone || '',
    state: data.state || '',
    city: data.city || '',
    zip: data.zip || '',
    address: data.address1 || '',
    verificationStatusCode: data.verification_status_code,
    verificationStatus: data.verification_status,
    walletLockedUntil: data.wallet_locked_until,
    is2FAEnabled: !!data.is_2fa,
    isApproved: data.is_approved,
    isLocked: !!data.is_locked,
    isManager: !!data.is_manager,
  };
};

export const mapReferralStats = ({ data }: IReferralStatsResponse): IReferralStatsMapped => ({
  totalReferrals: data.total_referrals,
  totalProfit: data.total_profit,
  referralCommission: data.referral_commission,
});

export const mapReferralLink = (referralLink: IReferralLink): IReferralLinkMapped => ({
  name: referralLink.name,
  code: referralLink.code,
  link: referralLink.link,
  path: referralLink.path,
  salesCommission: referralLink.sales_comission,
  customerDiscount: referralLink.customer_discount,
  earned: referralLink.earned,
  conversion: referralLink.conversion,
});

export const mapInvoiceData = (data: IInvoiceResponseData): IInvoiceResponseDataMapped => {
  return {
    id: data.id,
    userId: data.user_id,
    billId: data.bill_id,
    createdAt: data.created_at,
    invoices: data.invoices,
    priceUsd: data.price_usd,
    priceBtc: data.price_btc,
    priceLtc: data.price_ltc,
    priceUst: data.price_ust,
    priceEth: data.price_eth,
    electricityFee: data.electricity_fee,
    discount: data.discount,
    startAt: data.start_at,
  };
};

export const mapCartCouponData = (
  data: ICartCouponResponseData,
): ICartCouponResponseDataMapped => ({
  priceUsd: data.price_usd,
  priceBtc: data.price_btc,
  priceLtc: data.price_ltc,
  priceUst: data.price_ust,
  priceEth: data.price_eth,
  discount: data.discount,
  type: data.type,
  errors: data.errors,
});

export const mapIpData = (data: IIpResponseData): IIpResponseDataMapped => ({
  ip: data.ip,
  city: data.city,
  region: data.region,
  regionCode: data.region_code,
  country: data.country,
  countryName: data.country_name,
  continentCode: data.continent_code,
  inEu: data.in_eu,
  postal: data.postal,
  latitude: data.latitude,
  longitude: data.longitude,
  timezone: data.timezone,
  utcOffset: data.utc_offset,
  countryCallingCode: data.country_calling_code,
  currency: data.currency,
  languages: data.languages,
  asn: data.asn,
  org: data.org,
});

export const mapVerificationData = (data: IVerificationData): IVerificationDataMapped => ({
  userId: data.user_id,
  applicantToken: data.applicant_token,
});

export const mapReferralHistory = (
  data: IReferralHistoryResponseData[],
): IReferralHistoryResponseDataMapped[] =>
  data.map((item) => ({
    userId: item.user_id,
    referralId: item.referral_id,
    linkName: item.link_name,
    refCode: item.ref_code,
    dataTimestamp: item.data_timestamp,
    coin: item.coin,
    referralReward: item.referral_reward,
    purchaseAmount: item.purchase_amount,
  }));

export const mapCardPayment = (data: ICardPaymentMessage): ICardPaymentMessageMapped => {
  return {
    paymentLink: data.payment_link,
  };
};

export const mapUptimeData = ({ data }: IUptimeResponseData): IUptimeDataMapped => {
  return {
    uptime: data.uptime_mode,
    lastUptimeDisabling: transformTimestampDate(data.uptime_change_at),
  };
};
