import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackReplenishMessages } from 'constants/notification-messages/fallback-replenish-messages';
import {
  HISTORY_TRANSACTIONS_COUNT,
  INITIAL_DATE,
} from 'pages/PageTransactionsHistory/constants/constants';
import {
  requestElectricityPayment,
  requestElectricityReplenish,
} from 'store/api/cuverse-api/replenish/replenish.service';
import { IReplenishError } from 'store/api/cuverse-api/replenish/types/replenish-error.interface';
import { IReplenishRequest } from 'store/api/cuverse-api/replenish/types/replenish-request.interface';
import {
  requestDashboardElectricity,
  requestDashboardWalletBalance,
} from 'store/dashboard-reducer/dashboard.thunk-actions';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { requestTransactionHistoryData } from 'store/transaction-reducer/transaction-history.thunk-actions';

import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const fetchReplenishThunkAction = createAsyncThunk(
  'replenish/fetchAndPayment',
  async ({ amount }: IReplenishRequest, { dispatch }) => {
    return requestElectricityReplenish({ amount })
      .then((res) => {
        return requestElectricityPayment({
          purchase_id: res.data.purchase_id,
          payment_method: 'btc-balance',
          operation_type: 'electricity_fee_payment',
          language: 'en',
        });
      })
      .then((res) => {
        notifySuccess(FallbackReplenishMessages.SuccessfulReplenish);
        void dispatch(closePopupAction());
        void dispatch(requestDashboardElectricity());
        void dispatch(
          requestTransactionHistoryData({
            count: HISTORY_TRANSACTIONS_COUNT,
            dateStart: INITIAL_DATE,
          }),
        );
        void dispatch(requestDashboardWalletBalance());
        return res.message.status;
      })
      .catch((error: AxiosError<IReplenishError>) => {
        if (error.response?.data.message) {
          notifyError(error.response.data.message);
          throw error;
        }
        if (error.response?.data.errors?.amount) {
          notifyError(error.response.data.errors.amount[0]);
          throw error;
        }
        notifyError(FallbackReplenishMessages.CannotReplenish);
        throw error;
      });
  },
);
