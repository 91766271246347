import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUptimeDataMapped } from 'store/api/cuverse-api/uptime/types/uptime-data-mapped';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { requestUptimeThunkAction, setUptimeThunkAction } from './uptime.thunk-action';

export interface IUptimeState {
  uptimeStatus: TFetchStatus;
  uptime: boolean;
  lastUptimeDisabling: string | null;
  isCooldownActive: boolean;

  error: string | null;
}

export const initialState: IUptimeState = {
  uptimeStatus: 'initial',
  uptime: false,
  lastUptimeDisabling: null,
  isCooldownActive: false,
  error: null,
};

const uptimeSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setCooldown: (state, action: PayloadAction<boolean>) => {
      state.isCooldownActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestUptimeThunkAction.pending, (state) => {
        state.uptimeStatus = 'pending';
      })
      .addCase(
        requestUptimeThunkAction.fulfilled,
        (state, action: PayloadAction<IUptimeDataMapped>) => {
          state.uptimeStatus = 'fulfilled';
          state.uptime = action.payload.uptime;
          state.lastUptimeDisabling = action.payload.lastUptimeDisabling;
        },
      )
      .addCase(requestUptimeThunkAction.rejected, (state, error) => {
        state.uptimeStatus = 'rejected';
        state.error = JSON.stringify(error);
      });
    builder
      .addCase(setUptimeThunkAction.pending, (state) => {
        state.uptimeStatus = 'pending';
      })
      .addCase(
        setUptimeThunkAction.fulfilled,
        (state, action: PayloadAction<IUptimeDataMapped>) => {
          state.uptimeStatus = 'fulfilled';
          state.uptime = action.payload.uptime;
        },
      )
      .addCase(setUptimeThunkAction.rejected, (state, error) => {
        state.uptimeStatus = 'rejected';
        state.error = JSON.stringify(error);
      });
  },
});

export const { setCooldown } = uptimeSlice.actions;
export const uptimeReducer = uptimeSlice.reducer;
