import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITransactionHistoryDataMapped } from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { requestTransactionHistoryData } from './transaction-history.thunk-actions';

export interface ITransactionHistoryState {
  transactions: ITransactionHistoryDataMapped;
  status: TFetchStatus;
  error: string | null;
}

export const initialState: ITransactionHistoryState = {
  transactions: {
    count: 0,
    initialDate: '',
    transactionHistoryData: [],
  },
  status: 'initial',
  error: null,
};

const transactionHistorySlice = createSlice({
  name: 'transaction/history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestTransactionHistoryData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      requestTransactionHistoryData.fulfilled,
      (state, action: PayloadAction<ITransactionHistoryDataMapped>) => {
        state.status = 'fulfilled';
        state.transactions = action.payload;
      },
    );
    builder.addCase(requestTransactionHistoryData.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const transactionHistoryReducer = transactionHistorySlice.reducer;
