import React from 'react';
import { PaymentOption } from 'pages/PagePurchase/components/ui/PaymentOption';
import { EPaymentType } from 'pages/PagePurchase/types/payment-type.enum';

import * as S from './PaymentOptionsScreen.styled';

interface PaymentOptionsScreenProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
  balance: number;
  amount: number;
}

export const PaymentOptionsScreen: React.FC<PaymentOptionsScreenProps> = (props) => {
  const { handlePaymentButtonClick, balance, amount } = props;

  return (
    <S.ScreenWrapper>
      {Object.values(EPaymentType).map((type) => {
        return (
          <PaymentOption
            key={type}
            handlePaymentButtonClick={handlePaymentButtonClick}
            paymentType={type}
            amount={amount}
            balance={balance}
          />
        );
      })}
    </S.ScreenWrapper>
  );
};
