import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const SPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 346px;
  padding: 24px;
  border-radius: 24px;
  background-color: #fff;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    width: 310px;
    border-radius: 16px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    width: 100%;
    border-radius: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
  }
`;
