import { createSlice } from '@reduxjs/toolkit';
import { TPairRatesData } from 'store/api/cuverse-api/rates/types/rates-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchPairRatesThunkAction } from './rates.thunk-actions';

export interface IRatesState {
  debugError: string | null;
  ratesData: TPairRatesData | null;
  ratesFetchStatus: TFetchStatus;
}

const initialState: IRatesState = {
  debugError: null,
  ratesData: null,
  ratesFetchStatus: 'initial',
};

export const ratesSlice = createSlice({
  name: 'rates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPairRatesThunkAction.pending, (state) => {
        state.ratesFetchStatus = 'pending';
        state.debugError = null;
      })
      .addCase(fetchPairRatesThunkAction.fulfilled, (state, action) => {
        state.ratesFetchStatus = 'fulfilled';
        state.ratesData = action.payload;
      })
      .addCase(fetchPairRatesThunkAction.rejected, (state, error) => {
        state.ratesFetchStatus = 'rejected';
        state.debugError = JSON.stringify(error);
      });
  },
});

export const ratesReducer = ratesSlice.reducer;
