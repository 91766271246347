import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIpResponseDataMapped } from 'store/api/ip-api/types/ip-response-data.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchIpData } from './ip.thunk-actions';

export interface IIpState {
  ipData: IIpResponseDataMapped | null;
  ipDataFetchStatus: TFetchStatus;
  error: string | null;
}

export const initialState: IIpState = {
  ipData: null,
  ipDataFetchStatus: 'initial',
  error: null,
};

const ipSlice = createSlice({
  name: 'ipapi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIpData.pending, (state) => {
      state.ipDataFetchStatus = 'pending';
    });
    builder.addCase(
      fetchIpData.fulfilled,
      (state, action: PayloadAction<IIpResponseDataMapped>) => {
        state.ipDataFetchStatus = 'fulfilled';
        state.ipData = action.payload;
      },
    );
    builder.addCase(fetchIpData.rejected, (state, error) => {
      state.ipDataFetchStatus = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const ipReducer = ipSlice.reducer;
