export const PERSONAL_INFORMATION_TITLE = 'Personal information';
export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const BIRTH_DATE_LABEL = 'Date of birth';
export const EMAIL_LABEL = 'E-mail';

export const ADDRESS_TITLE = 'Address';
export const COUNTRY_LABEL = 'Country';
export const STATE_LABEL = 'Region / state';
export const CITY_LABEL = 'City';
export const ZIP_CODE_LABEL = 'Postal code';
export const MAILING_ADDRESS_LABEL = 'Official mailing address';
export const TIMEZONE_LABEL = 'Time zone';
