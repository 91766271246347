import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const CartItemElement = styled.li`
  display: flex;
  flex-direction: column;
`;

export const CartItemDescription = styled.dl`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  font-family: ${FontFamily.Montserrat};
  row-gap: 8px;

  @media (min-width: ${BreakPoint.LaptopLow}) and (max-width: ${BreakPoint.LaptopTop}) {
    row-gap: 4px;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    row-gap: 4px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    row-gap: 4px;
  }
`;

export const CartItemName = styled.dt`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #272727;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const CartItemValue = styled.dd`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  line-height: 18px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const CartItemPrice = styled(CartItemValue)`
  font-weight: 400;
  color: #a1a1a1;
`;

export const CartItemCount = styled(CartItemValue)`
  font-weight: 400;
  color: #646464;
`;

export const CartItemSum = styled(CartItemValue)`
  font-weight: 700;
  color: #272727;
`;
