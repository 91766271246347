import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackAssetsErrorMessage } from 'constants/notification-messages/fallback-assets-error-message';
import { requestAssets } from 'store/api/cuverse-api/assets/assets.service';
import {
  IAssetsResponse,
  IMinersDataMapped,
} from 'store/api/cuverse-api/assets/types/assets-response.interface';

import { mapAssets } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

export const requestAssetsData = createAsyncThunk(
  'assets/fetch',
  async (): Promise<IMinersDataMapped[]> => {
    return await requestAssets()
      .then((response: IAssetsResponse) => {
        return mapAssets(response.data);
      })
      .catch((error) => {
        notifyError(FallbackAssetsErrorMessage.CannotLoadAssetsData);
        throw error;
      });
  },
);
