import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCartList,
  deleteCartListItem,
  requestActiveMiners,
  requestCartList,
  updateCartList,
} from 'store/api/cuverse-api/purchase/purchase.service';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { IPurchaseCartDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-cart-response.interface';

import { mapActiveMiners, mapCartData } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

export const fetchActiveMinersThunkAction = createAsyncThunk(
  'miners/fetchActiveMiners',
  async (): Promise<IMinerDataMapped[]> => {
    return await requestActiveMiners()
      .then((res) => mapActiveMiners(res))
      .catch((error) => {
        //TODO replace with correct message
        notifyError('Miners cannot be requested');
        throw error;
      });
  },
);

export const fetchCartThunkAction = createAsyncThunk(
  'miners/fetchCart',
  async (): Promise<IPurchaseCartDataMapped> => {
    return await requestCartList()
      .then((res) => mapCartData(res))
      .catch((error) => {
        throw error;
      });
  },
);

export const createCartThunkAction = createAsyncThunk(
  'miners/createCart',
  async (id: number): Promise<IPurchaseCartDataMapped> => {
    return await createCartList(id)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot create cart');
        throw error;
      });
  },
);

export const updateCartThunkAction = createAsyncThunk(
  'miners/updateCart',
  async ({ cartId, id }: { cartId: number; id: number }): Promise<IPurchaseCartDataMapped> => {
    return await updateCartList(cartId, id)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot update cart');
        throw error;
      });
  },
);

export const deleteCartItemThunkAction = createAsyncThunk(
  'miners/deleteCartItem',
  async ({ cartId, id }: { cartId: number; id: number }): Promise<IPurchaseCartDataMapped> => {
    return await deleteCartListItem(cartId, id)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot delete item');
        throw error;
      });
  },
);
