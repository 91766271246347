import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px 16px;
  }
`;

export const Description = styled(SText)`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
`;

export const WalletTitle = styled(SText)`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &::after {
    content: '*';
    color: #ec494f;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FieldsWrapper = styled.div<{ $isFailed: boolean }>`
  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }}
`;

export const WalletAddressLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100px;
`;

export const LabelText = styled(SText)`
  display: inline-flex;
  gap: 2px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &::after {
    content: '*';
    font-weight: 900;
    color: #ec494f;
  }
`;

export const WalletAddressInput = styled(Input)`
  & input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: ${BreakPoint.MobileTop}) {
      height: 42px;
      padding: 11px 15px;
      font-size: 12px;
      line-height: 16px;

      & > div {
        left: 12px;
      }
    }
  }
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const WithdrawButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
  padding: 12px 24px;
  border-radius: 24px;
  font-weight: 500;
`;

export const WarningWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  background: #fff6d9;

  & svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`;

export const WarningText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
`;
