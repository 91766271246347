import React from 'react';
import { useBlockContentScroll } from 'hooks/useBlockContentScroll';

import { MenuLogo } from 'components/logic/InnerLayout/components/ui/MenuLogo';
import { MenuProfileLink } from 'components/logic/InnerLayout/components/ui/MenuProfileLink';
import { Navigation } from 'components/logic/InnerLayout/components/ui/Navigation';
import { NavigationFooter } from 'components/logic/InnerLayout/components/ui/NavigationFooter';

interface IMainMenuProps {
  handleLogout: () => void;
  isLoggingOut: boolean;
  handleResetBuyingFlow: () => void;
}

export const MainMenu: React.FC<IMainMenuProps> = ({
  handleLogout,
  isLoggingOut,
  handleResetBuyingFlow,
}) => {
  useBlockContentScroll('main-menu');

  return (
    <>
      <MenuLogo />
      <MenuProfileLink />
      <Navigation handlePurchaseLinkClick={handleResetBuyingFlow} />
      <NavigationFooter handleLogout={handleLogout} isLoggingOut={isLoggingOut} />
    </>
  );
};
