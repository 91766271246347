import { AppRoute } from 'utils/route/app-route';

export interface INavItem {
  linkTo: string;
  text: string;
}

export const navItems: INavItem[] = [
  {
    linkTo: AppRoute.Home(),
    text: 'Dashboard',
  },
  {
    linkTo: AppRoute.Purchase(),
    text: 'Buy a miner',
  },
  {
    linkTo: AppRoute.Assets(),
    text: 'Assets',
  },
  {
    linkTo: AppRoute.TransactionsHistory(),
    text: 'Transaction history',
  },
  {
    linkTo: AppRoute.AffiliateProgram(),
    text: 'Affiliate program',
  },
];
