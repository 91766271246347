import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { IUptimeResponseData } from './types/uptime-response.interface';
import { UptimeRoute } from './uptime.route';

export const getUptimeData = async (): Promise<IUptimeResponseData> =>
  cuverseApi.get(UptimeRoute.Uptime()).then((res) => {
    return res?.data;
  });

export const updateUptimeData = async (): Promise<IUptimeResponseData> => {
  return cuverseApi.post(UptimeRoute.Uptime()).then((res) => {
    return res?.data;
  });
};
