import styled from 'styled-components';
import { FontFamily } from 'styles/style-variables/font-family';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  & svg {
    flex-shrink: 0;
  }
`;

export const StatusText = styled.span`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #272727;
  font-variant-numeric: lining-nums tabular-nums;
`;

export const TurnedOffText = styled(StatusText)`
  color: #a1a1a1;
`;
