import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const FieldsWrapper = styled.div<{ $isFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }}
`;

export const AmountLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LabelText = styled(SText)`
  display: inline-flex;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &::after {
    content: '*';
    font-weight: 900;
    color: #ec494f;
  }
`;

const WithdrawFormInput = styled(Input)`
  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      height: 42px;
      padding: 11px 15px;
    }

    & > div {
      left: 12px;
    }
  }
`;

export const WithdrawAmountInput = styled(WithdrawFormInput)`
  & input {
    letter-spacing: 1px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & input {
      padding-right: 120px;
      padding-left: 92px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const InputDescriptionText = styled(SText)`
  opacity: 0.8;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #646464;
  font-variant-numeric: tabular-nums;
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const NetworkCommissionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NetworkCommissionTitle = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const NetworkCommissionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  min-height: 42px;
  padding: 8px 16px 8px 12px;
  border-radius: 24px;
  background-color: #f4f4f7;
`;

export const CommissionAmount = styled(SText)`
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-overflow: ellipsis;
`;

export const WalletAddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const WalletTitle = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const SavedWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const WalletAddress = styled(SText)`
  min-height: 42px;
  padding: 12px;
  border-radius: 24px;
  background-color: #f4f4f7;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const WalletPrompt = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #646464;
`;

export const WithdrawButton = styled(Button)`
  width: 100%;
  padding: 12px 24px;
  border-radius: 24px;
`;
