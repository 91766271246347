import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import {
  ITransactionHistoryRequestParams,
  ITransactionHistoryResponse,
} from './types/transaction-history-response.interface';
import { TransactionRoute } from './transaction.rout';


export const requestTransactionHistory = async ({
  count,
  dateStart,
}: ITransactionHistoryRequestParams): Promise<ITransactionHistoryResponse> =>
  cuverseApi.get(TransactionRoute.TransactionHistory(count, dateStart)).then((res) => {
    return res?.data;
  });
