import React, { FC, lazy, Suspense, useEffect,useState } from 'react';
import dayjs from 'dayjs';
import { FormApi } from 'final-form';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { IForm } from 'pages/PageTransactionsHistory/types/filters-panel.interface';
import { getDatesRange } from 'pages/PageTransactionsHistory/utils/get-dates-range.util';
import { useAppDispatch } from 'store/store';
import {
  resetFilters,
  setFilters,
} from 'store/transaction-filters-reducer/transaction-filters.reducer';
import { BreakPoint } from 'styles/style-variables/breakpoint';

const FiltersPanelDesktop = lazy(() =>
  import(
    'pages/PageTransactionsHistory/components/ui/FiltersPanelDesktop/FiltersPanelDesktop'
  ).then((module) => ({ default: module.FiltersPanelDesktop })),
);
const FiltersPanelMobile = lazy(() =>
  import('pages/PageTransactionsHistory/components/ui/FiltersPanelMobile/FiltersPanelMobile').then(
    (module) => ({ default: module.FiltersPanelMobile }),
  ),
);

interface IFiltersState {
  transactionType: string | null;
  startDate: string | null;
  endDate: string | null;
  tabValue: string;
  isShow: boolean;
  isFiltersApply: boolean;
}

interface IProps {
  setCurrentPage: (pageNumber: number) => void;
}

export const FiltersPanel: FC<IProps> = ({ setCurrentPage }): React.ReactElement => {
  const { innerWidth } = useInnerWidth();
  const dispatch = useAppDispatch();
  const dateFormat = 'YYYY-MM-DD';
  const initialTransactionType = 'All transactions';
  const initialStartDate = '01.01.2024';
  const initialEndDate = dayjs().format(dateFormat);

  const [filters, setFiltersState] = useState<IFiltersState>({
    transactionType: initialTransactionType,
    startDate: initialStartDate,
    endDate: initialEndDate,
    tabValue: '',
    isShow: false,
    isFiltersApply: false,
  });

  const activeFiltersTag = `${filters.startDate ? dayjs(filters.startDate).format('DD.MM.YYYY') : ''} — ${filters.endDate ? dayjs(filters.endDate).format('DD.MM.YYYY') : ''}`;

  const toggleFilters = () => setFiltersState((prev) => ({ ...prev, isShow: !prev.isShow }));

  const handleDateChange = (type: 'start' | 'end', value: string, form: FormApi<IForm>) => {
    setFiltersState((prev) => ({ ...prev, tabValue: '' }));
    const newDate = dayjs(value);
    const oppositeDate = type === 'start' ? dayjs(filters.endDate) : dayjs(filters.startDate);

    if (
      (type === 'start' && newDate.isAfter(oppositeDate)) ||
      (type === 'end' && newDate.isBefore(oppositeDate))
    ) {
      const newValue = type === 'start' ? filters.endDate : filters.startDate;
      setFiltersState((prev) => ({ ...prev, [`${type}Date`]: newValue }));
      if (newValue) form.change(`${type}Date`, newValue);
    } else {
      setFiltersState((prev) => ({ ...prev, [`${type}Date`]: value }));
      form.change(`${type}Date`, value);
    }
  };
  const handleTabValue = (tabValue: string) => {
    setFiltersState((prev) => ({ ...prev, tabValue: tabValue }));
    const { newStartDate, newEndDate } = getDatesRange(tabValue, dateFormat);
    setFiltersState((prev) => ({ ...prev, startDate: newStartDate, endDate: newEndDate }));
  };
  const handleReset = () => {
    dispatch(resetFilters());
    setFiltersState({
      transactionType: initialTransactionType,
      startDate: initialStartDate,
      endDate: initialEndDate,
      tabValue: '',
      isShow: !filters.isShow,
      isFiltersApply: false,
    });
    setCurrentPage(1);
  };
  const handleApply = () => {
    setFiltersState((prev) => ({ ...prev, isFiltersApply: true, isShow: !prev.isShow }));
    dispatch(
      setFilters({
        startDate: filters.startDate,
        endDate: filters.endDate,
        transactionType:
          filters.transactionType === initialTransactionType ? null : filters.transactionType,
      }),
    );
    setCurrentPage(1);
  };

  const filtersProps = {
    handleStartDateChange: (value: string, form: FormApi<IForm>) =>
      handleDateChange('start', value, form),
    handleEndDateChange: (value: string, form: FormApi<IForm>) =>
      handleDateChange('end', value, form),
    handleTabValue,
    handleReset,
    handleApply,
    transactionType: filters.transactionType,
    setTransactionType: (type: string | null) =>
      setFiltersState((prev) => ({ ...prev, transactionType: type })),
    startDate: filters.startDate,
    endDate: filters.endDate,
    tabValue: filters.tabValue,
  };

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, [dispatch]);

  return (
    <Suspense fallback={null}>
      {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
        <FiltersPanelDesktop {...filtersProps} />
      ) : (
        <FiltersPanelMobile
          {...filtersProps}
          isShowFilters={filters.isShow}
          handleShowFilters={toggleFilters}
          isFiltersApply={filters.isFiltersApply}
          activeFiltersTag={activeFiltersTag}
        />
      )}
    </Suspense>
  );
};
