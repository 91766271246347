import React from 'react';
import { ReactComponent as CaretLeft } from 'assets/icons/button-icons/caret-left.svg';
import { ReactComponent as CaretRight } from 'assets/icons/button-icons/caret-right.svg';

import { PaginationButton } from './components/PaginationButton';
import { getPageNumbersArray } from './utils/get-page-numbers-array.util';

import * as S from './Pagination.styled';

interface IPaginationProps {
  currentPage: number;
  totalPages: number;
  handleSetCurrentPage: (newPage: number) => void;
}

export const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  totalPages,
  handleSetCurrentPage,
}) => {
  const pagesCount = totalPages <= 0 ? 1 : totalPages;
  const displayedPageNumbers = getPageNumbersArray(pagesCount, currentPage);
  const isLeftDividerVisible = displayedPageNumbers[0] > 1;
  const isRightDividerVisible = displayedPageNumbers[displayedPageNumbers.length - 1] < pagesCount;

  const handleForwardClick = () => {
    handleSetCurrentPage(currentPage + 1);
  };

  const handleBackClick = () => {
    handleSetCurrentPage(currentPage - 1);
  };

  return (
    <S.PaginationWrapper>
      <S.CurrentPage>{`Page ${currentPage} of ${pagesCount}`}</S.CurrentPage>
      <S.ButtonsGroup>
        <S.LeftDirection
          variant="alter-primary"
          icon={<CaretLeft />}
          onClick={handleBackClick}
          disabled={currentPage === 1}
        />
        {isLeftDividerVisible && <S.LeftDivider>...</S.LeftDivider>}
        <S.NumbersContainer>
          {displayedPageNumbers.map((pageNumber) => (
            <PaginationButton
              key={pageNumber}
              pageNumber={pageNumber}
              isCurrent={pageNumber === currentPage}
              handleSetCurrentPage={handleSetCurrentPage}
            />
          ))}
        </S.NumbersContainer>
        {isRightDividerVisible && <S.RightDivider>...</S.RightDivider>}
        <S.RightDirection
          variant="alter-primary"
          icon={<CaretRight />}
          onClick={handleForwardClick}
          disabled={currentPage === pagesCount}
        />
      </S.ButtonsGroup>
    </S.PaginationWrapper>
  );
};
