import { createAsyncThunk } from '@reduxjs/toolkit';
import { FallbackAssetsErrorMessage } from 'constants/notification-messages/fallback-assets-error-message';
import { IUptimeDataMapped } from 'store/api/cuverse-api/uptime/types/uptime-data-mapped';
import { IUptimeRequestAction } from 'store/api/cuverse-api/uptime/types/uptime-request-action.interface';
import { getUptimeData, updateUptimeData } from 'store/api/cuverse-api/uptime/uptime.service';

import { isTimePassed } from 'utils/common/is-time-passed';
import { mapUptimeData } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

import { setCooldown } from './uptime.reducer';

export const requestUptimeThunkAction = createAsyncThunk(
  'assets/uptime-get',
  async (_, { dispatch }): Promise<IUptimeDataMapped> => {
    return await getUptimeData()
      .then((response) => {
        const mappedResponse = mapUptimeData(response);
        const isPassed = isTimePassed(mappedResponse.lastUptimeDisabling);
        dispatch(setCooldown(!isPassed));

        return mappedResponse;
      })
      .catch((error) => {
        notifyError(FallbackAssetsErrorMessage.CannotLoadUptimeStatus);
        throw error;
      });
  },
);

export const setUptimeThunkAction = createAsyncThunk(
  'assets/uptime-set',
  async (uptimeAction: IUptimeRequestAction, { dispatch }): Promise<IUptimeDataMapped> => {
    return await updateUptimeData()
      .then((response) => {
        const mappedResponse = mapUptimeData(response);
        const isPassed = isTimePassed(mappedResponse.lastUptimeDisabling);
        dispatch(setCooldown(!isPassed));
        return mappedResponse;
      })
      .catch((error) => {
        notifyError(
          uptimeAction.uptimeAction
            ? FallbackAssetsErrorMessage.CannotEnableUptime
            : FallbackAssetsErrorMessage.CannotDisableUptime,
        );
        throw error;
      });
  },
);
